import { Component, ChangeDetectionStrategy, ViewChild, ElementRef, ChangeDetectorRef, OnInit } from '@angular/core';
import { CustomIcons } from '@shared/models/custom-icons';
import { takeUntil } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { IDestroy, TakeUntilDestroy } from '@utils/auto-unsubscribe';
import { Subject } from 'rxjs';
import { Locale, LocaleType } from '@shared/models/locale';
import { TranslateService as NgxTranslateService } from '@ngx-translate/core';
import { TranslateService } from '@shared/services/translate.service';
import { locales } from '@layout/components/models';
import { VersionForVisuallyImpairedRoutes } from '../../../version-for-visually-impaired/models/routes';

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@TakeUntilDestroy
export class MobileMenuComponent implements OnInit, IDestroy {
  private _touchendX: number;
  private _touchstartX: number;
  public componentDestroy: () => Subject<any>;
  @ViewChild('navBar') public navBar: ElementRef<HTMLElement>;
  public dataRoute = `/opendata`;
  public developersRoute = `/developers`;
  public isActiveDataUrl = false;
  public isActiveDevelopersUrl = false;
  public icons = CustomIcons;
  public isMobileMenuOpen = false;
  public visuallyImpaired = `/visually-impaired/${VersionForVisuallyImpairedRoutes.DataSet}`;
  public isRuLocale = true;

  constructor(
    private cdr: ChangeDetectorRef,
    private route: Router,
    private ngxTranslateService: NgxTranslateService,
    private translateService: TranslateService,
  ) {}

  private checkDirection(): void {
    if (this._touchendX < this._touchstartX) {
      this.isMobileMenuOpen = false;
    }
  }

  private setAllActiveUrlToFalse(): void {
    this.isActiveDataUrl = false;
    this.isActiveDevelopersUrl = false;
  }

  private setActiveUrl(currentUrl: string): void {
    if (currentUrl.includes('opendata')) {
      this.isActiveDataUrl = true;
    } else if (currentUrl.includes('developers')) {
      this.isActiveDevelopersUrl = true;
    }

    this.cdr.detectChanges();
  }

  public ngOnInit(): void {
    this.isRuLocale = this.ngxTranslateService.currentLang === Locale.RU_RU;
    this.route.events.pipe(takeUntil(this.componentDestroy())).subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.setAllActiveUrlToFalse();
        this.setActiveUrl(event.url);
      }
    });
  }

  public closeMenu(): void {
    this.isMobileMenuOpen = false;
  }

  public menuClick(): void {
    this.isMobileMenuOpen = !this.isMobileMenuOpen;
    this.cdr.detectChanges();
  }

  public changeLanguage(): void {
    const changedLocale = locales.find(x => x.id !== this.ngxTranslateService.currentLang) as LocaleType;
    this.translateService.use(changedLocale.id);
    this.isRuLocale = (this.ngxTranslateService.currentLang as Locale) === Locale.RU_RU;
  }

  public touchStart($event: TouchEvent): void {
    this._touchstartX = $event.changedTouches[0].screenX;
  }

  public touchEnd($event: TouchEvent): void {
    this._touchendX = $event.changedTouches[0].screenX;
    this.checkDirection();
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServerLinks } from '@shared/services/server-links';
import { ServerLinkName } from '@shared/models/server-links-model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NewSetsAndPopularSetsInfo, NewSetsAndPopularSetsResponse, SeasonsSetsResponse } from '@shared/models/catalog.service';
import { SmallCardData } from 'app/modules/main/models/smal-card';
import { CatalogCountResponse } from 'app/modules/main/models/catalogs';
import { Store } from '@ngxs/store';
import { SharedState } from '@shared/state/shared/shared.state';
import { CatalogStatus } from '../../data/modules/catalog-data/store/catalog-table.state';
import { NextToMeData } from '../../data/modules/catalog-items-data/models/catalog-item.service';

@Injectable()
export class MainPageService {
  private _apiUrl = '';
  private _ehdApi = '';
  public isRecommendationId: number | null;
  public isNextMeData: NextToMeData;
  constructor(private http: HttpClient, serverLinks: ServerLinks, private store: Store) {
    this._apiUrl = serverLinks.getLink(ServerLinkName.OpenData);
    this._ehdApi = serverLinks.getLink(ServerLinkName.EhdApi);
  }

  private convertToItemCard(catalog: NewSetsAndPopularSetsInfo, isNew = false): SmallCardData {
    const allCategories = this.store.selectSnapshot(SharedState.getCategories);
    return {
      id: catalog.idPublicCatalog,
      datasetId: catalog.idDataset,
      title: catalog.nameOpenCategory,
      text: catalog.namePublicCatalog,
      titleRu: catalog.nameOpenCategory,
      textRu: catalog.namePublicCatalog,
      titleEng: catalog.nameOpenCategoryEn ?? catalog.nameOpenCategory,
      textEng: catalog.namePublicCatalogEn ?? catalog.namePublicCatalog,
      rating: catalog.rating,
      catalogId: catalog.idPublicCatalog,
      views: catalog.numberView,
      isDynamic: !!catalog.isDynamic,
      seasonsId: catalog.seasonId,
      imageCategory: allCategories.find(x => +x.idOpenCategory === +catalog.idOpenCategory)?.imageCode ?? '',
      downloads: catalog?.numberDownloads,
      isNew: isNew
    };
  }

  public getSeasonDataSetCards(): Observable<SmallCardData[]> {
    return this.http
      .get<SeasonsSetsResponse>(`${this._apiUrl}season`)
      .pipe(map((response: SeasonsSetsResponse) => response.catalogs.map(catalog => this.convertToItemCard(catalog))));
  }

  public getNewSetsAndPopularCards(): Observable<SmallCardData[]> {
    return this.http
      .get<NewSetsAndPopularSetsResponse>(`${this._apiUrl}sets`)
      .pipe(
        map((response: NewSetsAndPopularSetsResponse) => [
          ...(response.newSets ? response.newSets.map(catalog => this.convertToItemCard(catalog, true)) : []),
          ...(response.popularSets ? response.popularSets.map(catalog => this.convertToItemCard(catalog)) : [])
        ])
      );
  }

  public getCatalogsCount(): Observable<CatalogCountResponse> {
    return this.http.post<CatalogCountResponse>(`${this._apiUrl}catalog/count/`, {
      criteria: `publicCatalogStatus in (${CatalogStatus.active},${CatalogStatus.archive})`
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public getCatalogDetails(catalogId: number, globalId: number) {
    const body = {
      criteria: `global_id = ${globalId}`,
      fetchGeodata: true,
      id: catalogId
    };
    return this.http.post(`${this._ehdApi}catalog/get/`, body);
  }
}

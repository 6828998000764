import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgModule } from '@shared/components/svg/svg.module';
import { FooterModule } from '@shared/components/footer/footer.module';
import { ErrorPageMessageComponent } from '@shared/components/error-page-message/copmponents/error-page-message/error-page-message.component';
import { TranslateModule } from '@ngx-translate/core';
import { PxModule } from '@shared/pipes/px/px.module';

@NgModule({
  declarations: [ErrorPageMessageComponent],
  imports: [CommonModule, SvgModule, FooterModule, TranslateModule, PxModule],
  exports: [ErrorPageMessageComponent]
})
export class ErrorPageMessageModule {}

<a [href]="mosRuUrl" class="icon-links" rel="nofollow" target="_blank">
  <svg [icon]="icons.LogoMosRu" class="icon-size social-icon"/>
</a>
<!--<a [href]="fbUrl" class="icon-links" rel="nofollow" target="_blank">-->
<!--  <svg [icon]="icons.Facebook" class="svg24 social-icon"/>-->
<!--</a>-->
<!--<a [href]="twitterUrl" class="icon-links" rel="nofollow" target="_blank">
  <svg [icon]="icons.Twitter" class="svg24 social-icon"/>
</a>-->
<!--        <a [href]="instagramUrl" class="icon-links" rel="nofollow" target="_blank">-->
<!--          <svg [icon]="icons.Instagram" class="svg24 social-icon"/>-->
<!--        </a>-->
<!--
<a class="icon-links" rel="nofollow" target="_blank">
  <svg [icon]="icons.Ok" class="svg24 social-icon"/>
</a>
-->

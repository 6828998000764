import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TranslateService as NgxTranslateService } from '@ngx-translate/core';
import { TranslateService } from '@shared/services/translate.service';
import { LocaleType } from '@shared/models/locale';
import { locales } from '@layout/components/models';

@Component({
  selector: 'app-locale-switcher',
  templateUrl: './locale-switcher.component.html',
  styleUrls: ['./locale-switcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocaleSwitcherComponent implements OnInit {
  public currentlocale: LocaleType;

  constructor(
    private ngxTranslateService: NgxTranslateService,
    private translateService: TranslateService,
  ) {}

  public ngOnInit(): void {
    this.currentlocale = locales.find(x => x.id === this.ngxTranslateService.currentLang) as LocaleType;
  }

  public changeLanguage($event: MouseEvent): void {
    const changedLocale = locales.find(x => x.id !== this.ngxTranslateService.currentLang) as LocaleType;
    this.translateService.use(changedLocale.id);
    $event.stopPropagation();
  }
}

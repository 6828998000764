import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { CustomIcons } from '@shared/models/custom-icons';
import { Select, Store } from '@ngxs/store';
import { IDestroy, TakeUntilDestroy } from '@utils/auto-unsubscribe';
import { SvgPrefetchService } from '@shared/services/svg-prefetch.service';
import { DOCUMENT } from '@angular/common';
import { AuthorizationDialogComponent } from '@auth/components/authorization-dialog/authorization-dialog.component';
import { AuthState } from '@auth/store/auth/auth.state';
import { SharedState } from '@shared/state/shared/shared.state';
import { UserInfo } from '@auth/models/auth.state';
import { Login } from '@auth/store/auth/auth.action';
import { ActionsExecuting, actionsExecuting } from '@ngxs-labs/actions-executing';
import { BreakpointObserver } from '@angular/cdk/layout';
import { OkCancelDialogComponent } from '@shared/components/ok-cancel-modal/ok-cancel-dialog/ok-cancel-dialog.component';
import { DataRouting } from '../../../data/modules/catalog-data/models/data-routing';
import { VersionForVisuallyImpairedRoutes } from '../../../version-for-visually-impaired/models/routes';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@TakeUntilDestroy
export class LayoutComponent implements OnInit, IDestroy {
  private _isMobile = false;
  public componentDestroy: () => Subject<any>;
  @Select(AuthState.authenticatedUser) public user$: Observable<UserInfo>;
  public showLayout: boolean;
  @Select(actionsExecuting([Login])) public loginInProgress$: Observable<ActionsExecuting>;

  public dataRoute = `/opendata`;
  public visuallyImpaired = `/visually-impaired/${VersionForVisuallyImpairedRoutes.DataSet}`;
  public developersRoute = `/developers`;

  public isActiveDataUrl = false;
  public isActiveDevelopersUrl = false;
  public icons = CustomIcons;
  public breakPointObserve$ = this.breakpointObserver.observe(['(max-width: 896px) and (orientation: landscape)']).pipe(
    tap(state => {
      this._isMobile = state.breakpoints['(max-width: 896px) and (orientation: landscape)'];
    })
  );
  constructor(
    private store: Store,
    private route: Router,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog,
    public svgPrefetchService: SvgPrefetchService,
    private r2: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private breakpointObserver: BreakpointObserver
  ) {}

  private setAllActiveUrlToFalse(): void {
    this.isActiveDataUrl = false;
    this.isActiveDevelopersUrl = false;
  }

  private setActiveUrl(currentUrl: string): void {
    if (currentUrl.includes('opendata')) {
      this.isActiveDataUrl = true;
    } else if (currentUrl.includes('developers')) {
      this.isActiveDevelopersUrl = true;
    }

    this.cdr.detectChanges();
  }

  private openAuthorizationModal(): void {
    const config = this._isMobile
      ? {
          maxWidth: '100%',
          width: '100%',
          position: {
            bottom: '0px'
          },
          panelClass: 'mobile-bottom-show-up-small-dialog',
          backdropClass: 'backdrop',
          hasBackdrop: true
        }
      : { backdropClass: 'closer-backdrop' };
    this.dialog.open(AuthorizationDialogComponent, { ...config, disableClose: true });
  }

  public ngOnInit(): void {
    this.setActiveUrl(this.document.URL);

    this.store
      .select(SharedState.getShowLayout)
      .pipe(takeUntil(this.componentDestroy()))
      .subscribe(isShow => {
        this.showLayout = isShow;
        this.cdr.detectChanges();
      });
    this.route.events.pipe(takeUntil(this.componentDestroy())).subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.setAllActiveUrlToFalse();
        this.setActiveUrl(event.url);
      }
    });

    this.svgPrefetchService
      .prefetchSharedSvg()
      .pipe(takeUntil(this.componentDestroy()))
      .subscribe(data => {
        const renderPlace = this.r2.createElement('div');
        renderPlace.style.display = 'none';
        renderPlace.innerHTML = data;
        this.r2.appendChild(this.document.body, renderPlace);
      });
  }

  public openAuthorizationDialog(): void {
    if (this.route.url.includes(DataRouting.Appeals)) {
      const okCancelDialog = this.dialog.open(OkCancelDialogComponent, {});

      okCancelDialog
        .afterClosed()
        .pipe(take(1))
        .subscribe(isNext => {
          if (isNext) {
            this.openAuthorizationModal();
          }
        });
    } else {
      this.openAuthorizationModal();
    }
  }
}

import { Action, Actions, ofAction, Selector, State, StateContext, Store } from '@ngxs/store';
import { catchError, map, take, takeUntil } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { convertCatalogResponseToView } from '@utils/converters';
import { SortingType } from 'app/modules/data/modules/catalog-data/components/ordering/models';
import { setLocationQueryParams } from '@utils/other-utils';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { CatalogView } from '@shared/models/shared-catalog.state';
import { CatalogService } from '@shared/services/catalog.service';
import { SnackBarService } from '@shared/components/snackbar-message/services/snackbar.service';
import { isFatalErrorCode } from '@utils/handle-error-code';
import { MirSnackbarType, SnackBarMessage } from '@shared/components/snackbar-message/model/snackbar';
import { isEmpty, isEqual, pickBy } from 'lodash';
import { HttpErrorResponse } from '@angular/common/http';
import { RecommendedCatalogResponse } from 'app/modules/data/models/catalog';
import { CatalogParams } from '@shared/models/catalog.service';
import { AuthState } from '@auth/store/auth/auth.state';
import { patch, updateItem } from '@ngxs/store/operators';
import { getStringFilter } from '@utils/catalog-params';
import { TranslateService } from '@ngx-translate/core';
import { Locale } from '@shared/models/locale';
import { CatalogFilter, CatalogQueryParams, FilterName } from '../models/catalog.service';
import {
  ChangeCatalogFilter,
  ChangeSortingType,
  ClearCatalogs,
  GetCatalogs,
  GetCatalogsCanceled,
  GetExtraCatalog,
  GetRecommendedCatalogs,
  HandleCatalogHeaderToggle,
  UpdateCatalog
} from './catalog-table.actions';
import { GetFavoriteCatalogs } from '../../../store/favorite-catalogs/favorite-catalogs.action';
import { FavoriteCatalogsState } from '../../../store/favorite-catalogs/favorite-catalogs.state';

export enum Seasons {
  summer = '3',
  all = '4',
  winter = '2'
}

export enum CatalogStatus {
  archive = "'Архивный'",
  active = "'Опубликовано'"
}

type CatalogStateModel = {
  catalogs: CatalogView[];
  changeFilter: boolean;
  loadingDataBeforeChangeFilter: boolean;
  catalogUpdate: CatalogView | null;
  filter: CatalogFilter | null;
  sortingType: SortingType;
  catalogTotal: number;
  limit: number;
  isCatalogGetFatalError: boolean;
  recommendedCatalogIds: number[] | null;
};

@State<CatalogStateModel>({
  name: 'catalogTable',
  defaults: {
    catalogUpdate: null,
    changeFilter: false,
    loadingDataBeforeChangeFilter: false,
    catalogs: [],
    catalogTotal: 0,
    filter: {
      booleanFilters: {
        activeStatus: true,
        archiveStatus: null,
        withMap: null,
        withoutMap: null,
        iso: null,
        summerSeason: null,
        regionalDict: null,
        ordinaryDict: null,
        federalDict: null,
        datasetType: null,
        allSeasons: null,
        accessibility: null,
        winterSeason: null
      }
    },
    sortingType: { byNameCatalog: 'ASC' },
    limit: 33,
    isCatalogGetFatalError: false,
    recommendedCatalogIds: null
  }
})
@Injectable()
export class CatalogTableState {
  constructor(
    private catalogService: CatalogService,
    private location: Location,
    private router: Router,
    private snackBar: SnackBarService,
    private store: Store,
    private translateService: TranslateService,
    private actions$: Actions
  ) {}

  private getCatalogViews(ctx: StateContext<CatalogStateModel>): Observable<CatalogView[] | []> {
    const state = ctx.getState();
    const stringFilter = getStringFilter(state.filter, this.translateService.currentLang as Locale);

    /*  if (state.catalogTotal !== 0 && state.catalogTotal <= state.catalogs.length && !state.changeFilter) return of([]); */

    const body: CatalogParams = {
      sorting: state.sortingType,
      limit: ctx.getState().limit,
      offset: state.changeFilter ? 0 : state.catalogs.length,
      idPublicCatalog: state.filter?.catalogIds
    };

    if (stringFilter) body.criteria = stringFilter;
    return this.catalogService.getCatalogs(body).pipe(
      map(response => {
        const catalogs = response.response;
        const currentCatalogs: CatalogView[] = catalogs.map(catalog => convertCatalogResponseToView(catalog));
        let result = currentCatalogs;
        if (!state.changeFilter) {
          result = [...state.catalogs, ...currentCatalogs];
        }

        ctx.patchState({
          catalogs: result,
          changeFilter: false,
          catalogTotal: response.found
        });

        return result;
      }),
      catchError((error: HttpErrorResponse) => {
        const errorMessage: SnackBarMessage[] = [
          {
            message: 'SNACK_BAR_MESSAGE.ERROR_LOADING_DATA_LIST',
            translate: true
          }
        ];

        this.snackBar.open(MirSnackbarType.Error, errorMessage);

        const isFatalError = isFatalErrorCode(error);
        if (isFatalError) {
          ctx.patchState({ isCatalogGetFatalError: isFatalError });
        }
        return of([]);
      }),
      takeUntil(this.actions$.pipe(ofAction(GetCatalogsCanceled)))
    );
  }

  @Selector()
  public static getCatalogSorting(state: CatalogStateModel): SortingType | null {
    return state.sortingType;
  }

  @Selector()
  public static getCatalogLimit(state: CatalogStateModel): number {
    return state.limit;
  }

  @Selector()
  public static getCatalogFilter(state: CatalogStateModel): CatalogFilter | null {
    return state?.filter ?? null;
  }

  @Selector()
  public static getLoadingDataBeforeChangeFilter(state: CatalogStateModel): boolean {
    return state?.loadingDataBeforeChangeFilter;
  }

  @Selector()
  public static getAllCatalogs(state: CatalogStateModel): CatalogView[] {
    return state.catalogs;
  }

  @Selector()
  public static getCatalogTotal(state: CatalogStateModel): number {
    return state.catalogTotal;
  }

  @Selector()
  public static getFatalError(state: CatalogStateModel): boolean {
    return state.isCatalogGetFatalError;
  }

  @Selector()
  public static hasBooleanFilters(state: CatalogStateModel): boolean {
    return !isEmpty(pickBy(state.filter?.booleanFilters, isNotNull => isNotNull !== null));
  }

  @Selector()
  public static getCatalogUpdate(state: CatalogStateModel): CatalogView | null {
    return state.catalogUpdate;
  }

  @Selector()
  public static hasFilters(state: CatalogStateModel): boolean {
    if (
      state.filter === null ||
      Object.keys(state.filter).length === 0 ||
      ((!state.filter.openCategoryIds || state.filter.openCategoryIds?.length === 0) &&
        (!state.filter.providerIds || state.filter.providerIds?.length === 0) &&
        (!state.filter.newRecommendedFavorites || state.filter.newRecommendedFavorites === 'all') &&
        state.filter.booleanFilters &&
        (state.filter.booleanFilters.activeStatus === true || (state.filter.booleanFilters.activeStatus as any) === 'true') &&
        Object.keys(state.filter?.booleanFilters)
          .filter(r => r !== 'activeStatus')
          .filter(x => state.filter?.booleanFilters && state.filter.booleanFilters[x] !== null).length === 0)
    )
      return false;
    return !isEqual(state.filter, {});
  }

  @Selector()
  public static hasMobileFilters(state: CatalogStateModel): boolean {
    if (
      state.filter === null ||
      Object.keys(state.filter).length === 0 ||
      ((!state.filter.newRecommendedFavorites || state.filter.newRecommendedFavorites === 'all') &&
        state.filter.booleanFilters &&
        (state.filter.booleanFilters.activeStatus === true || (state.filter.booleanFilters.activeStatus as any) === 'true') &&
        Object.keys(state.filter?.booleanFilters)
          .filter(r => r !== 'activeStatus')
          .filter(x => state.filter?.booleanFilters && state.filter.booleanFilters[x] !== null).length === 0)
    )
      return false;
    return !isEqual(state.filter, {});
  }

  @Selector()
  public static hasCategoryFilters(state: CatalogStateModel): boolean {
    if (
      state.filter === null ||
      Object.keys(state.filter).length === 0 ||
      ((!state.filter.openCategoryIds || state.filter.openCategoryIds?.length === 0) &&
        (!state.filter.providerIds || state.filter.providerIds?.length === 0))
    )
      return false;
    return !isEqual(state.filter, {});
  }

  @Action(GetCatalogs)
  public getCatalogs(ctx: StateContext<CatalogStateModel>): Observable<CatalogView[]> {
    return this.getCatalogViews(ctx);
  }

  @Action(ClearCatalogs)
  public clearCatalogs(ctx: StateContext<CatalogStateModel>): void {
    ctx.patchState({
      catalogs: []
    });
  }

  @Action(UpdateCatalog)
  public updateCatalog(ctx: StateContext<CatalogStateModel>, params: UpdateCatalog): Observable<CatalogView> {
    ctx.setState(
      patch({
        catalogs: updateItem(item => (item as CatalogView).id === params.currentCatalog.id, patch(params.currentCatalog))
      })
    );
    ctx.patchState({
      catalogUpdate: params.currentCatalog
    });

    return of(params.currentCatalog);
  }

  @Action(ChangeCatalogFilter)
  public changeCatalogFilter(ctx: StateContext<CatalogStateModel>, action: ChangeCatalogFilter): void {
    const currentFilter = ctx.getState().filter;
    const newFilter = action.filter;
    ctx.patchState({ loadingDataBeforeChangeFilter: action.isCatalogsDispatch });
    let copyCurrentFilter: CatalogFilter = { ...newFilter };
    if (action.overwriteFilter || !currentFilter || isEqual(newFilter, {})) {
      ctx.patchState({
        filter: copyCurrentFilter
      });
    } else {
      copyCurrentFilter = { ...currentFilter };

      Object.keys(newFilter).forEach(key => {
        switch (key) {
          case FilterName.Provider:
            copyCurrentFilter.providerIds = newFilter.providerIds;
            break;
          case FilterName.SearchString:
            copyCurrentFilter.searchString = newFilter.searchString;
            break;
          case FilterName.OpenCategory:
            copyCurrentFilter.openCategoryIds = newFilter.openCategoryIds;
            break;
          case FilterName.BooleanFilters:
            copyCurrentFilter.booleanFilters = newFilter.booleanFilters;
            break;
          case FilterName.CatalogIds:
            copyCurrentFilter.catalogIds = newFilter.catalogIds;
            break;
          case FilterName.NewRecommendedFavorites:
            copyCurrentFilter.newRecommendedFavorites = newFilter.newRecommendedFavorites;
            break;
          default:
            break;
        }
      });

      ctx.patchState({
        filter: copyCurrentFilter
      });
    }

    ctx.patchState({ changeFilter: true });
    if (action.isCatalogsDispatch) {
      if (isEqual(newFilter, {})) {
        this.router.navigate([], { queryParams: {} });
        this.location.replaceState(this.router.url.split('?')[0]);
      }

      const booleanFilters =
        copyCurrentFilter.booleanFilters && Object.keys(copyCurrentFilter.booleanFilters).length === 0
          ? {
              winterSeason: null,
              accessibility: null,
              activeStatus: null,
              allSeasons: null,
              datasetType: null,
              iso: null,
              archiveStatus: null,
              federalDict: null,
              ordinaryDict: null,
              regionalDict: null,
              summerSeason: null,
              withMap: null,
              withoutMap: null
            }
          : copyCurrentFilter.booleanFilters;
      setLocationQueryParams<CatalogQueryParams>(
        {
          newRecommendedFavorites: copyCurrentFilter.newRecommendedFavorites === 'new' ? 'new' : undefined,
          searchString: copyCurrentFilter?.searchString,
          providerIds: copyCurrentFilter?.providerIds?.join(','),
          openCategoryIds: copyCurrentFilter?.openCategoryIds?.join(','),
          ...booleanFilters
        },
        this.router,
        this.location
      );

      ctx.dispatch(new GetCatalogs());
    }
  }

  @Action(ChangeSortingType)
  public changeSortingType(ctx: StateContext<CatalogStateModel>, action: ChangeSortingType): void {
    const [sortType, direction] = Object.entries(action.sortingType)[0];
    setLocationQueryParams<CatalogQueryParams>(
      {
        sorting: `${sortType}-${direction}`
      },
      this.router,
      this.location
    );
    ctx.patchState({
      sortingType: action.sortingType
    });
    if (action.isCatalogsDispatch) {
      ctx.patchState({ changeFilter: true });
      ctx.dispatch(new GetCatalogs());
    }
  }

  @Action(GetExtraCatalog)
  public getExtraCatalogCategories(ctx: StateContext<CatalogStateModel>): Observable<CatalogView[]> {
    return this.getCatalogViews(ctx);
  }

  @Action(GetRecommendedCatalogs)
  public getRecommendedCatalogs(ctx: StateContext<CatalogStateModel>): Observable<number[]> {
    /*  let catalogIds = ctx.getState().recommendedCatalogIds;
    if (catalogIds) {
      ctx.dispatch(new ChangeCatalogFilter({ catalogIds, newRecommendedFavorites: 'recommended' }, true));
      return of(catalogIds);
    } */

    return this.catalogService.getRecommendations().pipe(
      map((res: RecommendedCatalogResponse) => {
        if (res.catalogList.length === 0) {
          ctx.dispatch(new ChangeCatalogFilter({ catalogIds: [-123], newRecommendedFavorites: 'recommended' }, true));
          return of([]);
        }
        const catalogIds = [...new Set(res.catalogList.map(catalog => catalog.catalogId))] ?? [];

        ctx.patchState({
          recommendedCatalogIds: catalogIds
        });
        ctx.dispatch(new ChangeCatalogFilter({ catalogIds, newRecommendedFavorites: 'recommended' }, true));
        return catalogIds;
      }),
      catchError((error: HttpErrorResponse) => {
        const errorMessage: SnackBarMessage[] = [
          {
            message: 'SNACK_BAR_MESSAGE.ERROR_LOADING_RECOMMENDED',
            translate: true
          },
          {
            message: `\n${error.message}`,
            translate: false
          }
        ];

        this.snackBar.open(MirSnackbarType.Error, errorMessage);

        return of(undefined);
      })
    );
  }
  @Action(HandleCatalogHeaderToggle)
  public handleCatalogHeaderToggle(ctx: StateContext<CatalogStateModel>, action: HandleCatalogHeaderToggle): void {
    const user = this.store.selectSnapshot(AuthState.authenticatedUser);
    switch (action.type) {
      case 'favorites':
        if (user)
          this.store
            .dispatch(new GetFavoriteCatalogs())
            .pipe(take(1))
            .subscribe(() => {
              const catalogIds = this.store.selectSnapshot(FavoriteCatalogsState.favoriteCatalogIds);
              if (catalogIds?.length === 0) {
                this.store.dispatch(new ChangeCatalogFilter({ catalogIds: [-123], newRecommendedFavorites: 'favorites' }, action.isCatalogDispatch));
              } else {
                this.store.dispatch(new ChangeCatalogFilter({ catalogIds, newRecommendedFavorites: 'favorites' }, action.isCatalogDispatch));
              }
            });
        break;
      case 'recommended':
        if (user) this.store.dispatch(new GetRecommendedCatalogs());
        break;
      case 'new':
        this.store.dispatch(new ChangeCatalogFilter({ catalogIds: [], newRecommendedFavorites: 'new' }, action.isCatalogDispatch));
        break;

      case 'all':
        this.store.dispatch(new ChangeCatalogFilter({ catalogIds: [], newRecommendedFavorites: 'all' }, action.isCatalogDispatch));
        break;
      default:
        break;
    }
  }
}

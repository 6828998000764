import { Component, ChangeDetectionStrategy, NgZone } from '@angular/core';
import { CustomIcons } from '@shared/models/custom-icons';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '@auth/services/auth.service';
import { AuthorizationDialogComponent } from '@auth/components/authorization-dialog/authorization-dialog.component';
import { Store } from '@ngxs/store';
import { Logout } from '@auth/store/auth/auth.action';

@Component({
  selector: 'app-re-authorization-dialog',
  templateUrl: './re-authorization-dialog.component.html',
  styleUrls: ['./re-authorization-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReAuthorizationDialogComponent {
  public icons = CustomIcons;
  constructor(
    private dialogRef: MatDialogRef<AuthorizationDialogComponent>,
    private authService: AuthService,
    private store: Store,
    private ngZone: NgZone
  ) {}

  public close(): void {
    this.ngZone.run(() => {
      this.dialogRef.close();
    });
  }

  public reAuthorization(): void {
    localStorage.setItem(
      'auth',
      JSON.stringify({
        user: null
      })
    );
    this.authService.reAuthorization();
  }

  public logout(): void {
    this.store.dispatch(new Logout(false, false));
  }
}

import { Interest } from '../../models/interest';

export class GetUserInterests {
  public static readonly type = '[Get] GetUserInterests';
}

export class SaveUserInterests {
  public static readonly type = '[Save] SaveUserInterests';
  constructor(public interests: Interest[]) {}
}

export class GetUserNotifications {
  public static readonly type = '[Get] User Notifications';
  constructor(public searchString = '') {}
}

export class ChangeNotificationPaginator {
  public static readonly type = '[Change] Notifications paginator';
  constructor(public limit: number, public offset: number) {}
}

export class CheckAllNotificationRead {
  public static readonly type = '[CheckAllRead] Notifications';
}

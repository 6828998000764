import { Injectable } from '@angular/core';
import { ServerLinkName, ServerLinksVariables } from '@shared/models/server-links-model';
import { environment } from '@environments/environment';
import { cloneDeep } from 'lodash';
import { SnackBarService } from '@shared/components/snackbar-message/services/snackbar.service';
import { MirSnackbarType } from '@shared/components/snackbar-message/model/snackbar';
import { Stend } from '@shared/models/Stend';

@Injectable({
  providedIn: 'root'
})
export class ServerLinks {
  private readonly _links = new Map<ServerLinkName, ServerLinksVariables>()
    .set(ServerLinkName.EhdApi, {
      prod: '/ehdapi/',
      dev: '/ehdapi/',
      stage: '/ehdapi/'
    })

    .set(ServerLinkName.OldOpod, {
      prod: '/old_opod/',
      dev: '/old_opod/',
      stage: '/old_opod/'
    })
    .set(ServerLinkName.Ehdws, {
      prod: '/EHDWS/',
      dev: '/EHDWS/',
      stage: '/EHDWS/'
    })
    .set(ServerLinkName.OpenData, {
      prod: '/odata/',
      dev: '/odata/',
      stage: '/odata/'
    })
    .set(ServerLinkName.File, {
      prod: 'https://op.mos.ru/MEDIA/showFile?id=',
      dev: 'https://op.proitr.ru/MEDIA/showFile?id=',
      stage: 'https://op-stage.mos.ru/MEDIA/showFile?id='
    })
    .set(ServerLinkName.DownloadFile, {
      prod: 'https://op.mos.ru/MEDIA/getFile?id=',
      dev: 'https://op.proitr.ru/MEDIA/getFile?id=',
      stage: 'https://op-stage.mos.ru/MEDIA/getFile?id='
    })
    .set(ServerLinkName.Media, {
      prod: '/MEDIA/',
      dev: '/MEDIA/',
      stage: '/MEDIA/'
    })
    .set(ServerLinkName.Lk, {
      prod: '/lk/',
      dev: '/lk/',
      stage: '/lk/'
    })
    .set(ServerLinkName.DynApi, {
      prod: '/dyn_api/',
      dev: '/dyn_api/',
      stage: '/dyn_api/'
    })
    .set(ServerLinkName.VkData, {
      prod: '/vkdata/',
      dev: '/vkdata/',
      stage: '/vkdata/'
    })
    .set(ServerLinkName.GlobalSearch, {
      prod: '/search/',
      dev: '/search/',
      stage: '/search/'
    })
    .set(ServerLinkName.ApiData, {
      prod: '/apidata/',
      dev: '/apidata/',
      stage: '/apidata/'
    })
    .set(ServerLinkName.Login, {
      prod: 'https://login.mos.ru/',
      dev: 'https://login-tech.mos.ru/',
      stage: 'https://login-tech.mos.ru/'
    })
    .set(ServerLinkName.ApiKey, {
      prod: '',
      dev: 'a5e61a97-0cbf-462d-bebd-6987de443790',
      stage: ''
    })
    .set(ServerLinkName.VkGroupLink, {
      prod: 'https://vk.com/club218696039',
      dev: 'https://vk.com/club219941828',
      stage: 'https://vk.com/club219941828'
    })
    .set(ServerLinkName.VkBoardLink, {
      prod: 'https://vk.com/board218696039',
      dev: 'https://vk.com/board219941828',
      stage: 'https://vk.com/board219941828'
    })
    .set(ServerLinkName.VkForumLink, {
      prod: 'https://vk.com/wall-218696039',
      dev: 'https://vk.com/wall-219941828',
      stage: 'https://vk.com/wall-219941828'
    })
    .set(ServerLinkName.VkGroupLinkWall, {
      prod: 'https://vk.com/club218696039?w=wall-218696039_',
      dev: ' https://vk.com/club219941828?w=wall-219941828_',
      stage: 'https://vk.com/club219941828?w=wall-219941828_'
    })
    .set(ServerLinkName.ApiLink, {
      prod: 'https://apidata.mos.ru',
      dev: 'https://apidata.mos.ru',
      stage: 'https://apidata-stage.mos.ru'
    })
    .set(ServerLinkName.CreateAppMail, {
      prod: 'support-op@mos.ru',
      dev: 'mytestprm@yandex.ru',
      stage: 'mytestprm@yandex.ru'
    })
    .set(ServerLinkName.UserPushNotify, {
      prod: 'https://op.mos.ru/push/',
      dev: 'https://op.proitr.ru/push/',
      stage: 'https://op-stage.mos.ru/push/'
    });

  constructor(private snackBar: SnackBarService) {}

  public get Links(): Map<ServerLinkName, ServerLinksVariables> {
    return cloneDeep(this._links);
  }

  public getLink(link: ServerLinkName): string {
    const currentLink = this._links.get(link);

    let finalLink;

    if (!currentLink) {
      this.snackBar.open(MirSnackbarType.Error, [
        {
          message: 'SNACK_BAR_MESSAGE.SERVICE_FAILURE_RESTART_PAGE',
          translate: true
        }
      ]);
      throw new Error('Не найдена ссылка, запрос упал!');
    }

    switch (environment.stend) {
      case Stend.Prod:
        finalLink = currentLink.prod;
        break;
      case Stend.Stage:
        finalLink = currentLink.stage;
        break;
      case Stend.Dev:
      default:
        finalLink = currentLink.dev;
    }

    return finalLink;
  }
}

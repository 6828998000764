import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { EMPTY, mergeMap, Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { SharedService } from '@shared/services/shared.service';
import {
  AllNotificationRead,
  ClearUserApiKey,
  CreateUserApiKey,
  GetCatalogProviders,
  GetCategories,
  GetDictionaryValues,
  GetNoticesCount,
  GetPopularApps,
  GetRecommendationCatalogItemsForUser,
  GetUserApiKey,
  GetUserDataProfiles,
  GetVkNews,
  ShowLayout
} from '@shared/state/shared/shared.action';
import { SelectModel } from '@shared/components/select/models/select';
import {
  DictionaryValues,
  DictionaryValuesResponseArray,
  Categories,
  RecommendationForCatalogItems,
  NoticeCountView,
  CatalogProviders
} from '@shared/models/shared.service';
import { patch, updateItem } from '@ngxs/store/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { MirSnackbarType } from '@shared/components/snackbar-message/model/snackbar';
import { SnackBarService } from '@shared/components/snackbar-message/services/snackbar.service';
import { VkCardData } from '@shared/components/vk-card/models';
import { VkService } from '@shared/services/vk.service';
import { DateTime } from 'luxon';
import { Gender } from '@auth/models/auth.state';
import { petsOptions } from '@shared/models/pets';
import { MobileApp } from '../../../main/models/mobile-apps';
import { ProfileTabInfo, UserDataProfilesHeader, UserDataProfilesResponse } from '../../../personal-profile/models/user.service';
import { CheckAllNotificationRead } from '../../../personal-profile/store/user/user.action';

export type SharedStateModel = {
  countCategories: number;
  userProfileTab: ProfileTabInfo | null;
  showLayout: boolean;
  userDataProfiles: UserDataProfilesHeader | null;
  categories: Categories[];
  dictionaryValues: DictionaryValues[];
  recommendationCatalogItemsForUser: RecommendationForCatalogItems[];
  noticeCount: NoticeCountView | null;
  userApiKey: string | null;
  popularApps: MobileApp[] | null;
  vkNews: VkCardData[] | null;
  providers: CatalogProviders[];
  countProviders: number;
};

@State<SharedStateModel>({
  name: 'globalState',
  defaults: {
    userProfileTab: null,
    userDataProfiles: null,
    showLayout: true,
    countCategories: 0,
    categories: [],
    dictionaryValues: [],
    recommendationCatalogItemsForUser: [],
    noticeCount: null,
    userApiKey: null,
    popularApps: null,
    vkNews: null,
    providers: [],
    countProviders: 0
  }
})
@Injectable()
export class SharedState {
  constructor(private sharedService: SharedService, private snackBar: SnackBarService, private vk: VkService) {}

  @Selector()
  public static getNoticeCount(state: SharedStateModel): NoticeCountView | null {
    return state.noticeCount;
  }

  @Selector()
  public static userDataProfiles(state: SharedStateModel): UserDataProfilesHeader | null {
    return state.userDataProfiles;
  }

  @Selector()
  public static userProfileTab(state: SharedStateModel): ProfileTabInfo | null {
    return state.userProfileTab;
  }

  @Selector()
  public static getPopularApps(state: SharedStateModel): MobileApp[] {
    return state.popularApps || [];
  }

  @Selector()
  public static getShowLayout(state: SharedStateModel): boolean {
    return state.showLayout;
  }

  @Selector()
  public static getVkNews(state: SharedStateModel): VkCardData[] | null {
    return state.vkNews;
  }

  @Selector()
  public static getCountCategories(state: SharedStateModel): number {
    return state.countCategories;
  }

  @Selector()
  public static userDataApiKey(state: SharedStateModel): string | null {
    return state.userApiKey;
  }

  @Selector()
  public static getRecommendationCatalogItemsForUser(state: SharedStateModel): (catalogId: number) => number[] {
    return (catalogId: number) => state.recommendationCatalogItemsForUser.find(x => x.catalogId === catalogId)?.itemsId ?? [];
  }

  @Selector()
  public static getCategories(state: SharedStateModel): Categories[] {
    return state.categories;
  }

  @Selector()
  public static getCountProviders(state: SharedStateModel): number {
    return state.countProviders;
  }

  @Selector()
  public static getProviders(state: SharedStateModel): CatalogProviders[] {
    return state.providers;
  }
  @Selector()
  public static getDictionaryValue(state: SharedStateModel): (dictionaryId: number) => SelectModel[] {
    return (dictionaryId: number) => state.dictionaryValues.find(x => x.dictionaryId === dictionaryId)?.dictionaryValues ?? [];
  }

  @Action(GetCategories)
  public getCategories(ctx: StateContext<SharedStateModel>): Observable<Categories[]> {
    const currentCategories = ctx.getState().categories;
    if (currentCategories.length === 0) {
      return this.sharedService.getCategories().pipe(
        tap(categories => {
          const isNotEmptyCategories = categories.filter(x => x.fullName);
          ctx.patchState({
            categories: isNotEmptyCategories,
            countCategories: isNotEmptyCategories.length
          });
        })
      );
    }
    return of(currentCategories);
  }

  @Action(ShowLayout)
  public showLayout(ctx: StateContext<SharedStateModel>, params: ShowLayout): Observable<boolean> {
    ctx.patchState({
      showLayout: params.show
    });

    return of(params.show);
  }

  @Action(GetDictionaryValues)
  public getDictionaryValues(ctx: StateContext<SharedStateModel>, action: GetDictionaryValues): Observable<DictionaryValuesResponseArray> {
    const dictionaryId = action.dictionaryId;
    const dicValues = ctx.getState().dictionaryValues;

    if (dictionaryId && !dicValues.find(x => x.dictionaryId === dictionaryId)) {
      ctx.patchState({
        dictionaryValues: [
          ...dicValues,
          {
            dictionaryId: dictionaryId,
            dictionaryValues: []
          }
        ]
      });
      return this.sharedService.getDictionaryValues(dictionaryId).pipe(
        tap(res => {
          ctx.setState(
            patch({
              dictionaryValues: updateItem(
                item => (item as DictionaryValues).dictionaryId === dictionaryId,
                patch({
                  dictionaryValues: res?.response?.map(v => ({
                    value: v.key,
                    title: v.value
                  }))
                })
              )
            })
          );
        })
      );
    }
    return of();
  }

  @Action(GetRecommendationCatalogItemsForUser)
  public getRecommendationCatalogItemsForUser(
    ctx: StateContext<SharedStateModel>,
    action: GetRecommendationCatalogItemsForUser
  ): Observable<number[]> {
    const allRecommendation = ctx.getState().recommendationCatalogItemsForUser;
    const recommendationCatalogItemsForUser = allRecommendation.find(x => x.catalogId === action.catalogId);
    if (recommendationCatalogItemsForUser) {
      return of(recommendationCatalogItemsForUser.itemsId);
    }

    return this.sharedService.getRecommendationForCatalogItems(action.catalogId).pipe(
      map(res => {
        const findCatalogList = res.catalogList.find(x => x.catalogId === action.catalogId);
        if (findCatalogList) {
          const itemsId = findCatalogList.objects.map(x => x.id);
          ctx.patchState({
            recommendationCatalogItemsForUser: [
              ...allRecommendation,
              {
                catalogId: action.catalogId,
                itemsId: itemsId
              }
            ]
          });
          return itemsId;
        }

        return [];
      }),
      catchError((error: HttpErrorResponse) => {
        this.snackBar.open(MirSnackbarType.Error, [
          {
            message: 'SNACK_BAR_MESSAGE.ERROR_LOADING_RECOMMENDED',
            translate: true
          },
          {
            translate: false,
            message: `\n${error.message}`
          }
        ]);

        return of([]);
      })
    );
  }

  @Action(GetNoticesCount, { cancelUncompleted: true })
  public getNoticesCount(ctx: StateContext<SharedStateModel>): Observable<NoticeCountView> {
    return this.sharedService.getNoticeCount().pipe(
      tap(res => {
        ctx.patchState({
          noticeCount: res
        });
      })
    );
  }

  @Action(AllNotificationRead)
  public allNotificationRead(ctx: StateContext<SharedStateModel>): Observable<never> {
    const noticeCount = ctx.getState().noticeCount;
    if (noticeCount) {
      return this.sharedService.allNotificationRead().pipe(
        tap(() => {
          const newNoticeCount = {
            all: noticeCount.all,
            unread: 0
          };
          ctx.patchState({
            noticeCount: newNoticeCount
          });
          ctx.dispatch(new CheckAllNotificationRead());
        })
      );
    }
    return EMPTY;
  }

  @Action(GetUserApiKey)
  public getUserApiKey(ctx: StateContext<SharedStateModel>): Observable<string | null> {
    const apiKey = ctx.getState().userApiKey;
    if (apiKey) {
      return of(apiKey);
    }
    return this.sharedService.getUserApiKey().pipe(
      tap(res => {
        ctx.patchState({
          userApiKey: res
        });
      }),
      catchError((error: HttpErrorResponse) => {
        this.snackBar.open(MirSnackbarType.Error, [
          {
            message: 'SNACK_BAR_MESSAGE.ERROR_API_KEY_GET',
            translate: true
          },
          {
            message: `\n${error.message}`,
            translate: false
          }
        ]);

        return throwError(() => error);
      })
    );
  }

  @Action(CreateUserApiKey)
  public createUserApiKey(ctx: StateContext<SharedStateModel>): Observable<string | null> {
    return this.sharedService.createUserApiKey().pipe(
      tap(res => {
        if (!res) {
          this.snackBar.open(MirSnackbarType.Error, [
            {
              message: 'SNACK_BAR_MESSAGE.ERROR_API_KEY_CREATED',
              translate: true
            }
          ]);
        } else {
          this.snackBar.open(MirSnackbarType.Success, [
            {
              message: 'SNACK_BAR_MESSAGE.GENERATED_APIKEY',
              translate: true
            }
          ]);
        }
        ctx.patchState({
          userApiKey: res
        });
      }),
      catchError((error: HttpErrorResponse) => {
        this.snackBar.open(MirSnackbarType.Error, [
          {
            message: 'SNACK_BAR_MESSAGE.ERROR_API_KEY_CREATED',
            translate: true
          },
          {
            translate: false,
            message: `\n${error.message}`
          }
        ]);
        return throwError(() => error);
      })
    );
  }

  @Action(ClearUserApiKey)
  public clearUserApiKey(ctx: StateContext<SharedStateModel>): void {
    ctx.patchState({
      userApiKey: null
    });
  }

  @Action(GetPopularApps)
  public fetchPopularApps(ctx: StateContext<SharedStateModel>): Observable<MobileApp[]> {
    const popularApps = ctx.getState().popularApps;
    if (popularApps) {
      return of(popularApps);
    }

    return this.sharedService.getPopularApps().pipe(tap(popularApps => ctx.patchState({ popularApps })));
  }

  @Action(GetVkNews)
  public fetchVkNews(ctx: StateContext<SharedStateModel>): Observable<VkCardData[]> {
    const vkNews = ctx.getState().vkNews;
    if (vkNews) {
      return of(vkNews);
    }

    return this.vk.getPostsInfo().pipe(tap(vkNews => ctx.patchState({ vkNews })));
  }

  @Action(GetUserDataProfiles, { cancelUncompleted: true })
  public getUserDataProfiles(ctx: StateContext<SharedStateModel>): Observable<UserDataProfilesHeader> {
    const userDataProfiles = ctx.getState().userDataProfiles;
    if (userDataProfiles && !userDataProfiles?.notFoundData) {
      return of(userDataProfiles);
    }
    return this.sharedService.getUserDataProfiles().pipe(
      mergeMap((response: UserDataProfilesResponse) => {
        if (!response) {
          return EMPTY;
        }
        if (response.errorText) {
          this.snackBar.open(MirSnackbarType.Error, [
            {
              translate: true,
              message: 'SNACK_BAR_MESSAGE.ERROR_REQUESTING_USER_DATA'
            },
            {
              translate: false,
              message: response.errorText
            }
          ]);

          return EMPTY;
        }
        const headerInfo: UserDataProfilesHeader = {
          contactEmail: response?.contacts?.contact_email[0]?.value ?? 'OTHER.NOT_DATA',
          birthDate: isNaN(new Date(response.birth_date).getDay())
            ? 'OTHER.NOT_DATA'
            : DateTime.fromJSDate(new Date(response.birth_date)).toFormat('dd.MM.yyyy'),
          gender: response.gender === Gender.Man ? Gender.Man : Gender.Female,
          notFoundData: false
        };

        const tabInfo: ProfileTabInfo = {
          pets: response?.pets?.map(res => ({
            birthDate: isNaN(new Date(res.birth_date).getDay())
              ? 'OTHER.NOT_DATA'
              : DateTime.fromJSDate(new Date(res.birth_date)).toFormat('dd.MM.yyyy'),
            species: petsOptions.find(x => x.id === +res.species)?.name ?? 'OTHER.NOT_DATA'
          })),
          cards: {
            troykaUid: response?.cards?.card_troyka[0].mdm_obj_id ?? null
          },
          documents: {
            osago: response.documents?.osago.map(x => ({ expiryDate: DateTime.fromJSDate(new Date(x.expiry_date)).toFormat('dd.MM.yyyy') })) ?? []
          },
          addresses: {
            jobAddress: {
              unom: response?.addresses?.addr_job ? response.addresses.addr_job[0].unom ?? null : null,
              address: null
            },
            livAddress: {
              unom: response?.addresses?.addr_live ? response?.addresses?.addr_live[0].unom ?? null : null,
              address: null
            }
          }
        };

        if (tabInfo.addresses.jobAddress.unom || tabInfo.addresses.livAddress.unom) {
          const unomIds = [tabInfo.addresses.jobAddress.unom, tabInfo.addresses.livAddress.unom].filter(x => x) as string[];
          return this.sharedService.getStreetName(unomIds).pipe(
            map(res => {
              if (res.response) {
                const liveAddress = tabInfo.addresses.livAddress;
                liveAddress.address = res.response.find(x => x.UNOM === +(liveAddress.unom ?? 0))?.ADDRESS ?? null;
                const jovAddress = tabInfo.addresses.jobAddress;
                jovAddress.address = res.response.find(x => x.UNOM === +(jovAddress.unom ?? 0))?.ADDRESS ?? null;
              }

              ctx.patchState({
                userDataProfiles: headerInfo,
                userProfileTab: tabInfo
              });

              return headerInfo;
            })
          );
        }

        ctx.patchState({
          userDataProfiles: headerInfo,
          userProfileTab: tabInfo
        });

        return of(headerInfo);
      }),
      catchError((error: HttpErrorResponse) => {
        const notFound = 'OTHER.NOT_DATA';
        const res: UserDataProfilesHeader = {
          contactEmail: notFound,
          birthDate: notFound,
          gender: null,
          notFoundData: true
        };
        ctx.patchState({
          userDataProfiles: res
        });
        this.snackBar.open(MirSnackbarType.Error, [
          {
            translate: true,
            message: 'SNACK_BAR_MESSAGE.ERROR_REQUESTING_USER_DATA'
          },
          {
            translate: false,
            message: `\n${error.message}`
          }
        ]);
        return throwError(() => error);
      })
    );
  }

  @Action(GetCatalogProviders)
  public getCatalogProviders(ctx: StateContext<SharedStateModel>): Observable<CatalogProviders[]> {
    const currentProviders = ctx.getState().providers;
    if (currentProviders.length === 0) {
      return this.sharedService.getProviders().pipe(
        tap(providers => {
          ctx.patchState({
            providers: providers,
            countProviders: providers.length
          });
        })
      );
    }
    return of(currentProviders);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgModule } from '@shared/components/svg/svg.module';
import { AuthorizationDialogComponent } from '@auth/components/authorization-dialog/authorization-dialog.component';
import { NgLetModule } from '@shared/directives/ng-let/ng-let.module';
import { AuthInterceptor } from '@auth/services/auth-interceptor.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { OkCancelModalModule } from '@shared/components/ok-cancel-modal/ok-cancel-modal.module';
import { ReAuthorizationDialogComponent } from './components/re-authorization-dialog/re-authorization-dialog.component';

@NgModule({
  declarations: [AuthorizationDialogComponent, ReAuthorizationDialogComponent],
  imports: [CommonModule, SvgModule, NgLetModule, TranslateModule, OkCancelModalModule],
  exports: [AuthorizationDialogComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true // option. This is required and tells Angular that HTTP_INTERCEPTORS is an array of values, rather than a single value.
    }
  ]
})
export class AuthModule {}

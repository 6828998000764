import { CatalogItemValue } from '../../../models/catalog';
import { NextToMeData } from '../models/catalog-item.service';

export class GetCatalogItemsData {
  public static readonly type = '[CatalogsItemData] Get data';
  constructor(public isDynamic: boolean, public sorting: { [p: string]: 'ASC' | 'DESC' } | null = null) {}
}

export class InitCatalogItemTable {
  public static readonly type = '[CatalogsItem] Init table';
  constructor(public isDynamic: boolean, public sorting: { [p: string]: 'ASC' | 'DESC' } | null = null) {}
}

export class GetCatalogItemAllCount {
  public static readonly type = '[GetCatalogItemAllCount] Get CatalogItemAllCount';
  constructor(public catalogId: number) {}
}

export class SetIsDynamic {
  public static readonly type = '[SetIsDynamic] is dynamic';
  constructor(public isDynamic: boolean, public locationChanged = true) {}
}

export class ChangedCatalogItemsPaginator {
  public static readonly type = '[CatalogsItem] Paginator changed';
  constructor(public pageIndex: number, public pageSize: number, public locationChanged = true) {}
}

export class ChangedCatalogItemsIsRecommendation {
  public static readonly type = '[CatalogsItem] IsRecommendation changed';
  constructor(public isRecommendationData: boolean, public catalogId: number | null = null, public locationChanged = true) {}
}

export class ChangedCatalogItemsIsNextToMeData {
  public static readonly type = '[CatalogsItem] IsNextToMeData changed';
  constructor(public nextToMeData: NextToMeData, public catalogId: number | null = null) {}
}

export class ChangedCatalogItemsFilter {
  public static readonly type = '[CatalogsItem] Filter changed';
  constructor(public criteria: string, public locationChanged = true) {}
}

export class ChangeCatalogItem {
  public static readonly type = '[ChangeCatalogItem] Change catalogItem values';
  constructor(public catalogItem?: CatalogItemValue, public locationChanged = true) {}
}

export class DeleteCatalogItem {
  public static readonly type = '[DeleteCatalogItem] Delete catalogItem';
  constructor(public catalogId: number) {}
}

export class DownloadFileNames {
  public static readonly type = '[CatalogsItem] download file names';
  constructor(public catalogItems: CatalogItemValue[]) {}
}

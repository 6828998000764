import { Component, ChangeDetectionStrategy, NgZone, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CustomIcons } from '@shared/models/custom-icons';
import { Store } from '@ngxs/store';
import { AuthState } from '@auth/store/auth/auth.state';
import { UserInfo } from '@auth/models/auth.state';

@Component({
  selector: 'app-ok-cancel-dialog',
  templateUrl: './ok-cancel-dialog.component.html',
  styleUrls: ['./ok-cancel-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OkCancelDialogComponent implements OnInit {
  public icons = CustomIcons;
  public user: UserInfo | null;
  constructor(private dialogRef: MatDialogRef<OkCancelDialogComponent>, private ngZone: NgZone, private store: Store) {}

  public ok(): void {
    this.ngZone.run(() => {
      this.dialogRef.close(true);
    });
  }

  public cancel(): void {
    this.ngZone.run(() => {
      this.dialogRef.close(false);
    });
  }

  public ngOnInit(): void {
    this.user = this.store.selectSnapshot(AuthState.authenticatedUser);
  }
}

import { ReleaseStatus } from '@shared/models/catalog.service';

export type ReqInfo = {
  filters?: any;
  message?: string;
  status?: number;
  validDate?: string;
};

export type GeoDataType = 'Point' | 'MultiLineString' | 'Polygon' | 'MultiPoint' | 'MultiPolygon' | 'GeometryCollection';

export type GeoData = {
  coordinates?: number[][][][] | number[][][] | number[][] | number[];
  type: GeoDataType;
  geometries?: GeoData[];
};

export type CatalogItemValue = {
  // eslint-disable-next-line @typescript-eslint/naming-convention,camelcase
  global_id: number;
  geoData: GeoData;
  numberClientOpod: number;
} & any;
export type CatalogList = {
  catalogId: number;
  objectIds: number[];
};
export type CatalogForUserResponse = {
  userId: number;
  catalog: CatalogList[];
};
export type AddOrDeleteCatalogListResponse = {
  statusId: number;
  message: string;
  detailMessage: string | null;
};

export type RecommendedCatalogResponse = {
  userId: number;
  catalogList: CatalogList[];
};

export type CatalogVersionRelease = {
  versionNum: number;
  releaseNum: number;
  catalogId: number;
  releaseDate: string;
  versionDate: string;
  releaseDateFull: string;
  defaultDate: string;
  actualVersion?: boolean;
  releaseStatus: ReleaseStatus;
  countRows: number;
};

export type NewCatalogVersionRelease = {
  idPublicCatalog: number;
  releases: {
    versionNum: number;
    releaseNum: number;
    releaseDate: string;
    versionDate: string;
    releaseStatus: ReleaseStatus;
    countRows: number;
  }[];
};

export type CatalogVersionReleaseInfo = {
  catalogId: number;
  versionRelease: CatalogVersionRelease[];
};

export type LoadingCatalogVersionRelease = {
  catalogId: number;
  versionReleaseInfo: CatalogVersionRelease | null;
};

export enum FileType {
  'JSON' = 'JSON',
  'XML' = 'XML',
  'XLSX' = 'XLSX',
  'CSV' = 'CSV'
}

export type StatusFileDownload = 'CREATED' | 'PROCESSING' | 'ERROR' | 'SUCCESS' | 'ARCHIVE';

export type TypeInfo = {
  idFile: number;
  status: StatusFileDownload | null;
  archiveFileSize: number;
  format: FileType;
  version?: string;
};

export type FileInfo = {
  id: number;
  files: TypeInfo[];
};

export type CreateFile = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  detail_message: string;
  message: string;
  status: number;
};

import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { PersonalProfileGuard } from '@auth/guards/personal-profile.guard';
import { FilesModule } from 'app/modules/secondary-pages/files/files.module';

export const routes: Routes = [
  {
    path: 'impaired-appeals',
    loadChildren: () => import('./modules/appeals-impaired/appeals-impaired.module').then(x => x.AppealsImpairedModule),
  },
  {
    path: 'opendata',
    loadChildren: () => import('./modules/data/data.module').then(x => x.DataModule),
  },
  {
    path: 'developers',
    loadChildren: () => import('./modules/developers/developers.module').then(x => x.DevelopersModule),
  },

  {
    path: 'personal-profile',
    loadChildren: () => import('./modules/personal-profile/personal-profile.module').then(x => x.PersonalProfileModule),
    canLoad: [PersonalProfileGuard]
  },
  {
    path: 'information-placement',
    loadChildren: () => import('./modules/information-placement/information-placement.module').then(x => x.InformationPlacementModule),
    canLoad: [PersonalProfileGuard]
  },

  {
    path: 'globalsearch',
    loadChildren: () => import('./modules/search/search.module').then(x => x.SearchModule),
  },

  {
    path: 'terms',
    loadChildren: () => import('./modules/secondary-pages/terms-of-use/terms-of-use.module').then(x => x.TermsOfUseModule),
  },

  {
    path: 'documents',
    loadChildren: () => import('./modules/secondary-pages/documents/documents.module').then(x => x.DocumentsModule),
  },

  {
    path: 'devAuth',
    loadChildren: () => import('./modules/dev-auth/dev-auth.module').then(x => x.DevAuthModule),
  },

  {
    path: 'visually-impaired',
    loadChildren: () =>
      import('./modules/version-for-visually-impaired/version-for-visually-impaired.module').then(x => x.VersionForVisuallyImpairedModule),
  },
  {
    path: '',
    loadChildren: () => import('./modules/main/main.module').then(x => x.MainModule)
  },

  {
    path: 'error',
    loadChildren: () => import('./modules/secondary-pages/error-page/error-page.module').then(x => x.ErrorPageModule)
  },

  {
    path: 'opodopendata',
    loadChildren: () => FilesModule
  },
  {
    path: 'classifier',
    redirectTo: 'opendata'
  },
  {
    path: 'datasets',
    redirectTo: 'opendata'
  },
  {
    path: '**',
    redirectTo: 'error'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      anchorScrolling: 'enabled',
      scrollOffset: [0, 159],
      scrollPositionRestoration: 'enabled',
      onSameUrlNavigation: 'reload'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { take } from 'rxjs/operators';
import { SnackbarData, MirSnackbarType, SnackBarMessage } from '@shared/components/snackbar-message/model/snackbar';
import { SnackbarMessageComponent } from '@shared/components/snackbar-message/component/snackbar-message/snackbar-message.component';
import { snackbarVariations } from '@shared/components/snackbar-message/model/snackbar-variations';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {
  private _queue: SnackbarData[] = [];
  private _started: boolean;
  private _ref: MatSnackBarRef<SnackbarMessageComponent> | null;

  constructor(private snackBar: MatSnackBar, private translateService: TranslateService) {
    // empty
  }

  private pushToQueue(data: SnackbarData): void {
    if (!this._queue) {
      this._queue = [];
    }
    this._queue.push(data);
  }

  private popFromQueue(): SnackbarData | undefined {
    return this._queue.pop();
  }

  private openNext(): void {
    const data = this.popFromQueue();
    if (!data) {
      return;
    }
    this.openSnackbar(data);
  }

  private openSnackbar(data: SnackbarData, isQueue = true): MatSnackBarRef<SnackbarMessageComponent> {
    if (this._started && isQueue) {
      this.pushToQueue(data);
      return this._ref as MatSnackBarRef<SnackbarMessageComponent>;
    }
    this._started = true;
    const ref = this.snackBar.openFromComponent(SnackbarMessageComponent, {
      data: data,
      duration: data.duration ?? 3000,
      panelClass: ['default-snackbar'],
      horizontalPosition: data.horizontalPosition,
      verticalPosition: data.verticalPosition
    });

    ref
      .afterDismissed()
      .pipe(take(1))
      .subscribe(() => {
        this._started = false;
        this._ref = null;
        this.openNext();
      });
    return ref;
  }

  public open(snackbarType: MirSnackbarType, messages: SnackBarMessage[], duration = 3000): void {
    const message = messages
      .map(res => {
        if (res.translate) {
          res.message = this.translateService.instant(res.message);
        }
        return res.message;
      })
      .join(' ');
    this._ref = this.openSnackbar({ ...snackbarVariations[snackbarType], duration: duration, message });
  }

  public dismiss(): void {
    this._ref?.dismiss();
  }
}

export enum CustomIcons {
  Star = 'star',
  NewIcon = 'new',
  BuildCategory = 'build-category',
  CultureCategory = 'culture-category',
  EducationCategory = 'education-category',
  GovernmentCategory = 'government-category',
  Links = 'links',
  Vk = 'vk',
  Facebook = 'facebook',
  Instagram = 'instagram',
  Ok = 'ok',
  Twitter = 'twitter',
  MainHeaderPeople = 'main-header-people',
  ArrowRight = 'arrow-right',
  ArrowDropDown = 'arrow_drop_down',
  ArrowDropUp = 'arrow_drop_up',
  Residents = 'residents',
  Experts = 'experts',
  Developers = 'developers',
  Close = 'close',
  Eye = 'eye',
  Logo = 'logo',
  Eng = 'eng',
  Flag = 'flag',
  Filter = 'filter',
  Search = 'search',
  Sort = 'sort',
  MoreVert = 'more_vert',
  EmptyStar = 'empty_star',
  Feedback = 'feedback',
  AvatarMan = 'avatar_man',
  AvatarWoman = 'account_woman',
  AccountAvatar = 'account_avatar',
  Notifications = 'notifications',
  FillNotifications = 'fillNotifications',
  NotificationsAndFavorite = 'notificationsAndFavorite',
  Logout = 'logout',
  Login = 'login',
  Interests = 'interests',
  DoneAll = 'done_all',
  Api = 'api',
  Card = 'card',
  Transport = 'transport',
  Pets = 'pets',
  Child = 'child',
  Adds = 'adds',
  Health = 'health',
  Delete = 'delete',
  Plus = 'plus',
  Comment = 'comment',
  Like = 'like',
  Tune = 'tune',
  ApiKey = 'api_key',
  ApiDoc = 'api_doc',
  ApiData = 'api_data',
  ApiUse = 'api_use',
  DepInfTech = 'dep_inf_tech',
  GorVideo = 'gor_video',
  InvestMosRu = 'invest_mos_ru',
  MosRu = 'mos_ru',
  SobaninPortal = 'sobanin_portal',
  LocationCrossed = 'location_crossed',
  Location = 'location',
  Archive = 'archive',
  ArchiveFrame = 'archive_frame',
  Moscow = 'moscow',
  Federal = 'federal',
  Doc = 'doc',
  Question = 'question',
  MobileMenu = 'mobile_menu',
  MobileMenuOpen = 'mobile_menu_open',
  Apps = 'apps',
  Ios = 'ios',
  Android = 'android',
  Windows = 'win',
  Bookmark = 'bookmark',
  BookmarkFill = 'bookmark-fill',
  Downloaded = 'downloaded',
  DownloadedFill = 'downloaded-fill',
  Selected = 'selected',
  ExpandMoreDown = 'expand_more_down',
  ExpandMoreUp = 'expand_more_up',
  Birthday = 'birthday',
  GenderMan = 'gender_man',
  GenderWoman = 'gender_woman',
  Email = 'email',
  Info = 'info',
  FullScreen = 'fullscreen',
  FullScreenNon = 'fullscreen-non',
  Autumn = 'autumn',
  NotAvailable = 'not_available',
  NotFound = 'not_found',
  Page404 = 'page_404',
  ServiceUnavailable = 'service_unavailable',
  DefaultMapZoom = 'default_map_zoom',
  Minus = 'minus',
  Error = 'error',
  SnackBarInfo = 'snackbar-info',
  Success = 'success',
  Warning = 'warning',
  FacebookThin = 'facebook-thin',
  LocationFilled = 'location-filled',
  CloseRound = 'close-round',
  Archive18 = 'archive_18',
  Downloaded18 = 'downloaded_18',
  Location18 = 'location_18',
  StarRate18 = 'star_rate_18',
  Viewed18 = 'viewed_18',
  Autumn32 = 'autumn_32',
  Attention = 'attention',
  TableOfContents = 'table_of_contents',
  VideoPlay = 'video_play',
  VkBig = 'vk-big',
  UnknownUser = 'unknown_user',
  AddPlus = 'add_plus',
  Loading = 'loading',
  Dynamic = 'dynamic',
  ExpandAll24 = 'expand_all_24',
  CollapseAll24 = 'collapse_all_24',
  Copy = 'copy',
  HideEye = 'hide_eye',
  Eye18 = 'eye18',
  HideEye18 = 'hide_eye18',
  Copy18 = 'copy18',
  CircleSelected18 = 'circle_selected_18',
  List24 = 'list_24',
  FirstPage = 'first_page',
  LastPage = 'last_page',
  PrevPage = 'prev_page',
  NextPage = 'next_page',
  PrevPageCarousel = 'prev_page_carousel',
  NextPageCarousel = 'next_page_carousel',
  MyTransport = 'my_transport',
  OrientationMap = 'orientation_map',
  BigTriangleDown = 'big_triangle_down',
  BigTriangleUp = 'big_triangle_up',
  NextPageImpaired = 'next_page_impaired',
  PrevPageImpaired = 'prev_page_impaired',
  FirstPageImpaired = 'first_page_impaired',
  LastPageImpaired = 'last_page_impaired',
  ImpairedUp = 'impaired_up',
  ImpairedDown = 'impaired_down',
  ImpairedLeft = 'impaired_left',
  ImpairedVk = 'impaired_vk',
  ImpairedComment = 'impaired_comment',
  ImpairedLike = 'impaired_like',
  ImpairedSelect = 'impaired_selected',
  Refresh = 'refresh',
  MyLocation = 'my_location',
  BackToObject = 'back_to_object',
  LogoMosRu = 'logo-mos-ru',
  Pinned = 'pinned'
}

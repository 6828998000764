import { Locale, LocaleType } from '@shared/models/locale';

export const locales: LocaleType[] = [
  {
    id: Locale.RU_RU,
    title: 'LAYOUT.LANGUAGE'
  },
  {
    id: Locale.EN_US,
    title: 'LAYOUT.LANGUAGE'
  }
];

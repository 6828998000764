<div class="container">
  <div class="title">
    <span class="h3">{{ 'AUTHORIZATION.HEADER' | translate }}</span>
    <button class="btn-empty" (click)="close()">
      <svg [icon]="icons.Close" class="dark-gray-color svg24 close-icon" />
    </button>
  </div>

  <div class="body">
    <p class="body-2">
      {{ 'AUTHORIZATION.SIGN_IN' | translate }} <a href="https://www.mos.ru/"> mos.ru</a>.
      {{ 'AUTHORIZATION.LOG_IN' | translate }}
    </p>

    <p>{{ 'AUTHORIZATION.REGISTER' | translate }}.</p>
  </div>

  <div class="footer">
    <button (click)="loginMosRu()" class="entry">
      <span class="link-3 text">{{ 'AUTHORIZATION.LOG_IN_BY_MOS_RU' | translate }}</span>
    </button>
    <button (click)="registrationMosRu()" class="register">
      <span class="link-3 text">{{ 'AUTHORIZATION.REGISTER_BUTTON' | translate }}</span>
    </button>
  </div>
</div>

import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { UserInfo } from '@auth/models/auth.state';
import { ServerLinkName } from '@shared/models/server-links-model';
import { ServerLinks } from '@shared/services/server-links';
import { HttpClient } from '@angular/common/http';
import { isMobile } from '@utils/other-utils';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _urlLkApi = '';
  private _loginLink = '';
  constructor(@Inject(DOCUMENT) private document: Document, serverLinks: ServerLinks, private http: HttpClient) {
    this._urlLkApi = serverLinks.getLink(ServerLinkName.Lk);
    this._loginLink = serverLinks.getLink(ServerLinkName.Login);
  }

  private getCurrentUrl(): string {
    return this.document.location.href.replace(/%2F/g, '').replace(/&/g, '%26');
  }

  public login(code: string): Observable<UserInfo> {
    return this.http.get<UserInfo>(`${this._urlLkApi}api/v1/auth/authUserSUDIR?redirect_uri=${this.getCurrentUrl()}&code=${code}`);
  }

  public logout(): Observable<any> {
    return this.http.get<any>(`${this._urlLkApi}api/v1/auth/logout`);
  }

  public saveUserGeo(
    userGeo: {
      x: number;
      y: number;
    },
    dateUserGeo: string
  ): Observable<any> {
    const body = {
      userGeo: userGeo,
      dateUserGeo: dateUserGeo,
      device: isMobile() ? 1 : 0
    };
    return this.http.post<any>(`${this._urlLkApi}api/v1/userGeo`, body);
  }

  public logoutSudir(): void {
    this.document.location.href = `${this._loginLink}sps/login/logout?client_id=data.mos.ru&post_logout_redirect_uri=${this.getCurrentUrl()}`;
  }

  public loginMosRu(): void {
    this.document.location.href = `${
      this._loginLink
    }sps/oauth/ae?client_id=data.mos.ru&response_type=code&redirect_uri=${this.getCurrentUrl()}&scope=openid+profile`;
  }

  public reAuthorization(): void {
    this.document.location.href = `${
      this._loginLink
    }sps/oauth/ae?client_id=data.mos.ru&response_type=code&redirect_uri=${this.getCurrentUrl()}&scope=openid+profile`; // &prompt=none
  }

  public registrationMosRu(): void {
    this.document.location.href = `${
      this._loginLink
    }sps/oauth/ae?client_id=data.mos.ru&response_type=code&redirect_uri=${this.getCurrentUrl()}&scope=openid+profile&bip_action_hint=open_reg`;
  }
}

import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { catchError, switchMap, take, tap } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { UserInfo } from '@auth/models/auth.state';
import { MirSnackbarType, SnackBarMessage } from '@shared/components/snackbar-message/model/snackbar';
import { SnackBarService } from '@shared/components/snackbar-message/services/snackbar.service';
import { HttpErrorResponse } from '@angular/common/http';
import { afterAuthorizationOperationStart, setLocationQueryParams } from '@utils/other-utils';
import { DOCUMENT, isPlatformBrowser, Location } from '@angular/common';
import { ClearUserApiKey } from '@shared/state/shared/shared.action';
import { DateTime } from 'luxon';
import { MatDialog } from '@angular/material/dialog';
import { OkCancelDialogComponent } from '@shared/components/ok-cancel-modal/ok-cancel-dialog/ok-cancel-dialog.component';
import { AuthService } from '../../services/auth.service';
import { Login, LoginLocalStorage, LoginMosRu, Logout, RegistrationMosRu } from './auth.action';
import { DevelopersRoutes } from '../../../developers/models/developers-routes';
import { DataRouting } from '../../../data/modules/catalog-data/models/data-routing';

export type AuthStateModel = {
  user: UserInfo | null;
};

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    user: null
  }
})
@Injectable()
export class AuthState {
  constructor(
    private authService: AuthService,
    private router: Router,
    private location: Location,
    private store: Store,
    private snackBarService: SnackBarService,
    @Inject(PLATFORM_ID) private platformId: string,
    @Inject(DOCUMENT) private document: Document,
    private dialog: MatDialog
  ) {}

  private beforeLogoutAction(): Observable<boolean> {
    if (this.router.url.includes(DataRouting.Appeals)) {
      const okCancelDialog = this.dialog.open(OkCancelDialogComponent, {});

      return okCancelDialog.afterClosed().pipe(take(1));
    }
    return of(true);
  }

  private logoutReload(action: Logout) {
    if (action.logoutSydir) {
      this.authService.logoutSudir();
    } else {
      this.document.location.reload();
    }
  }

  private lastLogautAction(ctx: StateContext<AuthStateModel>, action: Logout): void {
    setLocationQueryParams(
      {
        recommendations: null
      },
      this.router,
      this.location
    );
    ctx.patchState({
      user: null
    });
    localStorage.setItem(
      'auth',
      JSON.stringify({
        user: null
      })
    );
    ctx.dispatch(new ClearUserApiKey());

    if (this.router.url.includes('personal-profile')) {
      this.router.navigate(['/']).then(() => this.logoutReload(action));
      return;
    }

    if (this.router.url.includes(`developers/${DevelopersRoutes.Statics}`)) {
      this.router.navigate([`/developers`]).then(() => this.logoutReload(action));
      return;
    }

    this.logoutReload(action);
    sessionStorage.clear();
  }

  @Selector()
  public static authenticatedUser(state: AuthStateModel): UserInfo | null {
    return state.user;
  }

  @Action(LoginMosRu)
  public loginMosRu(): void {
    this.authService.loginMosRu();
  }

  @Action(RegistrationMosRu)
  public registrationMosRu(): void {
    this.authService.registrationMosRu();
  }

  @Action(LoginLocalStorage)
  public loginLocalStorage(ctx: StateContext<AuthStateModel>, params: LoginLocalStorage): Observable<UserInfo | null> {
    ctx.patchState({
      user: params.userInfo
    });
    return of(params.userInfo);
  }

  @Action(Login)
  public login(ctx: StateContext<AuthStateModel>, action: Login): Observable<UserInfo> {
    sessionStorage.clear();
    return this.authService.login(action.code).pipe(
      tap((result: UserInfo) => {
        if (result.token) {
          ctx.patchState({
            user: result
          });
          this.snackBarService.open(MirSnackbarType.Success, [
            {
              message: 'SNACK_BAR_MESSAGE.AUTHENTICATION_SUCCESSFUL',
              translate: true
            }
          ]);
          if (isPlatformBrowser(this.platformId)) {
            afterAuthorizationOperationStart(this.store, this.router);
          }
          const window = this.document.defaultView;
          if (window) {
            window.navigator.geolocation.getCurrentPosition(res => {
              const state = ctx.getState();
              const token = state.user?.token;
              if (token) {
                this.authService
                  .saveUserGeo(
                    {
                      x: res.coords.latitude,
                      y: res.coords.longitude
                    },
                    DateTime.now().minus({ hours: 4 }).toFormat('yyyy-MM-dd HH:mm:ss').replace(' ', 'T')
                  )
                  .pipe(take(1))
                  .subscribe();
              }
            });
          }
        } else {
          this.snackBarService.open(MirSnackbarType.Error, [
            {
              message: 'SNACK_BAR_MESSAGE.ERROR_PERSONAL_ACCOUNT_CANNOT_PERFORMED',
              translate: true
            }
          ]);
        }
      }),
      catchError((error: HttpErrorResponse) => {
        const message: SnackBarMessage[] = [
          {
            message: 'SNACK_BAR_MESSAGE.ERROR_PERSONAL_ACCOUNT_CANNOT_PERFORMED',
            translate: true
          },
          {
            message: `${error.message}.`,
            translate: false
          },

          {
            message: 'SNACK_BAR_MESSAGE.ERROR_PERSONAL_ACCOUNT_CANNOT_PERFORMED',
            translate: true
          }
        ];
        this.snackBarService.open(MirSnackbarType.Error, message);
        return throwError(() => error);
      })
    );
  }

  @Action(Logout)
  public logout(ctx: StateContext<AuthStateModel>, action: Logout): void {
    this.beforeLogoutAction()
      .pipe(take(1))
      .subscribe(hasContinueAction => {
        const window = this.document.defaultView;
        if (hasContinueAction && window) {
          if (!action.saveGeoData) {
            this.lastLogautAction(ctx, action);
            return;
          }
          window.navigator.geolocation.getCurrentPosition(
            res => {
              this.authService
                .saveUserGeo(
                  {
                    x: res.coords.latitude,
                    y: res.coords.longitude
                  },
                  DateTime.now().minus({ hours: 4 }).toFormat('yyyy-MM-dd HH:mm:ss').replace(' ', 'T')
                )
                .pipe(
                  switchMap(() => this.authService.logout()),
                  take(1),
                  catchError(() => this.authService.logout().pipe(take(1)))
                )
                .subscribe(() => {
                  this.lastLogautAction(ctx, action);
                });
            },
            () => {
              this.authService
                .logout()
                .pipe(take(1))
                .subscribe(() => {
                  this.lastLogautAction(ctx, action);
                });
            }
          );
        }
      });
  }
}

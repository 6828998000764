import { DocumentFilters } from 'app/modules/secondary-pages/documents/models/document';
import { ImpairedSorting } from '../../../version-for-visually-impaired/modules/data/models/data-table';

export class GetDocuments {
  public static readonly type = '[DocumentTable] Get all';
}

export class GetDocumentTypes {
  public static readonly type = '[DocumentTable] Get document types select options';
}

export class SetSearchPhrase {
  public static readonly type = '[DocumentTable] Set search phrase';

  constructor(public searchPhrase: string) {}
}

export class SetSorting {
  public static readonly type = '[DocumentTable] Set sorting documents';

  constructor(public sorting: ImpairedSorting) {}
}

export class SetDocumentFilters {
  public static readonly type = '[DocumentTable] Set document filters';

  constructor(public filters: DocumentFilters) {}
}

export class DropDocumentsFilterState {
  public static readonly type = '[DocumentTable] Drop documents filter state';
}

export class FilterDocuments {
  public static readonly type = '[DocumentTable] Filter documents';
}

import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { SharedCatalogState } from '@shared/state/catalog/shared-catalog.state';
import { CatalogService } from '@shared/services/catalog.service';
import { patch, updateItem } from '@ngxs/store/operators';
import { cloneDeep } from 'lodash';
import { SnackBarService } from '@shared/components/snackbar-message/services/snackbar.service';
import { MirSnackbarType } from '@shared/components/snackbar-message/model/snackbar';
import { DownloadCatalogService } from '../../services/download-catalog.service';
import {
  CreateCatalogsFile,
  GetAllCatalogVersion,
  GetCatalogsExportStatus,
  GetCurrentCatalogVersionRelease,
  GetSelectCatalogItem,
  SelectCatalogItemShared,
  SetCurrentCatalogVersionRelease
} from './catalog.action';
import {
  CatalogItemValue,
  CatalogVersionRelease,
  CatalogVersionReleaseInfo,
  CreateFile,
  FileInfo,
  LoadingCatalogVersionRelease
} from '../../models/catalog';
import { CatalogItemsState } from '../../modules/catalog-items-data/store/catalog-items-state';
import { DownloadFileNames } from '../../modules/catalog-items-data/store/catalog-items.actions';

type CatalogStateModel = {
  selectCatalogItem?: CatalogItemValue | null;
  subscribeCatalogIds: number[];
  catalogsVersionsInfo: CatalogVersionReleaseInfo[];
  currentCatalogVersionRelease: LoadingCatalogVersionRelease | null;
  catalogsFilesInfo: FileInfo[];
};

@State<CatalogStateModel>({
  name: 'catalog',
  defaults: {
    selectCatalogItem: undefined,
    subscribeCatalogIds: [],
    catalogsVersionsInfo: [],
    currentCatalogVersionRelease: null,
    catalogsFilesInfo: []
  }
})
@Injectable()
export class CatalogState {
  constructor(
    private catalogService: CatalogService,
    private store: Store,
    private downloadCatalogService: DownloadCatalogService,
    private snackBar: SnackBarService
  ) {}

  private showSnackBarMessage(): void {
    this.snackBar.open(MirSnackbarType.Error, [
      {
        message: 'SNACK_BAR_MESSAGE.CREATE_CATALOG_ERROR',
        translate: true
      }
    ]);
  }

  @Selector()
  public static selectCatalogItem(state: CatalogStateModel): CatalogItemValue | undefined {
    return state.selectCatalogItem;
  }

  @Selector()
  public static catalogFileInfo(state: CatalogStateModel): (catalogId: number) => FileInfo | undefined {
    return (catalogId: number) => state.catalogsFilesInfo.find(catalog => catalog.id === catalogId);
  }
  @Selector()
  public static currentCatalogVersionRelease(state: CatalogStateModel): LoadingCatalogVersionRelease | null {
    return state.currentCatalogVersionRelease ?? null;
  }

  @Selector()
  public static getCatalogVersionsInfo(state: CatalogStateModel): (catalogId: number) => CatalogVersionRelease[] | undefined {
    return (catalogId: number) => state.catalogsVersionsInfo.find(x => x.catalogId === catalogId)?.versionRelease;
  }

  @Action(SelectCatalogItemShared)
  public setSelectCatalogItem(ctx: StateContext<CatalogStateModel>, action: SelectCatalogItemShared): void {
    ctx.patchState({
      selectCatalogItem: action.catalogItem
    });
  }
  @Action(GetCurrentCatalogVersionRelease)
  public getCurrentCatalogVersionRelease(
    ctx: StateContext<CatalogStateModel>,
    action: GetCurrentCatalogVersionRelease
  ): Observable<CatalogVersionRelease | null> {
    const datasetId = this.store.selectSnapshot(SharedCatalogState.selectCatalog).datasetId;
    const id = action.catalogId ? action.catalogId : datasetId;
    return this.catalogService.getCatalogVersionRelease(id, action.hasIdDataset, action.accessKey, action.version, action.release).pipe(
      tap(res => {
        if (res) {
          ctx.patchState({
            currentCatalogVersionRelease: {
              catalogId: action.hasIdDataset ? this.store.selectSnapshot(SharedCatalogState.selectCatalog).id : action.catalogId,
              versionReleaseInfo: res
            }
          });
        }
      })
    );
  }
  @Action(SetCurrentCatalogVersionRelease)
  public setCurrentCatalogVersionRelease(
    ctx: StateContext<CatalogStateModel>,
    action: SetCurrentCatalogVersionRelease
  ): Observable<CatalogVersionRelease | null> {
    ctx.patchState({
      currentCatalogVersionRelease: {
        catalogId: action.catalogId,
        versionReleaseInfo: action.version
      }
    });

    return of(action.version);
  }

  @Action(GetSelectCatalogItem)
  public getCatalogItemData(ctx: StateContext<CatalogStateModel>, params: GetSelectCatalogItem): Observable<CatalogItemValue | undefined> {
    const state = ctx.getState();
    const selectCatalog = this.store.selectSnapshot(SharedCatalogState.selectCatalog);

    if (
      !params.isDynamic &&
      selectCatalog &&
      (!state.selectCatalogItem || state.selectCatalogItem.global_id !== params.globalId) &&
      !params.isFromMain
    ) {
      const catalogItemValues = this.store.selectSnapshot(CatalogItemsState.getCatalogItemsData).call(this, selectCatalog.id);
      const selectCatalogItem = catalogItemValues?.find(x => x?.global_id === params?.globalId);
      if (selectCatalogItem) {
        ctx.patchState({
          selectCatalogItem: selectCatalogItem
        });
        return of(selectCatalogItem);
      }
    }
    if (!params.isDynamic && state.selectCatalogItem?.global_id === params.globalId) {
      return of(selectCatalog);
    }
    const catVer = this.store.selectSnapshot(CatalogState.currentCatalogVersionRelease);
    let req = this.catalogService.getValueFields({
      criteria: `global_id = ${params.globalId}`,
      id: params.catalogId,
      epoch: catVer?.versionReleaseInfo?.releaseDate,
      timestamp: 1
    });

    if (params.isDynamic) {
      const catalogFields = this.store.selectSnapshot(SharedCatalogState.fields);
      const isPkField = catalogFields?.find(x => x.isPk);

      req = this.catalogService.getDynamicValueFields({
        criteria: `${isPkField ? isPkField.techName : 'id_dynamic'} = '${params.globalId}'`,
        id: params.catalogId
      });
    }

    return req.pipe(
      map(res => {
        const value = res.response[0];
        this.store.dispatch(new DownloadFileNames([value]));
        ctx.patchState({
          selectCatalogItem: value
        });
        return value;
      })
    );
  }

  @Action(GetAllCatalogVersion)
  public getAllCatalogVersion(ctx: StateContext<CatalogStateModel>, action: GetAllCatalogVersion): Observable<CatalogVersionRelease[]> {
    const allVersions = ctx.getState().catalogsVersionsInfo;
    const currentVersion = allVersions.find(catalog => catalog.catalogId === action.catalogId);
    if (currentVersion) {
      return of(currentVersion.versionRelease);
    }
    return this.catalogService.getAllCatalogVersion(action.catalogId, action.hasIdDataset, action.accessKey).pipe(
      tap(res => {
        ctx.patchState({
          catalogsVersionsInfo: [
            ...allVersions,
            {
              catalogId: action.catalogId,
              versionRelease: res
            }
          ]
        });
      })
    );
  }

  @Action(GetCatalogsExportStatus)
  public getCatalogsExportStatus(ctx: StateContext<CatalogStateModel>, action: GetCatalogsExportStatus): Observable<FileInfo[]> {
    const catalogFileInfo = ctx.getState().catalogsFilesInfo;

    let isUpdate = false;
    let paramsCatalogIds: number[] = [];
    if (action.catalogsIds.length === 1 && catalogFileInfo.find(file => file.files.some(f => f.status === 'PROCESSING'))) {
      paramsCatalogIds = action.catalogsIds;
      isUpdate = true;
    } else {
      paramsCatalogIds = action.catalogsIds.filter(id => !catalogFileInfo.some(catalog => catalog.id === id));
    }
    if (paramsCatalogIds.length === 0) {
      paramsCatalogIds = action.catalogsIds;
    }

    if (paramsCatalogIds.length === 0) {
      return of([]);
    }

    return this.downloadCatalogService.statusFiles(paramsCatalogIds, action.versionDate).pipe(
      tap(res => {
        if (Array.isArray(res)) {
          if (isUpdate) {
            const catalogId = paramsCatalogIds[0];
            const newFiles = res.find(c => c.id === catalogId)?.files;
            if (catalogId && newFiles) {
              ctx.setState(
                patch({
                  catalogsFilesInfo: updateItem<FileInfo>(sample => sample?.id === catalogId, {
                    id: paramsCatalogIds[0],
                    files: newFiles.length > 0 ? newFiles : catalogFileInfo.find(x => x.id === paramsCatalogIds[0])?.files ?? []
                  })
                })
              );
            }
          } else {
            ctx.patchState({
              catalogsFilesInfo: [...catalogFileInfo, ...res]
            });
          }
        }
      })
    );
  }

  @Action(CreateCatalogsFile)
  public createCatalogsFile(ctx: StateContext<CatalogStateModel>, action: CreateCatalogsFile): Observable<CreateFile> {
    const catalogFileInfo = ctx.getState().catalogsFilesInfo;

    const oldFiles = catalogFileInfo.find(x => x.id === action.catalogId);
    const newFiles = oldFiles?.files ? cloneDeep(oldFiles?.files) : [];
    const currentFile = newFiles.find(x => x.format === action.format && x.version === action.version);

    if (currentFile && currentFile.status !== 'SUCCESS') {
      currentFile.status = 'PROCESSING';
      currentFile.idFile = 1;
    }

    if (!currentFile) {
      newFiles.push({
        idFile: 1,
        archiveFileSize: 0,
        format: action.format,
        status: 'PROCESSING',
        version: action.version ?? undefined
      });
    }

    return this.downloadCatalogService.createFile(action.catalogId, action.format, action.version).pipe(
      tap(res => {
        if (!(res.status === 200 && !res.message.includes('Ошибка'))) {
          this.showSnackBarMessage();
        } else {
          ctx.setState(
            patch({
              catalogsFilesInfo: updateItem<FileInfo>(sample => sample?.id === action.catalogId, {
                id: action.catalogId,
                files: newFiles
              })
            })
          );
        }
      }),
      catchError(() => {
        this.showSnackBarMessage();
        return EMPTY;
      })
    );
  }
}

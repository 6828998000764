import { Component, ChangeDetectionStrategy } from '@angular/core';
import { CustomIcons } from '@shared/models/custom-icons';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'div[social-share]',
  templateUrl: './social-share.component.html',
  styleUrls: ['./social-share.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SocialShareComponent {
  public icons = CustomIcons;

  public get mosRuUrl(): string {
    return 'https://mos.ru/';
  }

  /*  public get vkUrl(): string {
    return `https://vk.com/share.php?url=${this._url}`;
  }

  public get fbUrl(): string {
    return `https://www.facebook.com/sharer/sharer.php?u=${this._url}`;
  }

  public get twitterUrl(): string {
    return `https://twitter.com/intent/tweet?url=${this._url}`;
  }

  public get instagramUrl(): string {
    return ``;
  }

  public get okUrl(): string {
    return `https://connect.ok.ru/dk?st.cmd=WidgetSharePreview&st.shareUrl=${this._url}`;
  } */
}

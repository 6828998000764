import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FooterComponent } from '@shared/components/footer/footer.component';
import { SvgModule } from '@shared/components/svg/svg.module';
import { RouterModule } from '@angular/router';
import { LayoutModule } from '@layout/layout.module';
import { SocialShareModule } from '@shared/components/social-share/social-share.module';
import { NgLetModule } from '@shared/directives/ng-let/ng-let.module';

@NgModule({
  declarations: [FooterComponent],
  imports: [CommonModule, SvgModule, TranslateModule, RouterModule, LayoutModule, SocialShareModule, NgLetModule],
  exports: [FooterComponent]
})
export class FooterModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OkCancelDialogComponent } from '@shared/components/ok-cancel-modal/ok-cancel-dialog/ok-cancel-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { SvgModule } from '@shared/components/svg/svg.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [OkCancelDialogComponent],
  imports: [CommonModule, TranslateModule, SvgModule, BrowserAnimationsModule],
  exports: [OkCancelDialogComponent]
})
export class OkCancelModalModule {}

export class GetNotificationCatalogs {
  public static readonly type = '[GetNotificationCatalogs] Get NotificationCatalogs';
}

export class AddNotificationCatalog {
  public static readonly type = '[AddNotificationCatalog] Add NotificationCatalog';
  constructor(public catalogid: number) {}
}

export class DeleteNotificationCatalog {
  public static readonly type = '[DeleteNotificationCatalog] Delete NotificationCatalog';
  constructor(public catalogid: number) {}
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { InaccessibilityComponent } from '@shared/components/inaccessibility/inaccessibility.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { ErrorPageMessageModule } from '@shared/components/error-page-message/error-page-message.module';
import { SvgModule } from '@shared/components/svg/svg.module';

@NgModule({
  declarations: [InaccessibilityComponent],
  imports: [CommonModule, MatIconModule, TranslateModule, RouterModule, ErrorPageMessageModule, SvgModule],
  exports: [InaccessibilityComponent]
})
export class InaccessibilityModule {}

import { ChangeDetectionStrategy, Component, Inject, NgZone } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { CustomIcons } from '@shared/models/custom-icons';
import { SnackbarData } from '@shared/components/snackbar-message/model/snackbar';

@Component({
  selector: 'app-snackbar-message',
  templateUrl: './snackbar-message.component.html',
  styleUrls: ['./snackbar-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SnackbarMessageComponent {
  public closeIcon = CustomIcons.Close;

  constructor(
    public snackBarRef: MatSnackBarRef<SnackbarMessageComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: SnackbarData,
    private ngZone: NgZone
  ) {}

  public close(): void {
    this.ngZone.run(() => {
      this.snackBarRef.dismiss();
    });

    /*  this.snackBarRef.dismiss();
    const snackBar = document.getElementsByTagName('snack-bar-container');
    if (snackBar.length > 0) {
      (snackBar[0] as any).style.opacity = 0;
    } */
  }
}

import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ServerLinks } from '@shared/services/server-links';
import { ServerLinkName } from '@shared/models/server-links-model';
import {
  DictionaryValuesResponseArray,
  Categories,
  CatalogItemValueResponseArray,
  RecommendationForCatalogItemsResponse,
  NoticeCountView,
  NoticeCountResponse
} from '@shared/models/shared.service';
import { environment } from '@environments/environment';
import { Stend } from '@shared/models/Stend';
import { UnomAdress, UserApiKeyResponse, UserDataProfilesResponse } from '../../personal-profile/models/user.service';
import { MobileApp, PopularApp, PopularAppsResponse } from '../../main/models/mobile-apps';
import { Providers } from '../../data/modules/catalog-data/models/catalog.service';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private _urlopenData = '';
  private _urlLk = '';
  private _urlEhdapi = '';
  private _fileUrl = '';
  private _catalogId: number;
  private _popularAppCatalogId: number;
  private readonly _httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'ehd-system': 'opod'
  });

  constructor(private http: HttpClient, serverLinks: ServerLinks) {
    this._urlopenData = serverLinks.getLink(ServerLinkName.OpenData);
    this._urlEhdapi = serverLinks.getLink(ServerLinkName.EhdApi);
    this._urlLk = serverLinks.getLink(ServerLinkName.Lk);
    this._fileUrl = serverLinks.getLink(ServerLinkName.File);

    switch (environment.stend) {
      case Stend.Dev:
        this._catalogId = 87406;
        this._popularAppCatalogId = 190094;
        break;
      case Stend.Stage:
        this._catalogId = 6404;
        this._popularAppCatalogId = 6348;
        break;
      case Stend.Prod:
        this._catalogId = 29580;
        this._popularAppCatalogId = 118004;
        break;
      default:
        this._catalogId = 87406;
        this._popularAppCatalogId = 190094;
    }
  }

  private getAppIconSrcOrFallback(icon: string | null): string {
    return icon ? `${this._fileUrl}${icon}` : 'https://www.freepngimg.com/thumb/iphone/80146-blue-apple-text-app-iphone-store.png';
  }

  public getProviders(): Observable<Providers[]> {
    const body = {
      sorting: {
        byNamePD: 'ASC'
      }
    };
    return this.http.post<Providers[]>(`${this._urlopenData}oiv/list`, body);
  }

  public getCategories(): Observable<Categories[]> {
    const body = {
      isDynamic: true,
      publicationStatus: 'Опубликована',
      sorting: {
        byNameOD: 'ASC'
      }
    };
    return this.http.post<Categories[]>(`${this._urlopenData}category/list/`, body);
  }

  public getDictionaryValues(dictionaryId: number): Observable<DictionaryValuesResponseArray> {
    const body = {
      id: dictionaryId
    };
    return this.http.post<CatalogItemValueResponseArray>(`${this._urlEhdapi}dictionary/get`, body, {
      headers: this._httpHeaders
    });
  }

  public getRecommendationForCatalogItems(catalogId: number): Observable<RecommendationForCatalogItemsResponse> {
    return this.http.get<RecommendationForCatalogItemsResponse>(`${this._urlLk}api/v1/recommendation?idCatalogs=${catalogId}`, {
      headers: this._httpHeaders
    });
  }

  public getNoticeCount(): Observable<NoticeCountView> {
    return this.http
      .get<NoticeCountResponse>(`${this._urlLk}api/v1/notices/count`, {
        headers: this._httpHeaders
      })
      .pipe(
        map(res => ({
          all: res.count_1 + res.count_0,
          unread: res.count_0
        }))
      );
  }

  public allNotificationRead(): Observable<never> {
    const body = {
      idsNotices: []
    };
    return this.http.post<never>(`${this._urlLk}api/v1/notices/status`, body, {
      headers: this._httpHeaders
    });
  }

  public getUserApiKey(): Observable<string | null> {
    const url = `${this._urlLk}api/v1/apiKey`;
    return this.http.get<UserApiKeyResponse>(url).pipe(map(res => res?.api_key));
  }

  public createUserApiKey(): Observable<string | null> {
    const url = `${this._urlLk}api/v1/apiKey/?generate=true`;
    return this.http.get<UserApiKeyResponse>(url).pipe(map(res => res?.api_key));
  }

  public getPopularApps(): Observable<MobileApp[]> {
    const body = { id: this._popularAppCatalogId, criteria: `Public_status = 'Опубликовано' and is_deleted=0`, sorting: { Name: 'ASC' } };

    return this.http.post<PopularAppsResponse>(`${this._urlEhdapi}catalog/get/`, body, { headers: this._httpHeaders }).pipe(
      map(res => res.response),
      map(response => this.parsePopularApps(response)),
    );
  }

  private parsePopularApps(popularApps: PopularApp[] = []): MobileApp[] {
    return popularApps.map(app => ({
      description: app.Description,
      id: app.global_id,
      icon: this.getAppIconSrcOrFallback(app.Icon_app),
      iconEn: this.getAppIconSrcOrFallback(app.Icon_app_en),
      name: app.Name,
      nameEn: app.Name_en,
      appStores: app.Platform_data.map((platform, index) => ({
        name: platform.Platform,
        platform: platform.Platform,
        link: platform.Link,
        linkEn: app.Platform_data_en?.[index]?.Link_en ?? platform.Link,
        store: platform.applic_store,
      }))
    }))
  }

  public getUserDataProfiles(): Observable<UserDataProfilesResponse> {
    return this.http.get<UserDataProfilesResponse>(`${this._urlLk}api/v1/dataProfiles`);
  }

  public getStreetName(unomIds: string[]): Observable<UnomAdress> {
    const body = { id: this._catalogId, criteria: `UNOM in [${unomIds.toString()}]`, projection: ['UNOM', 'ADDRESS'] };
    return this.http.post<UnomAdress>(`${this._urlEhdapi}catalog/get/`, body, { headers: this._httpHeaders });
  }
}

export const petsOptions = [
  {
    id: 2,
    name: 'PETS.PIGEONS'
  },
  {
    id: 3,
    name: 'PETS.GEESE'
  },
  {
    id: 4,
    name: 'PETS.DEGU'
  },
  {
    id: 5,
    name: 'PETS.RACCOON'
  },
  {
    id: 6,
    name: 'PETS.CANARY'
  },
  {
    id: 7,
    name: 'PETS.GOATS'
  },
  {
    id: 8,
    name: 'PETS.COWS'
  },
  {
    id: 10,
    name: 'PETS.RABBITS'
  },
  {
    id: 11,
    name: 'PETS.RATS'
  },
  {
    id: 12,
    name: 'PETS.CHICKEN'
  },
  {
    id: 13,
    name: 'PETS.LORI'
  },
  {
    id: 15,
    name: 'PETS.GUINEA_PIGS'
  },
  {
    id: 16,
    name: 'PETS.MOUSE'
  },
  {
    id: 18,
    name: 'PETS.SHEEPS'
  },
  {
    id: 19,
    name: 'PETS.GERBIL'
  },
  {
    id: 20,
    name: 'PETS.PARROT'
  },
  {
    id: 21,
    name: 'PETS.BIRDS'
  },
  {
    id: 22,
    name: 'PETS.REPTILES'
  },
  {
    id: 23,
    name: 'PETS.AQUARIUM_FISH'
  },
  {
    id: 24,
    name: 'PETS.PIGS'
  },
  {
    id: 27,
    name: 'PETS.SNAILS'
  },
  {
    id: 28,
    name: 'PETS.DUCKS'
  },
  {
    id: 29,
    name: 'PETS.HAMSTERS'
  },
  {
    id: 30,
    name: 'PETS.FERRETS'
  },
  {
    id: 31,
    name: 'PETS.CHINCHILLA'
  },
  {
    id: 26,
    name: 'PETS.TIGER'
  },
  {
    id: 1,
    name: 'PETS.FLYING_SQUIRREL'
  },
  {
    id: 17,
    name: 'PETS.ARACHNIDS'
  },
  {
    id: 32,
    name: 'PETS.INSECTS_ENTOMOPHAGES'
  },
  {
    id: 33,
    name: 'PETS.SQUIRREL'
  },
  {
    id: 34,
    name: 'PETS.FRENCH_BULLDOG'
  },
  {
    id: 9,
    name: 'PETS.CATS'
  },
  {
    id: 14,
    name: 'PETS.HORSE'
  },
  {
    id: 25,
    name: 'PETS.DOGS'
  },
  {
    id: 36,
    name: 'PETS.OTHER_ANIMALS'
  },
  {
    id: 35,
    name: 'PETS.ARCTIC_FOX'
  },
  {
    id: 38,
    name: 'PETS.MONKEY'
  },
  {
    id: 39,
    name: 'PETS.AMERICAN_BULLY'
  }
];

import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CustomIcons } from '@shared/models/custom-icons';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { PersonalProfileRoutes } from 'app/modules/personal-profile/models/rours-name';
import { AuthState } from '@auth/store/auth/auth.state';
import { Logout } from '@auth/store/auth/auth.action';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Gender, UserInfo } from '@auth/models/auth.state';
import { SharedState } from '@shared/state/shared/shared.state';
import { tap } from 'rxjs/operators';
import { AllNotificationRead, GetNoticesCount } from '@shared/state/shared/shared.action';
import { NoticeCountView } from '@shared/models/shared.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { TranslateService } from '@ngx-translate/core';
import { Locale } from '@shared/models/locale';
import { LangChangeEvent } from '@ngx-translate/core/lib/translate.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserMenuComponent implements OnInit {
  private _mainPersonalProfileRoute = `/personal-profile/`;
  private _touchendX: number;
  private _touchstartX: number;
  public profileRoute = `${this._mainPersonalProfileRoute}${PersonalProfileRoutes.Profile}`;
  public interestRoute = `${this._mainPersonalProfileRoute}${PersonalProfileRoutes.Interests}`;
  public notificationRoute = `${this._mainPersonalProfileRoute}${PersonalProfileRoutes.Notification}`;
  public isRuLocale = true;
  public langChange$: Observable<LangChangeEvent>;
  public icons = CustomIcons;
  public manGender = Gender.Man;
  @ViewChild('menuTrigger') public menuTrigger: MatMenuTrigger;
  @ViewChild('navBar') public navBar: ElementRef<HTMLElement>;
  @ViewChild('menuButton') public menuButton: ElementRef<HTMLElement>;
  @Select(SharedState.getNoticeCount) public noticeCount$: Observable<NoticeCountView>;
  public user$: Observable<UserInfo | null>;
  public breakPointObserve$ = this.breakpointObserver.observe(['(max-width: 768px)']);
  public isMobileMenuOpen = false;
  public user: UserInfo;
  constructor(private store: Store, private translateService: TranslateService, private breakpointObserver: BreakpointObserver) {}

  private checkDirection(): void {
    if (this._touchendX > this._touchstartX) {
      this.isMobileMenuOpen = false;
    }
  }
  private setLocale(lang: Locale): void {
    if (lang === Locale.RU_RU) {
      this.isRuLocale = true;
    } else {
      this.isRuLocale = false;
    }
  }

  public ngOnInit(): void {
    this.setLocale(this.translateService.currentLang as Locale);
    this.langChange$ = this.translateService.onLangChange.pipe(
      tap(res => {
        this.setLocale(res.lang as Locale);
      })
    );

    this.user$ = this.store.select(AuthState.authenticatedUser).pipe(
      tap((user: UserInfo | null) => {
        if ((!this.user && user) || (user && this.user.token !== user.token && this.user.fio !== user.fio)) {
          this.user = user;
          this.store.dispatch(new GetNoticesCount());
        }
      })
    );
  }

  public logout(): void {
    this.store.dispatch(new Logout(true));
  }

  public checkAllNotification($event: MouseEvent): void {
    if (this.user) {
      this.store.dispatch(new AllNotificationRead());
    }
    $event.stopPropagation();
  }

  public menuClick(): void {
    this.isMobileMenuOpen = !this.isMobileMenuOpen;
    if (this.isMobileMenuOpen) {
      this.store.dispatch(new GetNoticesCount());
    }
  }

  public touchStart($event: TouchEvent): void {
    this._touchstartX = $event.changedTouches[0].screenX;
  }

  public touchEnd($event: TouchEvent): void {
    this._touchendX = $event.changedTouches[0].screenX;
    this.checkDirection();
  }

  public openMenu(): void {
    if (this.menuTrigger) {
      this.menuTrigger.openMenu();
    }
  }
}

import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { patch, removeItem, updateItem } from '@ngxs/store/operators';
import { cloneDeep } from 'lodash';
import { setLocationQueryParams } from '@utils/other-utils';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { CatalogItemValueResponseArray } from '@shared/models/shared.service';
import { CatalogItemsValues, FileData, GeoData, GetValueFieldsParams } from '@shared/models/catalog.service';
import { CatalogService } from '@shared/services/catalog.service';
import { SharedCatalogState } from '@shared/state/catalog/shared-catalog.state';
import { isFatalErrorCode } from '@utils/handle-error-code';
import { SnackBarService } from '@shared/components/snackbar-message/services/snackbar.service';
import { MirSnackbarType } from '@shared/components/snackbar-message/model/snackbar';
import { HttpErrorResponse } from '@angular/common/http';
import { SharedState } from '@shared/state/shared/shared.state';
import { CatalogItemService } from '../services/catalog-item.service';
import {
  ChangeCatalogItem,
  ChangedCatalogItemsFilter,
  ChangedCatalogItemsIsNextToMeData,
  ChangedCatalogItemsIsRecommendation,
  ChangedCatalogItemsPaginator,
  DeleteCatalogItem,
  DownloadFileNames,
  GetCatalogItemsData,
  InitCatalogItemTable,
  SetIsDynamic
} from './catalog-items.actions';
import { CatalogItemValue } from '../../../models/catalog';
import { SelectCatalogItemShared } from '../../../store/catalog/catalog.action';
import { HistoryParams, NextToMeData } from '../models/catalog-item.service';
import { CatalogState } from '../../../store/catalog/catalog.state';

type CatalogItemsStateModel = {
  allCatalogItems: CatalogItemsValues[];
  allCatalogItemsCount: {
    catalogId: number;
    count: number;
  }[];
  allFileNames: FileData[];
  isCatalogItemsGetFatalError: boolean;
};

@State<CatalogItemsStateModel>({
  name: 'catalogItems',
  defaults: {
    allCatalogItems: [],
    allCatalogItemsCount: [],
    allFileNames: [],
    isCatalogItemsGetFatalError: false
  }
})
@Injectable()
export class CatalogItemsState {
  constructor(
    private catalogItemService: CatalogItemService,
    private store: Store,
    private catalogService: CatalogService,
    private router: Router,
    private location: Location,
    private snackBar: SnackBarService
  ) {}

  private static getFoundedCatalogItem(catalogId: number, state: CatalogItemsStateModel): CatalogItemsValues | undefined {
    return state?.allCatalogItems?.find(x => x.catalogId === catalogId);
  }

  private static getFoundedCatalogItemByDatasetId(datasetId: number, state: CatalogItemsStateModel): CatalogItemsValues | undefined {
    return state?.allCatalogItems?.find(x => x.datasetId === datasetId);
  }

  private static newCatalogItemsValues(
    catalogId: number,
    datasetId: number,
    criteria: string,
    isCriteriaChanged: boolean,
    catalogItemsPageSize: number,
    catalogItemsPageIndex: number,
    catalogItems?: CatalogItemValue[],
    selectCatalogItem?: CatalogItemValue,
    isRecommendationData?: boolean,
    nextToMeData?: NextToMeData | undefined,
    epoch?: string | undefined,
    isDynamic?: boolean | undefined
  ): CatalogItemsValues {
    return {
      catalogItems: catalogItems,
      datasetId: datasetId,
      catalogId: catalogId,
      criteria: criteria,
      pageSize: catalogItemsPageSize,
      pageIndex: catalogItemsPageIndex,
      selectCatalogItem: selectCatalogItem,
      criteriaChanged: isCriteriaChanged,
      nextToMeData: nextToMeData,
      isRecommendationData: isRecommendationData,
      epoch: epoch,
      isDynamic: isDynamic
    };
  }

  private newTableData(dataRes: CatalogItemValueResponseArray, currentItemIndex: number, catalogItems: CatalogItemValue[]): void {
    const newData =
      dataRes.response?.map((row, index) => ({
        numberClientOpod: index + 1 + currentItemIndex,
        ...row
      })) ?? [];

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    catalogItems.splice(currentItemIndex, newData.length, ...newData);
  }

  @Selector()
  public static getCatalogItem(state: CatalogItemsStateModel): (catalogId: number) => CatalogItemsValues | undefined {
    return (catalogId: number) => CatalogItemsState.getFoundedCatalogItem(catalogId, state);
  }

  @Selector()
  public static getCatalogItemIsRecommendationData(state: CatalogItemsStateModel): (catalogId: number) => boolean | undefined {
    return (catalogId: number) => CatalogItemsState.getFoundedCatalogItem(catalogId, state)?.isRecommendationData ?? undefined;
  }

  @Selector()
  public static getCatalogItemCount(state: CatalogItemsStateModel): (catalogId: number) => number | null {
    return (catalogId: number) => state.allCatalogItemsCount.find(x => x.catalogId === catalogId)?.count ?? null;
  }

  @Selector()
  public static getCatalogItemIsNextToMeData(state: CatalogItemsStateModel): (catalogId: number) => NextToMeData | undefined {
    return (catalogId: number) => CatalogItemsState.getFoundedCatalogItem(catalogId, state)?.nextToMeData ?? undefined;
  }

  @Selector()
  public static getCatalogItemCriteria(state: CatalogItemsStateModel): (catalogId: number) => string {
    return (catalogId: number) => CatalogItemsState.getFoundedCatalogItem(catalogId, state)?.criteria ?? '';
  }
  @Selector()
  public static getCatalogItemCriteriaByDatasetId(state: CatalogItemsStateModel): (datasetId: number) => string {
    return (datasetId: number) => CatalogItemsState.getFoundedCatalogItemByDatasetId(datasetId, state)?.criteria ?? '';
  }

  @Selector()
  public static getCatalogItemPageSize(state: CatalogItemsStateModel): (catalogId: number) => number {
    return (catalogId: number) => CatalogItemsState.getFoundedCatalogItem(catalogId, state)?.pageSize ?? 10;
  }

  @Selector()
  public static getCatalogItemPageIndex(state: CatalogItemsStateModel): (catalogId: number) => number {
    return (catalogId: number) => CatalogItemsState.getFoundedCatalogItem(catalogId, state)?.pageIndex ?? 0;
  }

  @Selector()
  public static getCatalogItemsData(state: CatalogItemsStateModel): (catalogId: number) => CatalogItemValue[] | undefined {
    return (catalogId: number) => CatalogItemsState.getFoundedCatalogItem(catalogId, state)?.catalogItems;
  }

  @Selector()
  public static getSelectCatalogItem(state: CatalogItemsStateModel): (catalogId: number) => CatalogItemValue | undefined {
    return (catalogId: number) => CatalogItemsState.getFoundedCatalogItem(catalogId, state)?.selectCatalogItem;
  }

  @Selector()
  public static getFatalError(state: CatalogItemsStateModel): boolean {
    return state.isCatalogItemsGetFatalError;
  }

  @Selector()
  public static getFileData(state: CatalogItemsStateModel): FileData[] {
    return state.allFileNames || [];
  }

  @Action(ChangeCatalogItem)
  public changeSelectCatalogItem(ctx: StateContext<CatalogItemsStateModel>, action: ChangeCatalogItem): void {
    const catalogId = this.store.selectSnapshot(SharedCatalogState.selectCatalog)?.id;
    if (action.locationChanged) {
      setLocationQueryParams(
        {
          objectId: action?.catalogItem?.global_id
        },
        this.router,
        this.location
      );
    }
    ctx.setState(
      patch({
        allCatalogItems: updateItem(item => (item as CatalogItemsValues).catalogId === catalogId, patch({ selectCatalogItem: action.catalogItem }))
      })
    );

    this.store.dispatch(new SelectCatalogItemShared(cloneDeep(action.catalogItem)));
  }

  @Action(DeleteCatalogItem)
  public deleteCatalog(ctx: StateContext<CatalogItemsStateModel>, action: DeleteCatalogItem): void {
    ctx.setState(
      patch({
        allCatalogItems: updateItem(
          item => (item as CatalogItemsValues).catalogId === action.catalogId,
          patch({ catalogItems: undefined, selectCatalogItem: undefined, pageIndex: 0 })
        ),
        allCatalogItemsCount: removeItem(item => item.catalogId === action.catalogId)
      })
    );
  }

  @Action(SetIsDynamic)
  public setIsDynamic(ctx: StateContext<CatalogItemsStateModel>, action: SetIsDynamic): void {
    if (action.locationChanged) {
      setLocationQueryParams(
        {
          isDynamic: action.isDynamic
        },
        this.router,
        this.location
      );
    }

    const selectCatalog = this.store.selectSnapshot(SharedCatalogState.selectCatalog);
    const currentState = ctx.getState();
    const foundedCatalogItem = CatalogItemsState.getFoundedCatalogItem(selectCatalog.id, currentState);

    if (!foundedCatalogItem) {
      const newAllCatalogItems = [...currentState.allCatalogItems];
      newAllCatalogItems.push(
        CatalogItemsState.newCatalogItemsValues(
          selectCatalog.id,
          selectCatalog.datasetId,
          '',
          false,
          10,
          0,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          action.isDynamic
        )
      );
      ctx.patchState({
        allCatalogItems: newAllCatalogItems
      });
      return;
    }

    ctx.setState(
      patch({
        allCatalogItems: updateItem(item => (item as CatalogItemsValues).catalogId === selectCatalog.id, patch({ isDynamic: action.isDynamic }))
      })
    );
  }

  @Action(ChangedCatalogItemsPaginator)
  public changedCatalogItemsPaginator(ctx: StateContext<CatalogItemsStateModel>, action: ChangedCatalogItemsPaginator): void {
    if (action.locationChanged) {
      setLocationQueryParams(
        {
          pageSize: action.pageSize,
          pageIndex: action.pageIndex
        },
        this.router,
        this.location
      );
    }
    const selectCatalog = this.store.selectSnapshot(SharedCatalogState.selectCatalog);
    const currentState = ctx.getState();
    const foundedCatalogItem = CatalogItemsState.getFoundedCatalogItem(selectCatalog.id, currentState);
    if (!foundedCatalogItem) {
      const newAllCatalogItems = [...currentState.allCatalogItems];
      newAllCatalogItems.push(
        CatalogItemsState.newCatalogItemsValues(
          selectCatalog.id,
          selectCatalog.datasetId,
          '',
          false,
          action.pageSize,
          action.pageIndex,
          undefined,
          undefined,
          undefined
        )
      );
      ctx.patchState({
        allCatalogItems: newAllCatalogItems
      });
      return;
    }

    ctx.setState(
      patch({
        allCatalogItems: updateItem(
          item => (item as CatalogItemsValues).catalogId === selectCatalog.id,
          patch({ pageIndex: action.pageIndex, pageSize: action.pageSize })
        )
      })
    );
  }

  @Action(ChangedCatalogItemsIsRecommendation)
  public changedCatalogItemsIsRecommendation(ctx: StateContext<CatalogItemsStateModel>, action: ChangedCatalogItemsIsRecommendation): void {
    if (action.locationChanged) {
      setLocationQueryParams(
        {
          isRecommendationData: action.isRecommendationData
        },
        this.router,
        this.location
      );
    }

    const setectCatalog = this.store.selectSnapshot(SharedCatalogState.selectCatalog);
    const catalogId = action.catalogId ?? setectCatalog.id;
    const currentState = ctx.getState();
    const foundedCatalogItem = CatalogItemsState.getFoundedCatalogItem(catalogId, currentState);
    if (!foundedCatalogItem) {
      const newAllCatalogItems = [...currentState.allCatalogItems];
      newAllCatalogItems.push(
        CatalogItemsState.newCatalogItemsValues(
          catalogId,
          setectCatalog?.datasetId,
          '',
          true,
          10,
          0,
          undefined,
          undefined,
          action.isRecommendationData
        )
      );
      ctx.patchState({
        allCatalogItems: newAllCatalogItems
      });
      return;
    }

    ctx.setState(
      patch({
        allCatalogItems: updateItem(
          item => (item as CatalogItemsValues).catalogId === catalogId,
          patch({ isRecommendationData: action.isRecommendationData, criteriaChanged: true })
        )
      })
    );
  }

  @Action(ChangedCatalogItemsIsNextToMeData)
  public changedCatalogItemsIsNextToMeData(ctx: StateContext<CatalogItemsStateModel>, action: ChangedCatalogItemsIsNextToMeData): void {
    const selectCatalog = this.store.selectSnapshot(SharedCatalogState.selectCatalog);

    const catalogId = action.catalogId ?? selectCatalog?.id;
    const currentState = ctx.getState();
    const foundedCatalogItem = CatalogItemsState.getFoundedCatalogItem(catalogId, currentState);
    if (!foundedCatalogItem) {
      const newAllCatalogItems = [...currentState.allCatalogItems];
      newAllCatalogItems.push(
        CatalogItemsState.newCatalogItemsValues(
          catalogId,
          selectCatalog?.datasetId,
          '',
          true,
          10,
          0,
          undefined,
          undefined,
          undefined,
          action.nextToMeData
        )
      );
      ctx.patchState({
        allCatalogItems: newAllCatalogItems
      });
      return;
    }

    ctx.setState(
      patch({
        allCatalogItems: updateItem(
          item => (item as CatalogItemsValues).catalogId === catalogId,
          patch({ nextToMeData: action.nextToMeData, criteriaChanged: true })
        )
      })
    );
  }

  @Action(ChangedCatalogItemsFilter)
  public changedCatalogItemsFilter(ctx: StateContext<CatalogItemsStateModel>, action: ChangedCatalogItemsFilter): void {
    const selectCatalog = this.store.selectSnapshot(SharedCatalogState.selectCatalog);
    if (action.locationChanged) {
      setLocationQueryParams(
        {
          filter: action.criteria
        },
        this.router,
        this.location
      );
    }

    const currentState = ctx.getState();
    const foundedCatalogItem = CatalogItemsState.getFoundedCatalogItem(selectCatalog.id, currentState);
    if (!foundedCatalogItem) {
      const newAllCatalogItems = [...currentState.allCatalogItems];
      newAllCatalogItems.push(
        CatalogItemsState.newCatalogItemsValues(selectCatalog.id, selectCatalog.datasetId, action.criteria, true, 10, 0, undefined, undefined)
      );
      ctx.patchState({
        allCatalogItems: newAllCatalogItems
      });
      return;
    }

    ctx.setState(
      patch({
        allCatalogItems: updateItem(
          item => (item as CatalogItemsValues).catalogId === selectCatalog.id,
          patch({ criteria: action.criteria, criteriaChanged: true })
        )
      })
    );
  }

  @Action(GetCatalogItemsData)
  public getCatalogItemData(ctx: StateContext<CatalogItemsStateModel>, params: GetCatalogItemsData): Observable<CatalogItemValue[]> {
    const currentState = ctx.getState();
    const catalogId = this.store.selectSnapshot(SharedCatalogState.selectCatalog)?.id;
    const currentCatalog = currentState.allCatalogItems.find(x => x.catalogId === catalogId);
    if (!currentCatalog) {
      this.snackBar.open(MirSnackbarType.Error, [
        {
          translate: true,
          message: 'SNACK_BAR_MESSAGE.ERROR_LOADING_DATA'
        }
      ]);
      throw new Error('В кэшэ не найден каталог!');
    }
    const catalogVersionReleaseInfo = this.store.selectSnapshot(CatalogState.currentCatalogVersionRelease);
    let historyParams: HistoryParams | null = null;
    if (catalogVersionReleaseInfo && catalogVersionReleaseInfo.catalogId === catalogId && catalogVersionReleaseInfo.versionReleaseInfo?.releaseDate) {
      historyParams = {
        epoch: catalogVersionReleaseInfo.versionReleaseInfo.releaseDate,
        timestamp: 1
      };
    }

    const currentItemIndex = currentCatalog.pageSize * currentCatalog.pageIndex;
    const allItemLength =
      currentState.allCatalogItemsCount.find(x => x.catalogId === +currentCatalog.catalogId)?.count ??
      currentCatalog.pageSize * (currentCatalog.pageIndex + 1);

    const currentCatalogItems = currentCatalog?.catalogItems;
    const offset = currentCatalog.pageSize * currentCatalog.pageIndex;
    const currentLastCount = currentCatalog.pageSize + offset;
    let lastCount = 0;
    if (currentCatalogItems) {
      lastCount = currentLastCount > currentCatalogItems.length ? currentCatalogItems.length : currentLastCount;
    }
    if (
      params.isDynamic ||
      (catalogId && (!currentCatalogItems || (currentCatalogItems && !currentCatalogItems[lastCount - 1]))) ||
      (currentCatalogItems && currentCatalogItems.filter(x => x).length < allItemLength)
    ) {
      const reqParams: GetValueFieldsParams = {
        id: catalogId,
        offset: offset,
        limit: currentCatalog.pageSize,
        criteria: currentCatalog.criteria
      };

      if (currentCatalog.isRecommendationData) {
        const recommendedItemIds = this.store.selectSnapshot(SharedState.getRecommendationCatalogItemsForUser).call(this, catalogId);

        reqParams.criteria = `${reqParams.criteria === '' ? '' : `${reqParams.criteria} and `}global_id in [${recommendedItemIds.toString()}]`;
      }
      let geoData: GeoData | null = null;
      if (
        currentCatalog.nextToMeData?.isNextMeData &&
        currentCatalog.nextToMeData.longitude &&
        currentCatalog.nextToMeData.latitude &&
        currentCatalog.nextToMeData.radius
      ) {
        reqParams.criteria = `${reqParams.criteria === '' ? '' : `${reqParams.criteria} and `}geoData near(p1, ${
          currentCatalog.nextToMeData.radius
        }.0)`;
        geoData = {
          p1: {
            coordinates: [currentCatalog.nextToMeData.longitude, currentCatalog.nextToMeData.latitude],
            type: 'Point'
          }
        };
      }

      let req = this.catalogService.getValueFields(reqParams, geoData, historyParams, params.sorting);
      if (params.isDynamic) {
        req = this.catalogService.getDynamicValueFields(reqParams, geoData, params.sorting);
      }
      return req.pipe(
        map(req => {
          const newCatalogItems = [...((currentCatalogItems as CatalogItemValue[]) ?? [])];
          this.newTableData(req, currentItemIndex, newCatalogItems);

          ctx.dispatch(new DownloadFileNames(req.response));

          ctx.setState(
            patch({
              allCatalogItems: updateItem(item => (item as CatalogItemsValues).catalogId === catalogId, patch({ catalogItems: newCatalogItems }))
            })
          );

          return req.response;
        })
      );
    }
    return of([]);
  }
  /*  @Action(GetCatalogItemAllCount)
  public getCatalogItemAllCount(ctx: StateContext<CatalogItemsStateModel>, params: GetCatalogItemAllCount): Observable<any> {
    const catalogVersionReleaseInfo = this.store.selectSnapshot(CatalogState.currentCatalogVersionRelease);

    const catalogId = this.store.selectSnapshot(SharedCatalogState.selectCatalog)?.id;

    let historyParams: HistoryParams | null = null;
    if (catalogVersionReleaseInfo && catalogVersionReleaseInfo.catalogId === catalogId && catalogVersionReleaseInfo.versionReleaseInfo?.releaseDate) {
      historyParams = {
        epoch: catalogVersionReleaseInfo.versionReleaseInfo.releaseDate,
        timestamp: 1
      };
    }

    if (ctx.getState().allCatalogItemsCount.find(x => x.catalogId === params.catalogId)) {
      return of([]);
    }

    return this.catalogItemService.getCountCatalogItem(params.catalogId, '', null, historyParams).pipe(
      tap(res => {
        ctx.patchState({
          allCatalogItemsCount: [
            ...ctx.getState().allCatalogItemsCount,
            {
              count: res.response,
              catalogId: params.catalogId
            }
          ]
        });
      })
    );
  } */

  @Action(InitCatalogItemTable)
  public initCatalogItemTable(ctx: StateContext<CatalogItemsStateModel>, params: InitCatalogItemTable): Observable<CatalogItemValueResponseArray | null> {
    ctx.patchState({
      isCatalogItemsGetFatalError: false
    });

    const catalogVersionReleaseInfo = this.store.selectSnapshot(CatalogState.currentCatalogVersionRelease);

    const catalogId = this.store.selectSnapshot(SharedCatalogState.selectCatalog)?.id;
    const datasetId = this.store.selectSnapshot(SharedCatalogState.selectCatalog)?.datasetId;
    const currentState = ctx.getState();

    let historyParamsReleaseDate: HistoryParams | null = null;
    if (catalogVersionReleaseInfo && catalogVersionReleaseInfo.catalogId === catalogId && catalogVersionReleaseInfo.versionReleaseInfo?.releaseDate) {
      historyParamsReleaseDate = {
        epoch: catalogVersionReleaseInfo.versionReleaseInfo.releaseDate,
        timestamp: 1
      };
    }

    const foundedCatalog = currentState.allCatalogItems.find(x => x.catalogId === catalogId) ?? {
      pageIndex: 0,
      pageSize: 10,
      criteria: '',
      catalogId: catalogId,
      catalogItems: undefined,
      selectCatalogItem: undefined,
      criteriaChanged: false,
      isRecommendationData: false,
      nextToMeData: {
        isNextMeData: false
      },
      epoch: ''
    };

    const currentItemIndex = foundedCatalog.pageSize * foundedCatalog.pageIndex;
    if (
      !params.isDynamic &&
      foundedCatalog &&
      !foundedCatalog.criteriaChanged &&
      foundedCatalog.catalogItems &&
      historyParamsReleaseDate?.epoch === foundedCatalog.epoch
    ) {
      return of(null);
    }

    if (catalogId) {
      let criteria = foundedCatalog.criteria;
      if (foundedCatalog.isRecommendationData) {
        const recommendedItemIds = this.store.selectSnapshot(SharedState.getRecommendationCatalogItemsForUser).call(this, catalogId);
        if (recommendedItemIds.length === 0) {
          ctx.setState(
            patch({
              allCatalogItems: updateItem(
                item => (item as CatalogItemsValues).catalogId === catalogId,
                patch({ catalogItems: [], criteriaChanged: false })
              )
            })
          );
          return of(null);
        }
        criteria = `${criteria === '' ? '' : `${criteria} and `}global_id in [${recommendedItemIds.toString()}]`;
      }
      let geoData: GeoData | null = null;
      if (
        foundedCatalog.nextToMeData?.isNextMeData &&
        foundedCatalog.nextToMeData.longitude &&
        foundedCatalog.nextToMeData.latitude &&
        foundedCatalog.nextToMeData.radius
      ) {
        criteria = `${criteria === '' ? '' : `${criteria} and `}geoData near(p1, ${foundedCatalog.nextToMeData.radius}.0)`;
        geoData = {
          p1: {
            coordinates: [foundedCatalog.nextToMeData.longitude, foundedCatalog.nextToMeData.latitude],
            type: 'Point'
          }
        };
      }

      const reqParams: GetValueFieldsParams = {
        id: catalogId,
        offset: currentItemIndex,
        limit: foundedCatalog.pageSize,
        criteria: criteria
      };
      let req = this.catalogService.getValueFields(reqParams, geoData, historyParamsReleaseDate, params.sorting, true);

      if (params.isDynamic) {
        req = this.catalogService.getDynamicValueFields(reqParams, geoData, params.sorting);
      }
      const newAllCatalogItems = [...currentState.allCatalogItems];
      return req.pipe(
        tap((dataRes: CatalogItemValueResponseArray) => {
          if (dataRes.status === 999) {
            ctx.patchState({ isCatalogItemsGetFatalError: true });
            this.snackBar.open(MirSnackbarType.Error, [
              {
                message: 'SNACK_BAR_MESSAGE.ERROR_LOADING_DATA',
                translate: true
              }
            ]);
            if (currentState.allCatalogItems.find(x => x.catalogId === catalogId)) {
              ctx.setState(
                patch({
                  allCatalogItems: updateItem(
                    item => (item as CatalogItemsValues).catalogId === catalogId,
                    patch({ catalogItems: [], criteriaChanged: false })
                  )
                })
              );
            } else {
              newAllCatalogItems.push(CatalogItemsState.newCatalogItemsValues(catalogId, datasetId, '', false, 10, 0, [], undefined));
              ctx.patchState({
                allCatalogItems: newAllCatalogItems
              });
            }
          }

          ctx.dispatch(new DownloadFileNames(dataRes.response));

          let catalogItemsCount =
            dataRes.found ?? this.store.selectSnapshot(CatalogState.currentCatalogVersionRelease)?.versionReleaseInfo?.countRows ?? 0;

          if (params.isDynamic) {
            catalogItemsCount = dataRes.found as number;
            const stateAllCount = ctx.getState().allCatalogItemsCount;
            if (stateAllCount.find(x => x.catalogId === catalogId)) {
              ctx.setState(
                patch({
                  allCatalogItemsCount: updateItem(item => item.catalogId === catalogId, patch({ count: dataRes.total }))
                })
              );
            } else {
              ctx.patchState({
                allCatalogItemsCount: [
                  ...ctx.getState().allCatalogItemsCount,
                  {
                    count: dataRes.total!,
                    catalogId: catalogId
                  }
                ]
              });
            }
          }

          const newCatalogItems = new Array(catalogItemsCount).fill(undefined);
          this.newTableData(dataRes, currentItemIndex, newCatalogItems);

          if (currentState.allCatalogItems.find(x => x.catalogId === catalogId)) {
            ctx.setState(
              patch({
                allCatalogItems: updateItem(
                  item => (item as CatalogItemsValues).catalogId === catalogId,
                  patch({ catalogItems: newCatalogItems, criteriaChanged: false, epoch: historyParamsReleaseDate?.epoch })
                )
              })
            );
          } else {
            newAllCatalogItems.push(
              CatalogItemsState.newCatalogItemsValues(
                catalogId,
                datasetId,
                '',
                false,
                10,
                0,
                newCatalogItems,
                undefined,
                undefined,
                undefined,
                historyParamsReleaseDate?.epoch
              )
            );
            ctx.patchState({
              allCatalogItems: newAllCatalogItems
            });
          }
        }),
        catchError((error: HttpErrorResponse) => {
          const isFatalError = isFatalErrorCode(error);
          if (isFatalError) {
            ctx.patchState({ isCatalogItemsGetFatalError: isFatalError });
            this.snackBar.open(MirSnackbarType.Error, [
              {
                message: 'SNACK_BAR_MESSAGE.ERROR_LOADING_DATA',
                translate: true
              }
            ]);
          } else {
            this.snackBar.open(MirSnackbarType.Error, [
              {
                message: 'SNACK_BAR_MESSAGE.ERROR_LOADING_DATA_LIST',
                translate: true
              }
            ]);
          }

          if (currentState.allCatalogItems.find(x => x.catalogId === catalogId)) {
            ctx.setState(
              patch({
                allCatalogItems: updateItem(
                  item => (item as CatalogItemsValues).catalogId === catalogId,
                  patch({ catalogItems: [], criteriaChanged: false })
                )
              })
            );
          } else {
            newAllCatalogItems.push(CatalogItemsState.newCatalogItemsValues(catalogId, datasetId, '', false, 10, 0, [], undefined));
            ctx.patchState({
              allCatalogItems: newAllCatalogItems
            });
          }

          return of(null);
        })
      );
    }
    this.snackBar.open(MirSnackbarType.Error, [
      {
        message: 'SNACK_BAR_MESSAGE.ERROR_LOADING_DATA',
        translate: true
      }
    ]);
    throw new Error('В store отсутствует выбранный каталог!');
  }

  @Action(DownloadFileNames)
  public downloadFileNames(ctx: StateContext<CatalogItemsStateModel>, action: DownloadFileNames): Observable<FileData[]> {
    if (!action.catalogItems?.length) { return EMPTY; }

    const oldFileData = this.store.selectSnapshot(CatalogItemsState.getFileData) || [];

    const dataFileIds = action.catalogItems.reduce(
      (ids: string[], catalogItem: CatalogItemValue) => {
        const catalogItemFileNames = this.collectFileNames(catalogItem);
        return [...new Set([...catalogItemFileNames, ...ids])];
      },
      [],
    );

    if (dataFileIds.length > 0) {
      return this.catalogService.getDownloadFileNames(dataFileIds.toString())
        .pipe(
          tap((data) => {
            ctx.setState(
              patch({
                allFileNames: [...oldFileData, ...data],
              })
            );
          }),
        );
    }

    return EMPTY;
  }

  private collectFileNames(catalogItem: CatalogItemValue, array: string[] = []): string[] {
    const fileIdRegExp = /\w{8}-\w{4}-\w{4}-\w{4}-\w{12}/;
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const key in catalogItem) {
      if (typeof catalogItem[key] === 'string' && fileIdRegExp.test(catalogItem[key])) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        array.push(catalogItem[key]);
      }
      if (typeof catalogItem[key] === 'object') {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        this.collectFileNames(catalogItem[key], array);
      }
    }

    return array;
  }
}

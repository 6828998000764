import { CatalogView } from '@shared/models/shared-catalog.state';
import { GeoDataClusterParams } from '@shared/models/geodata.service';
import { NextToMeData } from '../../../data/modules/catalog-items-data/models/catalog-item.service';

export class GetCatalogInfo {
  public static readonly type = '[GetCatalog] Get catalog info';

  constructor(public catalogId: number, public isDynamicData = false) {}
}

export class GetSelectCatalog {
  public static readonly type = '[Catalog] Get Select';

  constructor(public id: number) {}
}

export class SetSelectCatalog {
  public static readonly type = '[Catalog] Set Select';

  constructor(public catalog: CatalogView | { id: number; datasetId: number }) {}
}

export class ChangedSelectCatalog {
  public static readonly type = '[ChangedCatalog] Select';

  constructor(public selectCatalog: CatalogView) {}
}

export class GetCatalogGeoData {
  public static readonly type = '[GetCatalogGeoData] Get';

  constructor(
    public nextToMeData: NextToMeData,
    public isRecommendationData: boolean,
    public criteria: string,
    public catalogId: number | null = null,
    public datasetId: number | null = null
  ) {}
}

export class SaveCatalogToStorage {
  public static readonly type = '[GetCatalog] Save to session storage';
}

export class RefreshCurrentCatalogStats {
  public static readonly type = '[GetCatalog] Refresh catalog rating';
}

export class GetGeoDataDistrict {
  public static readonly type = '[GetGeoDataDistrict] Get geoData district';
}

export class GetGeoDataArea {
  public static readonly type = '[GetGeoDataArea] Get geoData area';
}

export class GetGeoDataCluster {
  public static readonly type = '[GetGeoDataCluster] Get geoData cluster';
  constructor(public params: GeoDataClusterParams) {}
}

export class CancelGetGeoDataCluster {
  public static readonly type = '[CancelGetGeoDataCluster] Get geoData cluster';
}

import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { CustomIcons } from '@shared/models/custom-icons';

@Component({
  selector: 'app-hoverable-svg',
  templateUrl: './hoverable-svg.component.html',
  styleUrls: ['./hoverable-svg.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HoverableSvgComponent {
  @Input() public icon: CustomIcons | undefined;
}

<ng-container *ngIf="(breakPointObserve$ | async)?.matches; else userMenuTemplate">
  <ng-container *ngTemplateOutlet="buttonTemplate; context: { mobile: true }"></ng-container>
  <div class="navbar" [class.open]="isMobileMenuOpen" (touchstart)="touchStart($event)" (touchend)="touchEnd($event)">
    <ng-container *ngTemplateOutlet="menuTemplate"></ng-container>
  </div>
  <div class="closer-backdrop" *ngIf="isMobileMenuOpen" (click)="isMobileMenuOpen = false"></div>
</ng-container>

<ng-template #userMenuTemplate>
  <ng-container>
    <div #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="userMenu">
      <ng-container *ngTemplateOutlet="buttonTemplate; context: { mobile: false }"></ng-container>
    </div>
  </ng-container>
  <mat-menu #userMenu="matMenu" xPosition="before" class="menu-item">
    <ng-container *ngTemplateOutlet="menuTemplate"></ng-container>
  </mat-menu>
</ng-template>

<ng-template #buttonTemplate let-mobile="mobile">
  <button #menuButton class="btn-empty center" (click)="menuClick()">
    <svg [icon]="icons.AccountAvatar" class="svg24 white-color" />
  </button>
</ng-template>

<ng-template #menuTemplate>
  <ng-container *ngLet="langChange$ | async">
    <div *ngLet="user$ | async" class="header-menu" mat-menu-item disabled>
      <svg [icon]="user.gender ? (user.gender === manGender ? icons.AvatarMan : icons.AvatarWoman) : icons.UnknownUser" class="svg40 mr16" />
      <span>{{ user.fio }}</span>
    </div>
    <a [routerLink]="profileRoute" (click)="isMobileMenuOpen = false" mat-menu-item class="menu-item">
      <svg [icon]="icons.AccountAvatar" class="svg24 dark-gray-color mr8" />
      <span>{{ 'PERSONAL_PROFILE.PROFILE' | translate }}</span>
    </a>
    <a [routerLink]="interestRoute" (click)="isMobileMenuOpen = false" mat-menu-item class="menu-item">
      <svg [icon]="icons.Interests" class="svg24 dark-gray-color mr8" />
      <span>{{ 'PERSONAL_PROFILE.INTERESTS' | translate }}</span>
    </a>
    <div mat-menu-item class="df" *ngIf="isRuLocale">
      <a [routerLink]="notificationRoute" (click)="isMobileMenuOpen = false" class="notification-link">
        <svg [icon]="icons.Notifications" class="svg24 dark-gray-color mr8" />
        <span> {{ 'PERSONAL_PROFILE.NOTIFICATIONS' | translate }} </span>
      </a>
      <button [routerLink]="notificationRoute" class="btn-empty notification-link">
        <app-hoverable-svg
          matTooltip="{{ 'PERSONAL_PROFILE.NOTIFICATION.ALL_READ' | translate }}"
          [icon]="icons.DoneAll"
          (click)="checkAllNotification($event)"
          class="svg24 dark-gray-color"
        ></app-hoverable-svg>
        <span class="notifications" *ngIf="noticeCount$ | async as noticeCount">
          <span style="color: red">{{ noticeCount.unread }}</span
          >/{{ noticeCount.all }}
        </span>
      </button>
    </div>
    <a (click)="logout()" mat-menu-item class="menu-item">
      <svg [icon]="icons.Logout" class="svg24 dark-gray-color mr8" />
      <span>{{ 'OTHER.EXIT' | translate }}</span>
    </a>
  </ng-container>
</ng-template>

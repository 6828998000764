import { CustomIcons } from '@shared/models/custom-icons';
import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from "@angular/material/snack-bar";

export enum MirSnackbarType {
  Info = 'info',
  Error = 'error',
  Notification = 'notification',
  Warning = 'warning',
  Success = 'success'
}

export type SnackBarMessage = {
  translate: boolean;
  message: string;
};

export type SnackbarData = {
  message: string;
  duration?: number;
  color: 'var(--negative-color)' | 'var(--neutral-color)' | 'var(--positive-color)' | 'var(--primary-60-color)' | 'var(--secondary-60-color)';
  icon: CustomIcons;
  horizontalPosition?: MatSnackBarHorizontalPosition;
  verticalPosition?: MatSnackBarVerticalPosition;
};

export type SnackbarVariation = {
  [key in MirSnackbarType]: SnackbarData;
};

import { SortingType } from 'app/modules/data/modules/catalog-data/components/ordering/models';
import { CatalogView } from '@shared/models/shared-catalog.state';
import { CatalogFilter, HandleCatalogHeaderToggleModel } from '../models/catalog.service';

export class ChangeCatalogFilter {
  public static readonly type = '[CatalogTable] Change catalogs filter';

  constructor(public filter: CatalogFilter, public isCatalogsDispatch: boolean, public overwriteFilter = false) {
    // empty
  }
}

export class ChangeSortingType {
  public static readonly type = '[CatalogTable] Change sorting type';

  constructor(public sortingType: SortingType, public isCatalogsDispatch = true) {
    // empty
  }
}

export class GetExtraCatalog {
  public static readonly type = '[CatalogTable] Get extra catalog categories';

  constructor() {
    // empty
  }
}

export class GetCatalogs {
  public static readonly type = '[CatalogTable] Get all';
}

export class ClearCatalogs {
  public static readonly type = '[ClearCatalogs] Clear all';
}

export class GetCatalogsCanceled {
  public static readonly type = '[GetCatalogsCanceled] Get all';
}

export class UpdateCatalog {
  public static readonly type = '[UpdateCatalog] Update catalog';

  constructor(public currentCatalog: CatalogView) {}
}

export class GetRecommendedCatalogs {
  public static readonly type = '[CatalogTable] Get recommended catalogs';
}

export class HandleCatalogHeaderToggle {
  public static readonly type = '[CatalogTable] handle Catalog header toggle';

  constructor(public type: HandleCatalogHeaderToggleModel, public isCatalogDispatch = true) {}
}

import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { EMPTY, Observable, throwError, timeout, TimeoutError } from 'rxjs';
import { Store } from '@ngxs/store';
import { AuthState } from '@auth/store/auth/auth.state';
import { catchError, finalize } from 'rxjs/operators';
import { ReAuthorizationDialogComponent } from '@auth/components/re-authorization-dialog/re-authorization-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private _dialogRef: MatDialogRef<any> | undefined;
  constructor(private store: Store, private dialog: MatDialog) {}

  private timeOutError(er: HttpErrorResponse): Observable<never> {
    const errorMessage = 'Время ожидания запроса превысило 180 секунд!';
    if (er instanceof TimeoutError) {
      return throwError(() => ({
        message: errorMessage,
        status: 504
      }));
    }
    return throwError(() => er);
  }

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const user = this.store.selectSnapshot(AuthState.authenticatedUser);
    if (
      user?.token &&
      ((request.url.includes('/lk/api/v1/') && !request.url.includes('/lk/api/v1/auth/authUserSUDIR')) || request.url.includes('userRatingIn'))
    ) {
      const authHeader = `Bearer ${user.token}`;

      const authReq = request.clone({
        headers: request.headers.set('OpodAuth', authHeader)
      });
      return next.handle(authReq).pipe(
        timeout(180000),
        catchError((er: HttpErrorResponse) => this.timeOutError(er)),
        catchError(err => {
          if (err.status === 401 && !this._dialogRef) {
            this._dialogRef = this.dialog.open(ReAuthorizationDialogComponent, { disableClose: true });

            this._dialogRef
              .afterClosed()
              .pipe(finalize(() => (this._dialogRef = undefined)))
              .subscribe();
          }

          if (err.status === 401) {
            return EMPTY;
          }

          const error = {
            ...err,
            message: err.message,
            status: err.status
          };
          return throwError(() => error);
        })
      );
    }

    if (request.url.includes('history')) {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      timeout(180000),
      catchError((er: HttpErrorResponse) => this.timeOutError(er)),
      catchError(err => {
        const error = {
          ...err,
          message: err.message,
          status: err.status
        };
        return throwError(() => error);
      })
    );
  }
}

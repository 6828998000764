import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SvgModule } from '@shared/components/svg/svg.module';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { UserMenuComponent } from '@layout/components/user-menu/user-menu.component';
import { LocaleSwitcherComponent } from '@layout/components/locale-switcher/locale-switcher.component';
import { NgLetModule } from '@shared/directives/ng-let/ng-let.module';
import { SocialShareModule } from '@shared/components/social-share/social-share.module';
import { LoaderModule } from '@shared/components/loader/loader.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { HoverableSvgModule } from '@shared/components/hoverable-svg/hoverable-svg.module';
import { LayoutComponent } from './components/main/layout.component';
import { MobileMenuComponent } from './components/mobile-menu/mobile-menu.component';

@NgModule({
  declarations: [LayoutComponent, UserMenuComponent, LocaleSwitcherComponent, MobileMenuComponent],
  imports: [
    CommonModule,
    SvgModule,
    MatMenuModule,
    TranslateModule,
    RouterModule,
    MatToolbarModule,
    MatButtonModule,
    MatDialogModule,
    NgLetModule,
    SocialShareModule,
    LoaderModule,
    MatTooltipModule,
    HoverableSvgModule
  ],
  exports: [LayoutComponent, MobileMenuComponent]
})
export class LayoutModule {}

import { Params, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AfterAuthorizationOperation } from '@shared/models/catalog.service';
import { Store } from '@ngxs/store';
import { AuthState } from '@auth/store/auth/auth.state';
import { CreateUserApiKey, GetRecommendationCatalogItemsForUser, GetUserApiKey } from '@shared/state/shared/shared.action';
import { take } from 'rxjs/operators';
import { SharedState } from '@shared/state/shared/shared.state';
import { AbstractControl } from '@angular/forms';
import { SharedCatalogState } from '@shared/state/catalog/shared-catalog.state';
import { AddFavoriteCatalog } from '../../data/store/favorite-catalogs/favorite-catalogs.action';
import { CatalogItemsQueryParams } from '../../data/modules/catalog-items-data/models/catalog-item-component';
import { AddNotificationCatalog } from '../../data/store/catalog-change-notifications/catalog-change-notifications.action';
import { ChangedCatalogItemsIsRecommendation } from '../../data/modules/catalog-items-data/store/catalog-items.actions';
import { HandleCatalogHeaderToggle } from '../../data/modules/catalog-data/store/catalog-table.actions';
import { HandleCatalogHeaderToggleModel } from '../../data/modules/catalog-data/models/catalog.service';
import { CatalogState } from '../../data/store/catalog/catalog.state';

export function isMobile(): boolean {
  return /iphone|ipod|android|ie|blackberry|fennec/.test(navigator.userAgent.toLowerCase());
}
/* eslint-enable */
export function getAllChildTagsForTd(topTags: string[], element: HTMLElement, counter = 1): string[] {
  if (counter > 8) {
    return [];
  }
  const result = [];
  if (topTags.some(topTags => topTags === element?.tagName || element?.tagName === 'TD')) {
    return [element.tagName];
  }
  result.push(element?.tagName);
  result.push(...getAllChildTagsForTd(topTags, element.parentElement as HTMLElement, counter + 1));
  return result;
}

export function convertObjectToQueryString(object: CatalogItemsQueryParams): string {
  const params = new URLSearchParams();
  Object.entries(object).forEach(([key, value]) => {
    const stringValue = value?.toString();
    if (stringValue) {
      params.set(key, stringValue);
    }
  });

  return params.toString();
}

function createObjectWithParams<T>(queryParams: Params, object: T): T {
  Object.entries(queryParams).forEach(([key, value]) => {
    if (!Object.prototype.hasOwnProperty.call(object, key) && value) {
      object[key] = value;
    }
  });
  return object;
}

export function setLocationQueryParams<T extends object>(params: T, router: Router, location: Location): void {
  const queryParams = router.parseUrl(location.path()).queryParams as T;
  const newQueryParams = createObjectWithParams(queryParams, params);
  const stringQueryParams = convertObjectToQueryString(newQueryParams);
  location.replaceState(router.url.split('?')[0], stringQueryParams);
}
``
export function parseQueryString(str: string): any {
  const objURL = {};

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  str.replace(/([^?=&]+)(=([^&]*))?/g, function ($0, $1, $2, $3) {
    objURL[$1] = $3;
  });
  return objURL;
}

export function parseQueryStringByDocument(document: Document): any {
  parseQueryString(document.location.search);
}

export function afterAuthorizationOperationStart(store: Store, router: Router): void {
  const operationNames: AfterAuthorizationOperation[] = [
    'AddNotification',
    'AddFavoriteItem',
    'GetRecommendation',
    'NewRecommendedFavoriteToggleState',
    'GetUserApiKey',
    'InformationPlacement'
  ];

  operationNames.forEach(operation => {
    const params = localStorage.getItem(operation);
    if (params) {
      const user = store.selectSnapshot(AuthState.authenticatedUser);
      // eslint-disable-next-line default-case
      switch (operation) {
        case 'InformationPlacement': {
          if (user) {
            router.navigate([`information-placement`]);
          }
          break;
        }
        case 'AddFavoriteItem': {
          const catalogId = +JSON.parse(params);

          if (user) {
            store.dispatch(new AddFavoriteCatalog(catalogId));
          }
          break;
        }
        case 'AddNotification': {
          const catalogId = +JSON.parse(params);

          if (user) {
            store.dispatch(new AddNotificationCatalog(catalogId));
          }
          break;
        }
        case 'NewRecommendedFavoriteToggleState': {
          const toggleState = `${JSON.parse(params).type}` as HandleCatalogHeaderToggleModel;
          store.dispatch(new HandleCatalogHeaderToggle(toggleState));
          break;
        }

        case 'GetRecommendation': {
          const catalogId = +JSON.parse(params);

          if (user && catalogId) {
            store.dispatch([new GetRecommendationCatalogItemsForUser(catalogId), new ChangedCatalogItemsIsRecommendation(true, catalogId)]);
          }
          break;
        }

        case 'GetUserApiKey': {
          if (user) {
            store
              .dispatch(new GetUserApiKey())
              .pipe(take(1))
              .subscribe(() => {
                const apiKey = store.selectSnapshot(SharedState.userDataApiKey);
                if (!apiKey) {
                  store.dispatch(new CreateUserApiKey());
                }
                if (params !== 'undefined') {
                  const route = JSON.parse(params).route;
                  router.navigate([route]);
                }
              });
          }
          break;
        }
      }
      localStorage.removeItem(operation);
    }
  });
}

export const hasRequiredField = (abstractControl: AbstractControl): boolean => {
  if (abstractControl.validator) {
    const validator = abstractControl.validator({} as AbstractControl);
    if (validator && validator.required) {
      return true;
    }
  }
  // eslint-disable-next-line
  if (abstractControl['controls']) {
    // eslint-disable-next-line
    for (const controlName in abstractControl['controls']) {
      // eslint-disable-next-line
      if (abstractControl['controls'][controlName]) {
        // eslint-disable-next-line
        if (hasRequiredField(abstractControl['controls'][controlName])) {
          return true;
        }
      }
    }
  }
  return false;
};

export const getLinkToObject = (store: Store, document: Document, isMap = false): string => {
  const selectCatalog = store.selectSnapshot(SharedCatalogState.selectCatalog);
  const globalId = store.selectSnapshot(CatalogState.selectCatalogItem).global_id;
  const params = parseQueryStringByDocument(document);
  let objectLink = `https://${document.location.host}/opendata/${selectCatalog.datasetId}`;

  if (isMap) {
    objectLink += '/map';
  }

  objectLink += `?objectId=${globalId}&isDynamic=${selectCatalog.isDynamic}`;
  if (params?.version) {
    objectLink += `&version=${params.version}`;
  }

  if (params?.release) {
    objectLink += `&release=${params.release}`;
  }

  if (!selectCatalog?.isDynamic) {
    objectLink += `&filter=global_id+%3D+${globalId}`;
  } else {
    const catalogInfo = store.selectSnapshot(SharedCatalogState.selectCatalogInfo);
    if (catalogInfo) {
      const pkFields = catalogInfo.fields.find(x => x.isPk);
      if (pkFields) {
        objectLink += `&filter=${pkFields.techName}+%3D+${globalId}`;
      }
    }
  }
  return objectLink;
};

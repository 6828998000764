<div class="spacer" *ngIf="showLayout"></div>
<mat-toolbar *ngIf="showLayout">
  <div *ngLet="breakPointObserve$ | async" class="mobile-menu-container">
    <button class="mobile-menu btn-empty center mr24">
      <svg [icon]="icons.MobileMenu" (click)="mobileMenu.menuClick()" class="svg24 white-color" />
      <app-mobile-menu #mobileMenu></app-mobile-menu>
    </button>
    <a class="logo-container td-n" [routerLink]="'/'">
      <svg [icon]="icons.Logo" class="svg32 mr20 logo-icon" />
      <div>
        <h2 class="layout-title h4 white-color">{{ 'LAYOUT.PORTALNAME' | translate }}</h2>
        <p class="layout-subtitle">{{ 'LAYOUT.GOVERMENT' | translate }}</p>
      </div>
    </a>
  </div>
  <div class="layout-menu">
    <a class="menu-item" [class.active]="isActiveDataUrl" [routerLink]="dataRoute" mat-button>{{ 'LAYOUT.DATA' | translate }}</a>
    <a class="menu-item" [class.active]="isActiveDevelopersUrl" [routerLink]="developersRoute" mat-button>
      {{ 'LAYOUT.DEVELOPERS' | translate }}
    </a>
  </div>

  <div class="button-container" *ngLet="!(user$ | async) as isLogin">
    <div class="btn-pc">
    <ng-container *ngTemplateOutlet="!isLogin ? userMenu : exitButton"></ng-container>
    </div>

    <div class="btn-logout-icon">
      <button class="btn-logout-icon btn-empty center" *ngIf="isLogin; else userMenu" (click)="openAuthorizationDialog()">
        <svg [icon]="icons.Login" class="svg24 white-color" />
      </button>
    </div>
    <ng-template #userMenu>
      <a class="btn-logout menu-item" mat-button (click)="userMenuComponent.openMenu()">
        <app-user-menu #userMenuComponent></app-user-menu>
      </a>
    </ng-template>

    <ng-template #exitButton>
      <div class="btn-logout" (click)="openAuthorizationDialog()">
        <button class="btn-secondary small btn-primary40 login-btn"  [disabled]="loginInProgress$ | async">
          <app-loader class="make-smaller" *ngIf="loginInProgress$ | async; else loginText"></app-loader>
          <ng-template #loginText>
            <span>{{ 'LAYOUT.ENTRY' | translate }}</span>
          </ng-template>
        </button>
      </div>
    </ng-template>

    <a class="menu-item menu-item-hidden-mobile" (click)="localeSwitcher.changeLanguage($event)" mat-button
      ><app-locale-switcher #localeSwitcher></app-locale-switcher
    ></a>

    <a class="menu-item menu-item-hidden-mobile" [routerLink]="visuallyImpaired" mat-button
      ><button  class="eye-btn btn-empty center">
        <svg [icon]="icons.Eye" class="eye svg24 white-color" /></button
    ></a>
  </div>
</mat-toolbar>

import { UrlSerializer, UrlTree, DefaultUrlSerializer } from '@angular/router';

export class CustomUrlSerializer implements UrlSerializer {
  private _dus = new DefaultUrlSerializer();

  public parse(url: string): UrlTree {
    const newUrl = url.replace(/\(/g, '%28').replace(/\)/g, '%29');
    return this._dus.parse(newUrl);
  }

  public serialize(tree: UrlTree): string {
    return this._dus.serialize(tree).replace(/%28/g, '(').replace(/%29/g, ')');
  }
}

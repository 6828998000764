import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { of, switchMap } from 'rxjs';
import { FilesRouteService } from 'app/modules/secondary-pages/files/files-route.service';
import { CatalogService } from '@shared/services/catalog.service';
import { map } from 'rxjs/operators';
import { DateTime } from 'luxon';

@Component({
  template: '<pre *ngIf="json$ | async as res">{{ res | json }}</pre>',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilesComponent {
  public json$ = this.route.params.pipe(
    switchMap((params: Params) => {
      const catalogId = params.catalogId as number;
      const catalogName = params.catalogName as string;

      let releaseDate = '';
      const isDynamic = params.filename.includes('dynamicdata');
      if (!isDynamic) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        releaseDate = DateTime.fromFormat(params.filename.split('-')[1].split('.')[0].split('(')[0], 'yyyyMMddТHHmm')
          .plus({ minute: 1 })
          .toFormat('yyyy-MM-dd HH:mm');
      }

      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      return this.filesRouteService.getCatalogJson(catalogId, catalogName, releaseDate, params.filename).pipe(
        switchMap(catalogJSON => {
          if (catalogJSON) return of(catalogJSON);

          let req = this.catalogService.getCatalogInfo(params.catalogId as number, releaseDate ? { epoch: releaseDate } : {});
          if (isDynamic) {
            req = this.catalogService.getDynamicCatalogInfo(params.catalogId as number, releaseDate ? { epoch: releaseDate } : {});
          }

          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          return req.pipe(map(response => this.filesRouteService.formatJsonResponse(catalogId, catalogName, response.response[0], params.filename)));
        })
      );
    })
  );

  constructor(private route: ActivatedRoute, private filesRouteService: FilesRouteService, private catalogService: CatalogService) {}
}

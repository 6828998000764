import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { DOCUMENT, isPlatformServer } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class SvgPrefetchService {
  private _pathIcons = '/assets/svg/shared.svg';
  public svg: string;

  constructor(private http: HttpClient, @Inject(PLATFORM_ID) platformId: string, @Inject(DOCUMENT) document: Document) {
    if (isPlatformServer(platformId)) {
      this._pathIcons = `${document.location.origin}${this._pathIcons}`;
    }
  }

  public prefetchSharedSvg(): Observable<string> {
    return this.http.get(this._pathIcons, { responseType: 'text' }).pipe(take(1));
  }
}

export class GetPopularDatasets {
  public static readonly type = '[MainPage] Get popular datasets';
}

export class GetSeasonDatasets {
  public static readonly type = '[MainPage] Get season datasets';
}

export class GetCatalogCountAndTime {
  public static readonly type = '[MainPage] Get catalog count';
}

export class SetChosenCatalogOnMap {
  public static readonly type = '[MainPage] Set chosen catalog on map';

  constructor(public catalogId: number) {}
}

import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CatalogInfo, CatalogItemFieldsVM, CatalogType } from '@shared/models/shared-catalog.state';
import { CatalogJSONFile, CatalogJSONItemProperty, FileTypes } from 'app/modules/secondary-pages/files/models';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FilesRouteService {
  constructor(@Inject(PLATFORM_ID) private platformId: string) {}

  public saveCatalogInStorage(catalogId: string, catalog: CatalogInfo, releaseDate = ''): void {
    if (isPlatformBrowser(this.platformId)) {
      const catalogFiles = JSON.parse(sessionStorage.getItem(`catalogsJson${releaseDate}`) ?? '{}');
      if (!(catalogId in catalogFiles)) {
        catalogFiles[catalogId] = catalog;
        sessionStorage.setItem(`catalogsJson${releaseDate}`, JSON.stringify(catalogFiles));
      }
    }
  }

  public getCatalogFromStorage(catalogId: string, releaseDate: string | null): CatalogInfo | null {
    if (isPlatformBrowser(this.platformId)) {
      const catalogFiles = JSON.parse(sessionStorage.getItem(`catalogsJson${releaseDate}`) ?? '{}');
      if (catalogId in catalogFiles) {
        return catalogFiles[catalogId];
      }
    }
    return null;
  }

  public convertType(type: CatalogType): FileTypes {
    switch (type) {
      case CatalogType.Number:
      case CatalogType.Numeric:
        return 'number';
      case CatalogType.Catalog:
        return 'array';
      case CatalogType.Integer:
        return 'integer';
      case CatalogType.Boolean:
        return 'boolean';

      case CatalogType.LINK:
        return 'link';
      default:
        return 'string';
    }
  }

  public parseAttributes(fields: CatalogItemFieldsVM[], response: CatalogJSONFile | CatalogJSONItemProperty, hasGlobalId = true): any {
    fields
      .filter(field => !field.system || field.name === 'global_id')
      .forEach(field => {
        if (field.name === 'global_id' && !hasGlobalId) return;

        if (!response.items) response.items = { properties: {}, type: 'object' };

        response.items.properties[field.techName] = {
          description: field.name,
          type: this.convertType(field.type ?? field.fieldType)
        };

        if (field.type === 'CATALOG' && field.specification) {
          this.parseAttributes(field.specification.fields, response.items.properties[field.techName], false);
        }

        if (field.type === 'STRING') {
          response.items.properties[field.techName].maxLength = field.maxLength;
        }

        if (hasGlobalId && response.items.required) response.items.required.push(field.techName);
      });
  }

  public getCatalogJson(catalogId: number, catalogName: string, releaseDate: string | null, fileName: string): Observable<CatalogJSONFile | null> {
    const catalog = this.getCatalogFromStorage(catalogName, releaseDate);

    if (catalog) return of(this.formatJsonResponse(catalogId, catalogName, catalog, fileName));

    return of(null);
  }

  public formatJsonResponse(catalogId: number, catalogName: string, catalog: CatalogInfo, fileName: string): CatalogJSONFile {
    const response: CatalogJSONFile = {
      $schema: 'https://json-schema.org/draft/2020-12/schema',
      $id: `${environment.siteUrl}/opendata/${catalogId}/${catalogName}/${fileName}`,
      title: catalog?.shortName,
      description: catalog?.accountingObject,
      type: 'array',
      minItems: 1,
      items: {
        type: 'object',
        properties: {},
        required: []
      }
    };

    this.parseAttributes(catalog?.fields, response);

    if (catalog.hasGeo && !catalog.isDynamic) {
      response.items.properties.geoData = {
        anyOf: [{ $ref: '#/$defs/oneTypeGeo' }, { $ref: '#/$defs/fewTypeGeo' }]
      };
      // eslint-disable-next-line @typescript-eslint/naming-convention
      response.items.properties.geodata_center = {
        $ref: '#/$defs/oneTypeGeo'
      };
      response.$defs = {
        oneTypeGeo: {
          type: 'object',
          properties: {
            coordinates: {
              type: 'array',
              items: {
                type: ['number', 'array'],
                items: {
                  type: ['number', 'array'],
                  items: {
                    type: ['number', 'array'],
                    items: {
                      type: 'number'
                    }
                  }
                }
              }
            },
            type: {
              type: 'string'
            }
          }
        },
        fewTypeGeo: {
          type: 'object',
          properties: {
            geometries: {
              type: 'array',
              items: {
                $ref: '#/$defs/oneTypeGeo'
              }
            },
            type: {
              type: 'string'
            }
          }
        }
      };
    } else if (catalog.hasGeo) {
      response.items.properties.geoData = {
        type: 'object',
        properties: {
          type: {
            type: 'string'
          },
          coordinates: {
            type: 'array',
            items: {
              type: 'number'
            }
          }
        }
      } as any;
    }

    return response;
  }
}

<div class="navbar" [class.open]="isMobileMenuOpen" (touchstart)="touchStart($event)"  (touchend)="touchEnd($event)" >
  <div class="header ml20 mt20">
    <svg [icon]="icons.MobileMenuOpen"  (click)="isMobileMenuOpen=!isMobileMenuOpen" class="svg24 white-color mr12" />
    <a class="logo-container td-n" (click)="isMobileMenuOpen = false"  [routerLink]="['/']">
      <svg [icon]="icons.Logo" class="svg32 mr8 logo-icon" />
      <div class="title-container">
        <span class="layout-title h4 white-color">{{ 'LAYOUT.PORTALNAME' | translate }}</span>
        <p class="layout-subtitle">{{ 'LAYOUT.GOVERMENT' | translate }}</p>
      </div>
    </a>
  </div>
  <div class="menu-container mt32">
    <a class="body-2 menu-item" [class.active]="isActiveDataUrl" [routerLink]="dataRoute" (click)="closeMenu()" mat-button>{{
      'LAYOUT.DATA' | translate
    }}</a>
    <a class="body-2 menu-item" [class.active]="isActiveDevelopersUrl" [routerLink]="developersRoute" (click)="closeMenu()" mat-button>
      {{ 'LAYOUT.DEVELOPERS' | translate }}
    </a>
  </div>

  <div class="horizontal-line mt16 mb16"></div>

  <div class="menu-container ml16 mr16">
    <div class="flex-block mb16" [routerLink]="visuallyImpaired">
      <svg [icon]="icons.Eye" class="svg24 mr8 primary-color" />
      <span class="body-2 white-color">{{ 'LAYOUT.CONTRAST_VERSION' | translate }}</span>
    </div>
    <div (click)="changeLanguage()">
      <span class="mr8 primary-color">{{ 'LAYOUT.LANGUAGE' | translate }} </span>
      <span class="body-2 white-color">{{ 'LAYOUT.MOBILE_LANGUAGE' | translate }}</span>
    </div>
  </div>

  <div class="horizontal-line mt16 mb16"></div>

  <div class="ml16 mr16">
    <div class="mt12 flex-block">
      <span class="caption-4 mr4 footer-text">{{'FOOTER.TECHNICAL_SUPPORT' | translate}}: </span><a class="caption-3 dark">opendata&#64;mos.ru</a>
    </div>

    <div class="mt12 flex-block">
      <a (click)="isMobileMenuOpen = false" class="caption-3 dark" [routerLink]="['/terms']">{{'FOOTER.TERMS_CONDITIONS' | translate}}</a>
    </div>

    <div *ngIf="isRuLocale" class="mt12 flex-block">
      <a  (click)="isMobileMenuOpen = false" class="caption-3 dark" [routerLink]="['/documents']">{{'FOOTER.DOCUMENTATION' | translate}}</a>
    </div>
  </div>

  <div class="horizontal-line mt16 mb16"></div>

  <div class="ml16 mr16">
    <span class="footer-text caption-4 mb8">{{'FOOTER.LICENCE' | translate}}  <a href="https://creativecommons.org/licenses/by/3.0/deed.ru" target="_blank" class="caption-3 footer-link">Creative Commons Attribution 3.0</a>. {{'FOOTER.LICENCE_LAST' | translate}}.</span>
    <span class="footer-text caption-4 mb8">{{'FOOTER.REGARDING' | translate}} <a href="https://data.mos.ru" target="_blank" class="caption-3 footer-link">https://data.mos.ru</a>,  {{'FOOTER.MATERIAL_AVAILABLE' | translate}} <a href="https://data.gov.ru/information-usage" target="_blank" class="caption-3 footer-link">https://data.gov.ru/information-usage</a>.</span>
    <span class="footer-text caption-4 "><a class="caption-3 dark footer-link"  target="_blank" href="https://www.mos.ru/legal/rules/">{{'FOOTER.AGREEMENT' | translate}}.</a></span>
  </div>

  <div class="horizontal-line mt16 mb16"></div>

  <div class="ml16 mr16 mt12 flex-block" social-share>
  </div>
</div>
<div class="backdrop" (click)="closeMenu()" *ngIf="isMobileMenuOpen"></div>

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { VKGroupPostsResponseReq } from '@shared/models/vk';
import { ServerLinks } from '@shared/services/server-links';
import { ServerLinkName } from '@shared/models/server-links-model';
import { VkCardData } from '@shared/components/vk-card/models';
import { map } from 'rxjs/operators';
import { DateTime } from 'luxon';
import { maxBy } from 'lodash';
import { Store } from '@ngxs/store';
import { MainPageState } from '../../main/store/main-page.state';

@Injectable({
  providedIn: 'root'
})
export class VkService {
  private _urlVk = '';
  public vkPosts$ = new BehaviorSubject<VkCardData[]>([]);
  constructor(private http: HttpClient, private linkService: ServerLinks, private store: Store) {
    this._urlVk = linkService.getLink(ServerLinkName.VkData);
  }

  public getPostsInfo(): Observable<VkCardData[]> {
    if (this.vkPosts$.value && this.vkPosts$.value.length !== 0) {
      return of(this.vkPosts$.value);
    }
    return this.http.get<VKGroupPostsResponseReq>(`${this._urlVk}get`).pipe(
      map(resResponse => {
        const serverTime = this.store.selectSnapshot(MainPageState.getServerTime)?.adaptedServerTime ?? DateTime.now();
        let result: VkCardData[] = resResponse.data.response.items
          .sort((a, b) => b.date - a.date)
          .map(res => {
            const cardData: VkCardData = {
              isPinned: res?.is_pinned === 1,
              text: res.text,
              createData: DateTime.fromSeconds(res.date).toString(),
              likesCount: res.likes.count,
              commentsCount: res.comments.count,
              postId: res.id.toString(),
              type: 'text'
            };

            if (res?.geo) {
              const coordinates = res?.geo.coordinates
                .split(' ')
                .map(coord => Number(coord))
                .reverse();
              cardData.type = 'map';
              cardData.map = [
                {
                  center: { coordinates, type: 'Point' },
                  allCoordinates: { coordinates, type: 'Point' },
                  catalogItemId: 0
                }
              ];
            }

            if (res?.attachments) {
              const attachment = res?.attachments[0];

              if (attachment?.photo) {
                cardData.type = 'photo';
                cardData.imagePath = attachment?.photo.sizes[attachment.photo.sizes.length - 1].url;
              } else if (attachment?.video) {
                cardData.type = 'video';
                cardData.title = attachment?.video?.title;
                cardData.imagePath = attachment?.video?.image[attachment.video.image.length - 1].url;
              } else if (attachment?.poll) {
                cardData.type = 'poll';
                const answers = attachment.poll.answers;

                const leadAnswer = maxBy(answers, 'rate');

                if (leadAnswer) leadAnswer.leader = true;
                cardData.title = attachment?.poll.question;

                cardData.poll = {
                  answers: attachment.poll.answers,
                  isFinished: DateTime.fromSeconds(attachment?.poll.end_date) < serverTime
                };
              } else if (attachment?.audio) {
                const audio = attachment?.audio;
                cardData.type = 'audio';
                cardData.title = `${audio.artist} - ${audio.title}`;
              } else if (attachment?.link) {
                cardData.type = 'link';
                cardData.title = attachment?.link.title;
                cardData.imagePath = attachment?.link.photo?.sizes[4].url ?? '/assets/img/no-post-pic.png';
              } else if (attachment?.doc) {
                cardData.type = 'file';
                cardData.title = attachment?.doc.title;
              }
            }
            return cardData;
          });

        result = [
          ...result.filter(x => x.isPinned),
          ...result.filter(x => x.poll && !x.poll?.isFinished && !x.isPinned),
          ...result.filter(x => (x.poll?.isFinished || !x.poll) && !x.isPinned)
        ];

        this.vkPosts$.next(result);
        return result;
      })
    );
  }
}

import { DateTime } from 'luxon';
import { Seasons } from '../../data/modules/catalog-data/store/catalog-table.state';

export class ServerTime {
  private readonly _serverTimeFromResponse: string;

  public adaptedServerTime: DateTime;

  constructor(serverTime: string) {
    this._serverTimeFromResponse = serverTime;
    this.adaptedServerTime = DateTime.fromISO(serverTime, { zone: 'Europe/Moscow' });
  }

  public serverTimeMonthAgoInServerFormat(): string {
    const date = this.adaptedServerTime.setZone('UTC').minus({ months: 1 });
    return date.toFormat("yyyy-MM-dd'T'HH:mm:ss.SSS+00:00");
  }

  public getCurrentSeason(): string {
    const winter = [9, 10, 11, 0, 1, 2];

    if (winter.includes(this.adaptedServerTime.month)) {
      return Seasons.winter;
    }
    return Seasons.summer;
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { ServerLinks } from '@shared/services/server-links';
import { ServerLinkName } from '@shared/models/server-links-model';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Locale } from '@shared/models/locale';
import { CreateFile, FileInfo, FileType } from '../models/catalog';

@Injectable({
  providedIn: 'root'
})
export class DownloadCatalogService {
  private _openData = '';

  constructor(private http: HttpClient, serverLinks: ServerLinks, private translateService: TranslateService) {
    this._openData = serverLinks.getLink(ServerLinkName.OpenData);
  }

  public statusFiles(catalogsIds: number[], versionDate = ''): Observable<FileInfo[]> {
    let params: any = {
      id: catalogsIds
    };
    if (versionDate.length > 0) {
      params = {
        ...params,
        version: versionDate
      };
    }
    return this.http.post<FileInfo[]>(`${this._openData}export/catalog/status`, params).pipe(
      map(
        res =>
          res?.map(r => ({
            ...r,
            files: r.files.map(f => ({
              ...f,
              status: f.status === 'CREATED' ? 'PROCESSING' : f.status,
              archiveFileSize: +(f.archiveFileSize / 1024).toFixed(2)
            }))
          })) ?? []
      )
    );
  }

  public downloadFile(fileId: number): Observable<ArrayBuffer> {
    let url = `${this._openData}export/catalog?idFile=${fileId}`;
    if (this.translateService.currentLang === Locale.EN_US) {
      url += '&translate=true';
    }

    return this.http.get<ArrayBuffer>(url, {
      responseType: 'arraybuffer' as 'json'
    });
  }

  public createFile(catalogId: number, format: FileType, version: string | null = null): Observable<CreateFile> {
    let url = `${this._openData}export/catalog/${catalogId}/create?format=${format.toLowerCase()}${version ? `&version=${version}` : ''}`;
    if (this.translateService.currentLang === Locale.EN_US) {
      url += '&translate=true';
    }

    return this.http.get<CreateFile>(url);
  }

  public statusAllFiles(catalogsIds: number[]): Observable<FileInfo[]> {
    return this.http
      .post<FileInfo[]>(`${this._openData}export/catalog/status`, {
        id: catalogsIds,
        version: 'all',
        format: FileType.XLSX
      })
      .pipe(
        map(
          res =>
            res?.map(r => ({
              ...r,
              files: r.files.map(f => ({
                ...f,
                status: f.status === 'CREATED' ? 'PROCESSING' : f.status
              }))
            })) ?? []
        )
      );
  }

  public downloadRegistry(type: 'csv' | 'json'): Observable<ArrayBuffer> {
    let url = `${this._openData}export/list?format=${type}`;
    if (this.translateService.currentLang === Locale.EN_US) {
      url += '&translate=true';
    }
    return this.http.get<ArrayBuffer>(url, {
      responseType: 'arraybuffer' as 'json'
    });
  }

  public downloadPassport(type: 'csv' | 'json', catalogId: number): Observable<Blob> {
    let url = `${this._openData}export/meta?idCatalog=${catalogId}&format=${type}`;
    if (this.translateService.currentLang === Locale.EN_US) {
      url += '&translate=true';
    }

    return this.http.get<Blob>(url, {
      responseType: 'blob' as any
    });
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngxs/store';
import { ServerLinks } from '@shared/services/server-links';
import { ServerLinkName } from '@shared/models/server-links-model';
import { Observable } from 'rxjs';

import { GeoDataAreaResponse, GeoDataClusterParams, GeoDataClusterResponse, GeoDataDistrictResponse } from '@shared/models/geodata.service';

@Injectable({
  providedIn: 'root'
})
export class GeodataService {
  private _urlopenData = '';

  constructor(private http: HttpClient, private store: Store, serverLinks: ServerLinks) {
    this._urlopenData = serverLinks.getLink(ServerLinkName.OpenData);
  }

  public getAllCatalogArea(): Observable<GeoDataAreaResponse> {
    return this.http.get<GeoDataAreaResponse>(`${this._urlopenData}/area`);
  }

  public getAllCatalogDistrict(): Observable<GeoDataDistrictResponse> {
    return this.http.get<GeoDataDistrictResponse>(`${this._urlopenData}/district`);
  }

  public getAllCatalogCluster(clusterParams: GeoDataClusterParams): Observable<GeoDataClusterResponse> {
    return this.http.post<GeoDataClusterResponse>(`${this._urlopenData}/cluster`, clusterParams);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ServerLinks } from '@shared/services/server-links';
import { ServerLinkName } from '@shared/models/server-links-model';
import { Observable } from 'rxjs';
import {
  CatalogItemFieldsResponse,
  CatalogParams,
  CatalogResponse,
  CatalogResponseDynamic,
  DynamicCatalogItemFieldsResponse,
  FileData,
  GeoData,
  GetValueFieldsParams,
  ParamsCatalogRatingSave,
  ResponseCatalogDataSave
} from '@shared/models/catalog.service';
import { CatalogItemValueResponseArray } from '@shared/models/shared.service';
import { map } from 'rxjs/operators';
import { DateTime } from 'luxon';
import { Store } from '@ngxs/store';
import { SharedCatalogState } from '@shared/state/catalog/shared-catalog.state';
import { BodyReq, HistoryParams } from '../../data/modules/catalog-items-data/models/catalog-item.service';
import { CatalogVersionRelease, NewCatalogVersionRelease } from '../../data/models/catalog';

@Injectable({
  providedIn: 'root'
})
export class CatalogService {
  private _urlEhdapi = '';
  private _urlopenData = '';
  private _urlLkApi = '';
  private _urlDynApi = '';
  private readonly _httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'ehd-system': 'opod'
  });

  constructor(private http: HttpClient, private store: Store, serverLinks: ServerLinks) {
    this._urlEhdapi = serverLinks.getLink(ServerLinkName.EhdApi);
    this._urlopenData = serverLinks.getLink(ServerLinkName.OpenData);
    this._urlLkApi = serverLinks.getLink(ServerLinkName.Lk);
    this._urlDynApi = serverLinks.getLink(ServerLinkName.DynApi);
  }

  private setPeriodName(period: string): string {
    if (!isNaN(+period)) {
      return `${period} sec.`;
    }

    switch (period) {
      case 'Периодичность обновления':
        return 'Data update rate';
      case 'Не выбрано':
        return 'Not selected';
      case 'Ежеминутно':
        return 'Every minute';
      case 'Ежечасно':
        return 'Hourly';
      case 'Ежедневно':
        return 'Daily';
      case 'Еженедельно':
        return 'Weekly';
      case 'Ежемесячно':
        return 'Monthly';
      case 'Ежеквартально':
        return 'Quarterly';
      case 'Ежегодно':
        return 'Annually';
      case 'Настраиваемая (Произвольный срок)':
        return 'Customizable (arbitrary term)';
      case 'Настраиваемая (Календарные дни)':
        return 'Custom (calendar days)';
      case 'Множественная периодичность':
        return 'Multiple periodicity';
      case 'По мере поступления изменений из источников актуализации':
        return 'As changes are received from the update sources';
      case 'В режиме реального времени':
        return 'Real time ';
      default:
        return 'Multiple periodicity';
    }
  }

  public getCatalogInfo(catalogId: number, params: any = {}): Observable<CatalogItemFieldsResponse> {
    const body = {
      id: [catalogId],
      ...params
    };
    return this.http
      .post<CatalogItemFieldsResponse>(`${this._urlEhdapi}catalog/list`, body, {
        headers: this._httpHeaders
      })
      .pipe(
        map(x => {
          const result = x;
          result.response = x.response.map(v => ({ ...v, periodEn: this.setPeriodName(v.period) }));
          return result;
        })
      );
  }

  public getDynamicCatalogInfo(catalogId: number, params: any = {}): Observable<CatalogItemFieldsResponse> {
    const body = {
      id: [catalogId],
      ...params
    };
    return this.http
      .post<DynamicCatalogItemFieldsResponse>(`${this._urlDynApi}dynamicCatalog/list`, body, {
        headers: this._httpHeaders
      })
      .pipe(
        map(res => ({
          ...res,
          response: res.response.map(catalogInfo => ({
            ...catalogInfo,
            isDynamic: catalogInfo.is_dynamic,
            periodEn: this.setPeriodName(catalogInfo.updatePeriod ?? ''),
            fields: catalogInfo.fieldSpecificationList.map(field => ({
              ...field,
              type: field.fieldType,
              isDefaultSystems: field.permittedSystems
            }))
          }))
        }))
      );
  }

  public getCatalogs(params?: CatalogParams): Observable<CatalogResponse> {
    return this.http.post<CatalogResponse>(`${this._urlopenData}catalog/list`, params, { headers: this._httpHeaders }).pipe(
      map(res => ({
        found: res.found,
        response: res.response.filter(x => x.dataset)
      }))
    );
  }

  public getDynamicCatalogs(params?: CatalogParams): Observable<CatalogResponse> {
    return this.http.post<CatalogResponse>(`${this._urlopenData}dynamicCatalog/list`, params, { headers: this._httpHeaders });
  }

  public getDynamicCatalogsFiles(params?: CatalogParams): Observable<CatalogResponseDynamic> {
    return this.http.post<CatalogResponseDynamic>(`${this._urlDynApi}dynamicCatalog/list`, params, { headers: this._httpHeaders });
  }

  public getValueFields(
    params: GetValueFieldsParams,
    geoData: GeoData | null = null,
    historyParams: HistoryParams | null = null,
    sortingParams: any | null = null,
    isPaging = false
  ): Observable<CatalogItemValueResponseArray> {
    let body: BodyReq = {
      ...params,
      fetchGeodata: true
    };

    if (geoData) {
      body = { ...body, geoData: geoData };
    }

    if (historyParams) {
      body = { ...body, ...historyParams };
    }

    if (sortingParams) {
      body = { ...body, ...{ sorting: sortingParams } };
    }

    if (params.criteria && isPaging) {
      body = { ...body, ...{ paging: true } };
    }

    return this.http.post<CatalogItemValueResponseArray>(`${this._urlEhdapi}catalog/get`, body, {
      headers: this._httpHeaders
    });
  }

  public getDynamicValueFields(
    params: GetValueFieldsParams,
    geoData: GeoData | null = null,
    sortingParams: any | null = null
  ): Observable<CatalogItemValueResponseArray> {
    const catalogFields = this.store.selectSnapshot(SharedCatalogState.fields);
    const isPkField = catalogFields?.find(x => x.isPk);

    let body: BodyReq = {
      ...params,
      columnSort: isPkField ? isPkField.techName : 'id_dynamic',
      fetchGeodata: true
    };

    if (geoData) {
      body = { ...body, geoData: geoData };
    }

    if (sortingParams) {
      body = { ...body, ...{ sorting: sortingParams } };
    }

    return this.http
      .post<CatalogItemValueResponseArray>(`${this._urlDynApi}dynamicCatalog/get`, body, {
        headers: this._httpHeaders
      })
      .pipe(
        map(res => ({
          ...res,
          response: res.response
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            .filter(obj => Object.keys(obj).length > 0)
            .map(r => ({
              ...r,
              // eslint-disable-next-line @typescript-eslint/naming-convention
              global_id: isPkField ? +r[isPkField.techName] : +r.id_dynamic,
              geoData: r.geodata
            }))
        }))
      );
  }

  public getGeoData(params: GetValueFieldsParams, isDynamic: boolean, geoData: GeoData | null = null): Observable<CatalogItemValueResponseArray> {
    let body = {
      ...params,
      fetchGeodata: true,
      projection: ['global_id', 'geoData']
    };

    if (geoData) {
      body = { ...body, geoData: geoData };
    }

    let url = `${this._urlEhdapi}catalog/get`;
    const catalogFields = this.store.selectSnapshot(SharedCatalogState.fields);
    const isPkField = catalogFields?.find(x => x.isPk);
    if (isDynamic) {
      body = { ...body, columnSort: isPkField ? isPkField.techName : 'id_dynamic' };
      body.projection = [isPkField ? isPkField.techName : 'id_dynamic', 'geodata'];
      url = `${this._urlDynApi}dynamicCatalog/get`;
    }

    return this.http
      .post<CatalogItemValueResponseArray>(url, body, {
        headers: this._httpHeaders
      })
      .pipe(
        map(res => {
          if (isDynamic) {
            return {
              ...res,
              response: res.response.map(x => ({
                // eslint-disable-next-line @typescript-eslint/naming-convention
                global_id: isPkField ? x[isPkField.techName] : x.id_dynamic,
                geoData: x.geodata
              }))
            };
          }

          return res as CatalogItemValueResponseArray;
        })
      );
  }

  public saveUserRating(params: ParamsCatalogRatingSave): Observable<ResponseCatalogDataSave> {
    return this.http.post<ResponseCatalogDataSave>(`${this._urlopenData}userRatingIn`, params, {
      headers: this._httpHeaders
    });
  }

  public getRecommendations(catalogId: number | null = null): any {
    const params = new HttpParams();
    if (catalogId) params.set('idCatalog', catalogId);

    return this.http.get<any>(`${this._urlLkApi}api/v1/recommendation`, {
      headers: this._httpHeaders,
      params
    });
  }

  public getAllCatalogVersion(catalogId: number, hasDatasetId: boolean, accessKey: string | null = null): Observable<CatalogVersionRelease[]> {
    let url = `${this._urlopenData}catalog/versions?${hasDatasetId ? 'idDataset' : 'id'}=${catalogId}`;

    if (accessKey) {
      url += `&access=${accessKey}`;
    }

    return this.http.get<NewCatalogVersionRelease[]>(url).pipe(
      map(res => {
        if (res.length) {
          const result: CatalogVersionRelease[] = [];
          res.forEach(x => {
            x.releases.forEach(r => {
              result.push({
                releaseStatus: r.releaseStatus,
                catalogId: x.idPublicCatalog,
                versionNum: r?.versionNum,
                releaseDate: DateTime.fromJSDate(new Date(r.releaseDate)).toFormat('dd.MM.yyyy'),
                releaseNum: r?.releaseNum,
                releaseDateFull: DateTime.fromJSDate(new Date(r.releaseDate)).toFormat('yyyyMMddТHHmm'),
                defaultDate: r.releaseDate,
                versionDate: DateTime.fromJSDate(new Date(r.versionDate)).toFormat('dd.MM.yyyy'),
                countRows: r.countRows
              });
            });
          });

          return result;
        }
        return [];
      })
    );
  }

  public getCatalogVersionRelease(
    catalogId: number,
    hasDatasetId: boolean,
    accessKey: string | null,
    version: number | null,
    release: number | null
  ): Observable<CatalogVersionRelease | null> {
    let url = `${this._urlopenData}catalog/versions?${hasDatasetId ? 'idDataset' : 'id'}=${catalogId}`;

    if (accessKey) {
      url += `&access=${accessKey}`;
    }

    if (version) {
      url += `&version=${version}`;
    }

    if (version) {
      url += `&release=${release}`;
    }

    return this.http.get<NewCatalogVersionRelease[]>(url).pipe(
      map(res => {
        let result: CatalogVersionRelease | null = null;
        if (res.length && res[0].releases[0]) {
          const r = res[0].releases[0];

          result = {
            releaseStatus: r.releaseStatus,
            catalogId: res[0].idPublicCatalog,
            versionNum: r?.versionNum,
            releaseDate: r.releaseDate,
            releaseNum: r?.releaseNum,
            releaseDateFull: DateTime.fromJSDate(new Date(r.releaseDate)).toFormat('yyyyMMddТHHmm'),
            defaultDate: r.releaseDate,
            versionDate: r.versionDate,
            countRows: r.countRows
          };
        }
        return result;
      })
    );
  }

  public getDynCatalogFiles(catalogId: number): Observable<
    {
      name: string;
      size: string;
    }[]
  > {
    return this.http.get<
      {
        name: string;
        size: string;
      }[]
    >(`${this._urlDynApi}history/${catalogId}`);
  }

  public downloadDynCatalogFile(catalogId: number, fileName: string): Observable<ArrayBuffer> {
    return this.http.get<ArrayBuffer>(`${this._urlDynApi}history/${catalogId}/${fileName}`, {
      responseType: 'arraybuffer' as 'json'
    });
  }

  public getDownloadFileNames(param: string): Observable<FileData[]> {
    return this.http.get<FileData[]>(`/MEDIA/multi_info?ids=${param}`);
  }
}

import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TranslateService as NgxTranslateService } from '@ngx-translate/core';

import { Locale } from '@shared/models/locale';

@Injectable()
export class TranslateService {

  private isBrowser = isPlatformBrowser(this.platformId);

  get htmlElement(): HTMLElement {
    return this.document.getElementsByTagName('html')[0];
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: string,
    private ngxTranslateService: NgxTranslateService,
  ) {}

  use(locale: Locale): void {
    this.changeLanguageData(locale);
    this.changeLocalStorageLocale(locale);
  }

  /**
   * изменение в локальном хранилище значения locale
   */
  private changeLocalStorageLocale(locale: Locale): void {
    if (this.isBrowser) {
      localStorage.setItem('locale', locale.toString());
    }
  }

  /**
   * вызов сервиса ngx-translate
   */
  private changeLanguageData(locale: Locale): void {
    this.ngxTranslateService.use(locale);
  }
}

import { Inject, Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { AuthState, AuthStateModel } from '@auth/store/auth/auth.state';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class PersonalProfileGuard  {
  constructor(private store: Store, private router: Router, @Inject(DOCUMENT) private document: Document) {}

  public canLoad(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const authState: AuthStateModel = this.store.selectSnapshot(AuthState);
    const window = this.document.defaultView;
    if (window && !authState.user) {
      if (window.document.URL.includes('information-placement')) {
        this.router.navigate([`./developers`]);
      } else if (window.document.URL.includes('personal-profile')) {
        this.router.navigate([`./`]);
      }
    }

    return !!authState.user;
  }
}

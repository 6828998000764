import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MirSnackbarType } from '@shared/components/snackbar-message/model/snackbar';
import { SnackBarService } from '@shared/components/snackbar-message/services/snackbar.service';
import { AddFavoriteCatalog, DeleteFavoriteCatalog, GetFavoriteCatalogs } from './favorite-catalogs.action';
import { AddOrDeleteCatalogListResponse, CatalogForUserResponse } from '../../models/catalog';
import { CatalogFavoriteService } from '../../services/catalog-favorite.service';

type FavoriteCatalogsStateModel = {
  favoriteCatalogIds: number[];
};

@State<FavoriteCatalogsStateModel>({
  name: 'favoriteCatalogs',
  defaults: {
    favoriteCatalogIds: []
  }
})
@Injectable()
export class FavoriteCatalogsState {
  constructor(private catalogFavoriteService: CatalogFavoriteService, private store: Store, private snackBar: SnackBarService) {}

  @Selector()
  public static favoriteCatalogIds(state: FavoriteCatalogsStateModel): number[] | undefined {
    return state.favoriteCatalogIds;
  }

  @Action(GetFavoriteCatalogs)
  public getFavoriteCatalogs(ctx: StateContext<FavoriteCatalogsStateModel>): Observable<CatalogForUserResponse | undefined> {
    /*    const subscribeCatalogIds = ctx.getState().favoriteCatalogIds;
   if (subscribeCatalogIds.length > 0) {
      return of(undefined);
    } */
    return this.catalogFavoriteService.getFavoriteCatalogs().pipe(
      tap(res => {
        ctx.patchState({
          favoriteCatalogIds: res?.catalog.map(res => res.catalogId) ?? []
        });
      }),
      catchError((error: HttpErrorResponse) => {
        this.snackBar.open(MirSnackbarType.Error, [
          {
            translate: true,
            message: 'SNACK_BAR_MESSAGE.ERROR_LOADING_FAVORITES'
          },
          {
            translate: false,
            message: `\n${error.message}`
          }
        ]);

        return of(undefined);
      })
    );
  }

  @Action(AddFavoriteCatalog)
  public addFavoriteCatalogs(
    ctx: StateContext<FavoriteCatalogsStateModel>,
    action: AddFavoriteCatalog
  ): Observable<AddOrDeleteCatalogListResponse | undefined> {
    return this.catalogFavoriteService.addFavoriteCatalog(action.catalogid).pipe(
      tap(res => {
        if (res.statusId === 1) {
          this.snackBar.open(MirSnackbarType.Error, [
            {
              translate: true,
              message: 'SNACK_BAR_MESSAGE.ERROR_ADD_FAVORITE'
            }
          ]);
          return;
        }
        const subscribeCatalogIds = ctx.getState().favoriteCatalogIds;
        ctx.patchState({
          favoriteCatalogIds: [...subscribeCatalogIds, action.catalogid]
        });
      }),
      catchError((error: HttpErrorResponse) => {
        this.snackBar.open(MirSnackbarType.Error, [
          {
            translate: true,
            message: 'SNACK_BAR_MESSAGE.ERROR_ADD_FAVORITE'
          },
          {
            translate: false,
            message: `\n${error.message}`
          }
        ]);

        return of(undefined);
      })
    );
  }

  @Action(DeleteFavoriteCatalog)
  public deleteFavoriteCatalog(
    ctx: StateContext<FavoriteCatalogsStateModel>,
    action: DeleteFavoriteCatalog
  ): Observable<AddOrDeleteCatalogListResponse | undefined> {
    return this.catalogFavoriteService.deleteFavoriteCatalog(action.catalogid).pipe(
      tap(res => {
        if (res.statusId === 1) {
          this.snackBar.open(MirSnackbarType.Error, [
            {
              translate: true,
              message: 'SNACK_BAR_MESSAGE.ERROR_REMOVE_FAVORITE'
            }
          ]);
          return;
        }
        const subscribeCatalogIds = ctx.getState().favoriteCatalogIds;
        const newSubscribeCatalogIds = subscribeCatalogIds.filter(catalogId => catalogId !== action.catalogid);

        ctx.patchState({
          favoriteCatalogIds: newSubscribeCatalogIds
        });
      }),
      catchError((error: HttpErrorResponse) => {
        this.snackBar.open(MirSnackbarType.Error, [
          {
            translate: true,
            message: 'SNACK_BAR_MESSAGE.ERROR_REMOVE_FAVORITE'
          },
          {
            translate: false,
            message: `\n${error.message}`
          }
        ]);

        return of(undefined);
      })
    );
  }
}

import { ChangeDetectionStrategy, Component, HostListener, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, NavigationError, NavigationStart, Router, Scroll } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DOCUMENT, isPlatformBrowser, Location, ViewportScroller } from '@angular/common';
import { repeat, switchMap, takeUntil } from 'rxjs/operators';
import { IDestroy, TakeUntilDestroy } from '@utils/auto-unsubscribe';
import { of, Subject } from 'rxjs';
import { Store } from '@ngxs/store';
import { Login, LoginLocalStorage, Logout } from '@auth/store/auth/auth.action';
import { setLocationQueryParams } from '@utils/other-utils';
import { Locale } from '@shared/models/locale';
import { TranslateService } from '@shared/services/translate.service';
import { UserInfo } from '@auth/models/auth.state';
import { AuthState } from '@auth/store/auth/auth.state';
import { UserNotifyService } from '@shared/services/user-notify.service';
import { CustomIcons } from '@shared/models/custom-icons';
import { SnackbarMessageComponent } from '@shared/components/snackbar-message/component/snackbar-message/snackbar-message.component';
import { environment } from '@environments/environment.prod';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@TakeUntilDestroy
export class AppComponent implements OnInit, IDestroy {
  public componentDestroy: () => Subject<any>;
  public showLayout = false;
  public isImpaired = false;
  public icons = CustomIcons;

  constructor(
    private translateService: TranslateService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private store: Store,
    private location: Location,
    private userNotify: UserNotifyService,
    private _snackBar: MatSnackBar,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: string,
    viewportScroller: ViewportScroller
  ) {
    router.events.subscribe(val => {
      if (val instanceof NavigationStart) {
        if (val.url.includes('opodopendata') && val.url.split('/').length === 4 && this.showLayout) {
          this.showLayout = false;
        } else {
          this.showLayout = true;
        }
        this.isImpaired = val.url.includes('visually-impaired') || val.url.includes('impaired-appeals');
      }

      if (val instanceof Scroll) {
        if (val.position) {
          // backward navigation
          viewportScroller.scrollToPosition(val.position);
        } else if (val.anchor) {
          // anchor navigation
          viewportScroller.scrollToAnchor(val.anchor);
        } else {
          // forward navigation
          viewportScroller.scrollToPosition([1, 1]);
        }
      }

      this.handleRouterErrors(val as NavigationError);
    });
  }

  private handleRouterErrors(event: NavigationError) {
    const window = this.document.defaultView;
    if (window && event?.error?.name === 'ChunkLoadError') {
      window.location.href = `${window.location.origin}${event.url}`;
    }
  }

  private longPol() {
    const userData = JSON.parse(localStorage.getItem('auth') as string);
    of(1)
      .pipe(
        switchMap(() => {
          if (userData.user) {
            const requestOptions = {
              'User-Token': userData.user.token as string,
              'Connection-Uid': Date.now().toString()
            };
            return this.userNotify.notifyUser(requestOptions);
          }
          return of(null);
        }),
        repeat({ delay: 5000 }),
        takeUntil(this.componentDestroy())
      )
      .subscribe(data => {
        if (data) {
          const snackbarData = {
            message: data.message,
            icon: CustomIcons.Notifications
          };
          this._snackBar.openFromComponent(SnackbarMessageComponent, {
            data: snackbarData,
            panelClass: ['default-snackbar'],
            horizontalPosition: 'end',
            verticalPosition: 'top'
          });
        }
      });
  }

  @HostListener('window:storage')
  public storageChange(): void {
    if (isPlatformBrowser(this.platformId)) {
      const stringAuth = localStorage.getItem('auth');
      if (stringAuth) {
        const auth = JSON.parse(stringAuth) as { user: UserInfo };
        const currentUser = this.store.selectSnapshot(AuthState.authenticatedUser);
        if (currentUser && currentUser.token && !auth.user) {
          this.store.dispatch(new Logout(false, false));
        } else if (!currentUser && auth.user) {
          this.store.dispatch(new LoginLocalStorage(auth.user));
        }
      }
    }
  }

  public ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const userData = JSON.parse(localStorage.getItem('auth') as string);
      if (userData?.user) {
        this.longPol();
      }
      const locale = localStorage.getItem('locale');
      if (locale) {
        this.translateService.use(locale as Locale);
      } else {
        this.translateService.use(environment.defaultLocale);
      }
    } else {
      this.translateService.use(environment.defaultLocale);
    }

    this.activeRoute.queryParams.pipe(takeUntil(this.componentDestroy())).subscribe(res => {
      if (this.location.path().includes('devAuth')) {
        return;
      }
      const code: string = res.code;
      if (code && isPlatformBrowser(this.platformId)) {
        setLocationQueryParams({ code: '' }, this.router, this.location);
        this.store.dispatch(new Login(code));
      }
    });
  }
}

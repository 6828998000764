import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServerLinkName } from '@shared/models/server-links-model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ServerLinks } from '@shared/services/server-links';
import { AddOrDeleteCatalogListResponse, CatalogForUserResponse } from '../models/catalog';

@Injectable({
  providedIn: 'root'
})
export class CatalogFavoriteService {
  private _urlLkApi = '';
  private readonly _httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'ehd-system': 'opod'
  });
  constructor(private http: HttpClient, serverLinks: ServerLinks) {
    this._urlLkApi = serverLinks.getLink(ServerLinkName.Lk);
  }

  public addFavoriteCatalog(catalogId: number): Observable<AddOrDeleteCatalogListResponse> {
    return this.http.post<AddOrDeleteCatalogListResponse>(
      `${this._urlLkApi}api/v1/favorite`,
      {
        catalogId: catalogId
      },
      {
        headers: this._httpHeaders
      }
    );
  }

  public deleteFavoriteCatalog(catalogId: number): Observable<AddOrDeleteCatalogListResponse> {
    return this.http.delete<AddOrDeleteCatalogListResponse>(`${this._urlLkApi}api/v1/favorite`, {
      headers: this._httpHeaders,
      body: {
        catalogId: catalogId
      }
    });
  }

  public getFavoriteCatalogs(limit: number | null = null, offset: number | null = null): Observable<CatalogForUserResponse> {
    let url = `${this._urlLkApi}api/v1/favorite/`;
    if (offset !== null && limit !== null) {
      url = `${this._urlLkApi}api/v1/favorite?limit=${limit}&offset=${offset}`;
    }

    return this.http.get<CatalogForUserResponse>(url, {
      headers: this._httpHeaders
    });
  }
}

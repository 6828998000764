import { Action, Actions, ofAction, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { convertCatalogResponseToView } from '@utils/converters';
import {
  CancelGetGeoDataCluster,
  ChangedSelectCatalog,
  GetCatalogGeoData,
  GetCatalogInfo,
  GetGeoDataArea,
  GetGeoDataCluster,
  GetGeoDataDistrict,
  GetSelectCatalog,
  RefreshCurrentCatalogStats,
  SaveCatalogToStorage,
  SetSelectCatalog
} from '@shared/state/catalog/shared-catalog.action';
import { CatalogService } from '@shared/services/catalog.service';
import { CatalogGeoData, CatalogInfo, CatalogItemFieldsVM, CatalogView } from '@shared/models/shared-catalog.state';
import { GeoDataInfo } from '@shared/components/map/models/map';
import { patch, updateItem } from '@ngxs/store/operators';
import { SnackBarService } from '@shared/components/snackbar-message/services/snackbar.service';
import { MirSnackbarType } from '@shared/components/snackbar-message/model/snackbar';
import { FilesRouteService } from 'app/modules/secondary-pages/files/files-route.service';
import { SharedState } from '@shared/state/shared/shared.state';
import { CatalogResponse, GeoData } from '@shared/models/catalog.service';
import { GeoDataAreaResponse, GeoDataCluster, GeoDataClusterParams, GeoDataDistrictResponse } from '@shared/models/geodata.service';
import { GeodataService } from '@shared/services/geodata.service';
import { AuthState } from '@auth/store/auth/auth.state';
import { UpdateCatalog } from '../../../data/modules/catalog-data/store/catalog-table.actions';
import { CatalogState } from '../../../data/store/catalog/catalog.state';

export type SharedStateModel = {
  selectCatalog?: CatalogView | { id: number; datasetId: number };
  catalogsInfo: CatalogInfo[];
  selectCatalogInfo?: CatalogInfo;
  geoData: CatalogGeoData[];
  clusterGeoData?: GeoDataCluster;
  geoDataArea?: GeoDataAreaResponse;
  geoDataDistrict?: GeoDataDistrictResponse;
  prevClusterParams?: GeoDataClusterParams;
};

@State<SharedStateModel>({
  name: 'sharedCatalogState',
  defaults: {
    geoData: [],
    clusterGeoData: undefined,
    catalogsInfo: [],
    selectCatalogInfo: undefined,
    selectCatalog: undefined,
    geoDataArea: undefined,
    geoDataDistrict: undefined,
    prevClusterParams: undefined
  }
})
@Injectable()
export class SharedCatalogState {
  constructor(
    private catalogService: CatalogService,
    private snackBar: SnackBarService,
    private filesRouteService: FilesRouteService,
    private store: Store,
    private geoDataService: GeodataService,
    private actions$: Actions
  ) {}

  @Selector()
  public static fields(state: SharedStateModel): CatalogItemFieldsVM[] | undefined {
    const catalogInfo = state.catalogsInfo.find(x => x.id === state?.selectCatalog?.id);
    return catalogInfo?.fields;
  }

  @Selector()
  public static geoData(state: SharedStateModel): GeoDataInfo[] | undefined {
    const catalogInfo = state.geoData.find(x => x.catalogId === state?.selectCatalog?.id);
    return catalogInfo?.geoData;
  }

  @Selector()
  public static clusterGeoData(state: SharedStateModel): GeoDataCluster | undefined {
    return state.clusterGeoData;
  }

  @Selector()
  public static selectCatalog(state: SharedStateModel): CatalogView {
    return state.selectCatalog as CatalogView;
  }

  @Selector()
  public static selectGeoDataArea(state: SharedStateModel): GeoDataAreaResponse | undefined {
    return state.geoDataArea;
  }

  @Selector()
  public static selectGeoDataDistrict(state: SharedStateModel): GeoDataDistrictResponse | undefined {
    return state.geoDataDistrict;
  }

  @Selector()
  public static catalogInfo(state: SharedStateModel): CatalogInfo | undefined {
    return state.catalogsInfo.find(x => x.id === state?.selectCatalog?.id);
  }

  @Selector()
  public static selectCatalogInfo(state: SharedStateModel): CatalogInfo | undefined {
    return state.selectCatalogInfo;
  }

  @Selector()
  public static selectCatalogTitle(state: SharedStateModel): string | undefined {
    const cachedCatalog = state.catalogsInfo.find(catalog => catalog.id === state.selectCatalog?.id);
    if (cachedCatalog) return cachedCatalog.name;
    return state.selectCatalogInfo?.name;
  }

  @Action(ChangedSelectCatalog)
  public changedSelectCatalog(ctx: StateContext<SharedStateModel>, action: ChangedSelectCatalog): void {
    ctx.patchState({
      selectCatalog: action.selectCatalog,
      selectCatalogInfo: ctx.getState().catalogsInfo.find(x => x.id === action.selectCatalog.id)
    });
  }

  @Action(GetSelectCatalog)
  public getSelectedCatalog(ctx: StateContext<SharedStateModel>, action: GetSelectCatalog): Observable<CatalogView | null> {
    const currentSelectedCatalog = ctx.getState().selectCatalog;

    if (currentSelectedCatalog && currentSelectedCatalog.id === action.id && 'catalogName' in currentSelectedCatalog) {
      return of(currentSelectedCatalog);
    }

    return this.catalogService
      .getCatalogs({
        idPublicCatalog: [action.id]
      })
      .pipe(
        map(res => res.response),
        map(catalogs => {
          const currentCatalogData = catalogs.find(x => x.idPublicCatalog === action.id);
          if (currentCatalogData) {
            const currentCatalog = convertCatalogResponseToView(currentCatalogData);
            ctx.patchState({
              selectCatalog: currentCatalog
            });
            return null;
          }
          return null;
        })
      );
  }

  @Action(SetSelectCatalog)
  public setSelectCatalog(
    ctx: StateContext<SharedStateModel>,
    action: SetSelectCatalog
  ): Observable<CatalogView | { id: number; datasetId: number }> {
    ctx.patchState({
      selectCatalog: action.catalog
    });
    return of(action.catalog);
  }

  @Action(GetCatalogInfo)
  public getCatalogInfo(ctx: StateContext<SharedStateModel>, action: GetCatalogInfo): Observable<CatalogInfo | undefined> {
    const catalogId = action.catalogId;
    if (!catalogId) {
      this.snackBar.open(MirSnackbarType.Error, [
        {
          translate: true,
          message: 'SNACK_BAR_MESSAGE.SERVICE_FAILURE_RESTART_PAGE'
        }
      ]);
      throw new Error('В SharedCatalogState ошибка в методе GetCatalogInfo!');
    }
    const currentState = ctx.getState();
    const currentCatalogsInfo = currentState.catalogsInfo;
    const existingCatalogInfo = currentCatalogsInfo.find(x => x.id === catalogId) as CatalogInfo;

    const catalogVersionReleaseInfo = this.store.selectSnapshot(CatalogState.currentCatalogVersionRelease);
    let params = {};
    if (catalogVersionReleaseInfo && catalogVersionReleaseInfo.catalogId === catalogId && catalogVersionReleaseInfo.versionReleaseInfo?.versionDate) {
      params = {
        epoch: catalogVersionReleaseInfo.versionReleaseInfo.versionDate,
        timestamp: 1
      };
    }
    if (!existingCatalogInfo || (existingCatalogInfo && existingCatalogInfo.epoch !== catalogVersionReleaseInfo?.versionReleaseInfo?.versionDate)) {
      let req = this.catalogService.getCatalogInfo(catalogId, params);
      if (action.isDynamicData) {
        req = this.catalogService.getDynamicCatalogInfo(catalogId, params);
      }
      return req.pipe(
        map(res => {
          let info = res.response.find(x => x.id === catalogId);
          if (catalogVersionReleaseInfo?.versionReleaseInfo?.versionDate) {
            info = { ...info, epoch: catalogVersionReleaseInfo.versionReleaseInfo.versionDate } as any;
          }
          if (info) {
            const newCatalogInfo = [...currentCatalogsInfo, info];
            ctx.patchState({
              catalogsInfo: newCatalogInfo,
              selectCatalogInfo: info
            });
          }
          return info;
        })
      );
    }
    return of(undefined);
  }

  @Action(GetCatalogGeoData, { cancelUncompleted: true })
  public getCatalogGeoData(ctx: StateContext<SharedStateModel>, params: GetCatalogGeoData): Observable<CatalogGeoData[]> {
    const catalogId = params.catalogId ?? ctx.getState().selectCatalog?.id;
    const isDynamic = (ctx.getState().selectCatalog as CatalogView)?.isDynamic;
    if (params.catalogId && params.datasetId) {
      ctx.patchState({ selectCatalog: { id: params.catalogId, datasetId: params.datasetId } });
    }

    if (!catalogId) {
      this.snackBar.open(MirSnackbarType.Error, [
        {
          translate: true,
          message: 'SNACK_BAR_MESSAGE.SERVICE_FAILURE_RESTART_PAGE'
        }
      ]);
      throw new Error('В SharedCatalogState ошибка в методе getCatalogGeoData!');
    }
    const currentGeoData = ctx.getState().geoData;
    const foundedGeoData = currentGeoData.find(x => x.catalogId === catalogId);
    /*    if (
      !isDynamic &&
      foundedGeoData &&
      params.criteria === foundedGeoData.criteria &&
      params.isRecommendationData === foundedGeoData.isRecommendationData &&
      params.nextToMeData.isNextMeData === foundedGeoData.nextMeData.isNextMeData &&
      params.nextToMeData.radius === foundedGeoData.nextMeData.radius &&
      params.nextToMeData.longitude === foundedGeoData.nextMeData.longitude &&
      params.nextToMeData.latitude === foundedGeoData.nextMeData.latitude
    ) {
      return of(currentGeoData);
    } */
    let criteria = params?.criteria ?? '';
    if (params.isRecommendationData) {
      const recommendedItemIds = this.store.selectSnapshot(SharedState.getRecommendationCatalogItemsForUser).call(this, catalogId);

      criteria = `${criteria === '' ? '' : `${criteria} and `}global_id in [${recommendedItemIds.toString()}]`;
      if (recommendedItemIds.length === 0) {
        if (foundedGeoData) {
          ctx.setState(
            patch({
              geoData: updateItem(
                item => (item as CatalogGeoData).catalogId === catalogId,
                patch({
                  geoData: [],
                  criteria: params.criteria,
                  isRecommendationData: params.isRecommendationData,
                  nextMeData: params.nextToMeData
                })
              )
            })
          );
        } else {
          const newCatalogGeoData: CatalogGeoData = {
            geoData: [],
            catalogId: catalogId,
            criteria: params.criteria,
            isRecommendationData: params.isRecommendationData,
            nextMeData: params.nextToMeData
          };

          const newGeoDataInfo = [...currentGeoData, newCatalogGeoData];
          ctx.patchState({
            geoData: newGeoDataInfo
          });
        }

        const user = this.store.selectSnapshot(AuthState.authenticatedUser);
        if (!params?.criteria && user?.token) {
          this.snackBar.open(MirSnackbarType.Info, [
            {
              translate: true,
              message: 'SNACK_BAR_MESSAGE.RECOMMENDED_NOT_FOUND'
            }
          ]);
        }

        return of([]);
      }
    }

    let geoData: GeoData | null = null;
    const catVer = this.store.selectSnapshot(CatalogState.currentCatalogVersionRelease);
    if (params.nextToMeData?.isNextMeData && params.nextToMeData.longitude && params.nextToMeData.latitude && params.nextToMeData.radius) {
      criteria = `${criteria === '' ? '' : `${criteria} and `}geoData near(p1, ${params.nextToMeData.radius}.0)`;
      geoData = {
        p1: {
          coordinates: [params.nextToMeData.longitude, params.nextToMeData.latitude],
          type: 'Point'
        }
      };
    }

    return this.catalogService
      .getGeoData(
        {
          id: catalogId,
          criteria: criteria,
          epoch: catVer?.versionReleaseInfo?.releaseDate,
          timestamp: 1
        },
        isDynamic,
        geoData
      )
      .pipe(
        map(res => {
          const geoData: GeoDataInfo[] =
            res.response?.map(x => ({
              catalogItemId: x.global_id,
              allCoordinates: x.geoData
            })) ?? [];

          if (!foundedGeoData) {
            const newCatalogGeoData: CatalogGeoData = {
              geoData: geoData,
              catalogId: catalogId,
              criteria: params.criteria,
              isRecommendationData: params.isRecommendationData,
              nextMeData: params.nextToMeData
            };

            const newGeoDataInfo = [...currentGeoData, newCatalogGeoData];
            ctx.patchState({
              geoData: newGeoDataInfo
            });

            return newGeoDataInfo;
          }
          ctx.setState(
            patch({
              geoData: updateItem(
                item => (item as CatalogGeoData).catalogId === catalogId,
                patch({
                  geoData: geoData,
                  criteria: params.criteria,
                  isRecommendationData: params.isRecommendationData,
                  nextMeData: params.nextToMeData
                })
              )
            })
          );

          return [];
        })
      );
  }

  @Action(SaveCatalogToStorage)
  public saveCatalogToStorage(ctx: StateContext<SharedStateModel>): void {
    const currentSelectedCatalog = ctx.getState().selectCatalog;
    const currentSelectedCatalogInfo = ctx.getState().selectCatalogInfo;

    if (currentSelectedCatalog && 'idNumber' in currentSelectedCatalog && currentSelectedCatalogInfo) {
      this.filesRouteService.saveCatalogInStorage(currentSelectedCatalog.idNumber, currentSelectedCatalogInfo);
    }
  }

  @Action(RefreshCurrentCatalogStats)
  public refreshCurrentCatalogStats(ctx: StateContext<SharedStateModel>): Observable<CatalogResponse | null> {
    const catalog = ctx.getState().selectCatalog;

    if (catalog) {
      return this.catalogService.getCatalogs({ idPublicCatalog: [catalog.id] }).pipe(
        tap(res => {
          const rating = res.response[0].dataset?.stat?.rating;
          const numberVotes = res.response[0].dataset?.stat?.numberVotes;
          const numberView = res.response[0].dataset?.stat?.numberView;
          const numberDownloads = res.response[0].dataset?.stat.numberDownloads;
          const newCatalog = { ...catalog, rating, numberVotes, numberDownloads, numberView };
          ctx.patchState({
            selectCatalog: newCatalog
          });
          ctx.dispatch(new UpdateCatalog(newCatalog as CatalogView));
        })
      );
    }
    return of(null);
  }

  @Action(GetGeoDataDistrict)
  public getGeoDataDistrict(ctx: StateContext<SharedStateModel>): Observable<GeoDataDistrictResponse> {
    const currentGeoDataDistrict = ctx.getState().geoDataDistrict;
    if (currentGeoDataDistrict) {
      return of(currentGeoDataDistrict);
    }

    return this.geoDataService.getAllCatalogDistrict().pipe(
      tap(res => {
        ctx.patchState({
          geoDataDistrict: res
        });
      })
    );
  }

  @Action(GetGeoDataArea)
  public GetGeoDataArea(ctx: StateContext<SharedStateModel>): Observable<GeoDataAreaResponse> {
    const currentGeoDataArea = ctx.getState().geoDataArea;
    if (currentGeoDataArea) {
      return of(currentGeoDataArea);
    }

    return this.geoDataService.getAllCatalogArea().pipe(
      tap(res => {
        ctx.patchState({
          geoDataArea: res
        });
      })
    );
  }

  @Action(GetGeoDataCluster)
  public GetGeoDataCluster(ctx: StateContext<SharedStateModel>, params: GetGeoDataCluster): Observable<GeoDataCluster | null> {
    const state = ctx.getState();
    if (
      !params.params.changeData &&
      state.prevClusterParams &&
      state.prevClusterParams.zoom === params.params.zoom &&
      state.prevClusterParams.idDataset === params.params.idDataset &&
      state.prevClusterParams.coordinates.southEast[0] === params.params.coordinates.southEast[0] &&
      state.prevClusterParams.coordinates.southWest[0] === params.params.coordinates.southWest[0] &&
      state.prevClusterParams.coordinates.northWest[0] === params.params.coordinates.northWest[0] &&
      state.prevClusterParams.coordinates.northEast[0] === params.params.coordinates.northEast[0]
    ) {
      return of(null);
    }

    ctx.patchState({
      prevClusterParams: params.params
    });

    return this.geoDataService.getAllCatalogCluster(params.params).pipe(
      map(res => {
        ctx.patchState({
          clusterGeoData: res.response
        });

        return res.response;
      }),
      takeUntil(this.actions$.pipe(ofAction(CancelGetGeoDataCluster)))
    );
  }
}

<div class="container">
  <div class="title">
    <span class="h3">{{ 'SNACK_BAR_MESSAGE.WARNING' | translate }}</span>
    <button class="btn-empty" (click)="cancel()">
      <svg [icon]="icons.Close" class="dark-gray-color svg24 close-icon" />
    </button>
  </div>

  <div class="body">
    <p class="body-2">{{ 'SNACK_BAR_MESSAGE.FORM_DONT_SAVE' | translate }}!</p>
  </div>

  <div class="footer">
    <button (click)="ok()" class="btn-primary medium">
      <span *ngIf="user">{{ 'OTHER.EXIT' | translate }}</span>
      <span *ngIf="!user">{{ 'OTHER.ENTER' | translate }}</span>
    </button>
    <button (click)="cancel()" class="btn-secondary medium">
      <span>{{ 'OTHER.CANCEL' | translate }}</span>
    </button>
  </div>
</div>

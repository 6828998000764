import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgModule } from '@shared/components/svg/svg.module';
import { HoverableSvgComponent } from './hoverable-svg.component';

@NgModule({
  declarations: [HoverableSvgComponent],
  imports: [CommonModule, SvgModule],
  exports: [HoverableSvgComponent]
})
export class HoverableSvgModule {}

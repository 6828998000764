import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SocialShareComponent } from '@shared/components/social-share/social-share.component';
import { SvgModule } from '@shared/components/svg/svg.module';

@NgModule({
  declarations: [SocialShareComponent],
  imports: [CommonModule, SvgModule],
  exports: [SocialShareComponent]
})
export class SocialShareModule {}

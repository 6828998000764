import { MirSnackbarType, SnackbarVariation } from '@shared/components/snackbar-message/model/snackbar';
import { CustomIcons } from '@shared/models/custom-icons';

export const snackbarVariations: SnackbarVariation = {
  [MirSnackbarType.Notification]: {
    color: 'var(--primary-60-color)',
    message: '',
    icon: CustomIcons.Notifications,
    horizontalPosition: 'end',
    verticalPosition: 'top'
  },
  [MirSnackbarType.Info]: {
    color: 'var(--secondary-60-color)',
    message: '',
    icon: CustomIcons.SnackBarInfo,
    horizontalPosition: 'start',
    verticalPosition: 'bottom'
  },
  [MirSnackbarType.Error]: {
    color: 'var(--negative-color)',
    message: '',
    icon: CustomIcons.Error,
    horizontalPosition: 'start',
    verticalPosition: 'bottom'
  },
  [MirSnackbarType.Warning]: {
    color: 'var(--neutral-color)',
    message: '',
    icon: CustomIcons.Warning,
    horizontalPosition: 'start',
    verticalPosition: 'bottom'
  },
  [MirSnackbarType.Success]: {
    color: 'var(--positive-color)',
    message: '',
    icon: CustomIcons.Success,
    horizontalPosition: 'start',
    verticalPosition: 'bottom'
  }
};

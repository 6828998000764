import { NgModule, PLATFORM_ID, APP_ID, Inject, LOCALE_ID, ɵɵinject, ErrorHandler, TransferState } from '@angular/core';
import { BrowserModule, HammerModule, provideClientHydration } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { DOCUMENT, isPlatformBrowser, registerLocaleData, ViewportScroller } from '@angular/common';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxsActionsExecutingModule } from '@ngxs-labs/actions-executing';
import { MissingTranslationService } from '@utils/missing-translation-service';
import { CoreModule } from '@core/core.module';
import { LayoutModule } from '@layout/layout.module';
import { AuthModule } from '@auth/auth.module';
import { NgxsModule } from '@ngxs/store';
import { AuthState } from '@auth/store/auth/auth.state';
import { ngxsConfig } from '@shared/models/ngxsConfig';
import { SharedState } from '@shared/state/shared/shared.state';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { SharedCatalogState } from '@shared/state/catalog/shared-catalog.state';
import { SnackbarMessageModule } from '@shared/components/snackbar-message/snackbar-message.module';
import { translateBrowserLoaderFactory } from '@utils/translateLoaders/translate-browser-loader';
import { CatalogState } from 'app/modules/data/store/catalog/catalog.state';
import localeRu from '@angular/common/locales/ru';
import localeEn from '@angular/common/locales/en';
import { MatNativeDateModule } from '@angular/material/core';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { CustomViewportScroller } from '@utils/custom-viewport-scroller';
import { CustomUrlSerializer } from '@utils/url-serializer';
import { UrlSerializer } from '@angular/router';
import { DocumentTableState } from '@shared/state/documents/document.state';
import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { TranslateService } from '@shared/services/translate.service';
import { InaccessibilityModule } from '@shared/components/inaccessibility/inaccessibility.module';
import { AppRoutingModule } from './app-routing.module';
import { CatalogItemsState } from './modules/data/modules/catalog-items-data/store/catalog-items-state';
import { AppComponent } from './app.component';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from "@angular/material/tooltip";

registerLocaleData(localeRu, 'ru');
registerLocaleData(localeEn, 'en');

export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 0,
  hideDelay: 0,
  touchendHideDelay: 1500,
  disableTooltipInteractivity: true,
};
@NgModule({
  imports: [
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'opod' }),
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    CoreModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateBrowserLoaderFactory,
        deps: [HttpClient, TransferState]
      },
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MissingTranslationService },
      useDefaultLang: false
    }),
    AuthModule,
    NgxsModule.forRoot([SharedState, SharedCatalogState, AuthState, CatalogState, CatalogItemsState, DocumentTableState], ngxsConfig),
    NgxsActionsExecutingModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot({
      key: ['auth']
    }),
    LayoutModule,
    SnackbarMessageModule,
    HammerModule,
    MatNativeDateModule,
    CdkScrollableModule,
    InaccessibilityModule
  ],
  providers: [
    provideClientHydration(),
    {
      provide: LOCALE_ID,
      useValue: 'ru'
    },
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: myCustomTooltipDefaults,
    },
    { provide: UrlSerializer, useClass: CustomUrlSerializer },
    {
      provide: ViewportScroller,
      useFactory: () => new CustomViewportScroller(ɵɵinject(DOCUMENT), window, ɵɵinject(ErrorHandler))
    },
    TranslateService,
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent]
})
export class AppModule {
  // eslint-disable-next-line @typescript-eslint/ban-types
  constructor(@Inject(PLATFORM_ID) private platformId: Object, @Inject(APP_ID) private appId: string) {
    const platform = isPlatformBrowser(platformId) ? 'in the browser' : 'on the server';
    console.info(`Running ${platform} with appId=${appId}`);
  }
}

import { CatalogItemValue, CatalogVersionRelease, FileType } from '../../models/catalog';

export class SelectCatalogItemShared {
  public static readonly type = '[SelectCatalogItem] Select catalog item';
  constructor(public catalogItem?: CatalogItemValue | null) {}
}

export class GetSelectCatalogItem {
  public static readonly type = '[GetSelectCatalogItem] Get Select';

  constructor(
    public globalId: number,
    public catalogId: number,
    public isDynamic: boolean,
    public isFromMain: boolean = false,
    public isDatasetId: boolean = false
  ) {}
}

export class GetAllCatalogVersion {
  public static readonly type = '[GetCatalogVersion] Get Catalog Version';
  constructor(public catalogId: number, public hasIdDataset: boolean, public accessKey: string | null = null) {}
}

export class GetCurrentCatalogVersionRelease {
  public static readonly type = '[GetLoading]  CatalogVersionRelease';
  constructor(
    public catalogId: number,
    public hasIdDataset: boolean,
    public accessKey: string | null,
    public version: number | null,
    public release: number | null
  ) {}
}

export class SetCurrentCatalogVersionRelease {
  public static readonly type = '[Set] SetCatalogVersionRelease';
  constructor(public catalogId: number, public version: CatalogVersionRelease) {}
}

export class GetCatalogsExportStatus {
  public static readonly type = '[GetCatalogsExportStatus] GetCatalogs ExportStatus';
  constructor(public catalogsIds: number[], public versionDate = '') {}
}

export class CreateCatalogsFile {
  public static readonly type = '[CreateFileType] Create FileType';
  constructor(public catalogId: number, public format: FileType, public version: string | null = null) {}
}

import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServerLinks } from '@shared/services/server-links';
import { ServerLinkName } from '@shared/models/server-links-model';

export interface INotifyUser {
  message: string;
}
@Injectable({
  providedIn: 'root'
})
export class UserNotifyService {
  private _urlLkApi = '';
  constructor(private http: HttpClient, serverLinks: ServerLinks) {
    this._urlLkApi = serverLinks.getLink(ServerLinkName.UserPushNotify);
  }
  public notifyUser(requestOptions: { 'User-Token': string; 'Connection-Uid': string }): Observable<INotifyUser> {
    const url = `${this._urlLkApi}greeting`;
    return this.http.get<INotifyUser>(url, {
      headers: new HttpHeaders(requestOptions)
    });
  }
}

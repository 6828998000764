import { Locale } from '@shared/models/locale';
import { Stend } from '@shared/models/Stend';

export const environment = {
  production: true,
  stend: Stend.Prod,
  siteUrl: 'https://data.mos.ru',
  locales: [Locale.EN_US, Locale.RU_RU],
  defaultLocale: Locale.RU_RU
};

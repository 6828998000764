import { UserInfo } from '@auth/models/auth.state';

export class Login {
  public static readonly type = '[Auth] Login';
  constructor(public code: string) {}
}

export class LoginLocalStorage {
  public static readonly type = '[Auth] LoginLocalStorage';
  constructor(public userInfo: UserInfo | null) {}
}

export class CloseSite {
  public static readonly type = '[CloseSite] CloseSite';
}

export class LoginMosRu {
  public static readonly type = '[Auth] LoginMosRu';
}

export class RegistrationMosRu {
  public static readonly type = '[Auth] RegistrationMosRu';
}

export class Logout {
  public static readonly type = '[Auth] Logout';
  constructor(public logoutSydir = false, public saveGeoData = true) {}
}

import { CatalogResponseInfo } from '@shared/models/catalog.service';
import { CatalogItemFieldsVM, CatalogView } from '@shared/models/shared-catalog.state';
import { DateTime } from 'luxon';
import { CatalogDynamicColumns } from '../../data/modules/catalog-items-data/models/catalog-item-component';

export const convertCatalogResponseToView = (catalog: CatalogResponseInfo): CatalogView => ({
  history: catalog.dataset.history,
  actualReleaseNum: +catalog.dataset.actualReleaseNum,
  actualVersionNum: +catalog.dataset.actualVersionNum,
  actualVersion: DateTime.fromJSDate(new Date(catalog.dataset.actualVersion)).toFormat('yyyy-MM-dd HH:mm:ss'),
  actualVersionPassport: DateTime.fromJSDate(new Date(catalog.dataset.actualVersion)).toFormat('yyyyMMddТHHmm'),
  actualRelease: DateTime.fromJSDate(new Date(catalog.dataset.actualRelease)).toFormat('yyyy-MM-dd HH:mm:ss'),
  actualReleasePassport: catalog.dataset?.actualRelease
    ? DateTime.fromFormat(catalog.dataset.actualRelease.substring(0, 10), 'yyyy-MM-dd').toFormat('dd.MM.yyyy')
    : '',
  datasetId: catalog.dataset.id,
  id: catalog.idPublicCatalog,
  idNumber: catalog.idNumber,
  archive: catalog.publicCatalogStatus === 'Архивный',
  categoryName: catalog.nameOpenCategory,
  categoryNameRu: catalog.nameOpenCategory,
  categoryNameEn: catalog.nameOpenCategoryEn ?? catalog.nameOpenCategory,
  categoryId: catalog.idOpenCategory,
  imageCode: null,
  // layerId: catalog.layerId,
  hasGeo: catalog.hasGeo,
  rating: catalog?.dataset?.stat?.rating ?? 0,
  catalogName: catalog.namePublicCatalog,
  catalogNameRu: catalog.namePublicCatalog,
  catalogNameEn: catalog.namePublicCatalogEn ?? catalog.namePublicCatalog,
  numberDownloads: catalog?.dataset?.stat?.numberDownloads ?? 0,
  numberView: catalog?.dataset?.stat?.numberView ?? 0,
  relevance: catalog?.dataset?.actualRelease
    ? DateTime.fromFormat(catalog.dataset.actualRelease.substring(0, 10), 'yyyy-MM-dd').toFormat('dd.MM.yyyy')
    : '',
  countRows: catalog.dataset.countRows,
  cluster: catalog.cluster,
  publicCatalogStatus: catalog.publicCatalogStatus,
  linkForum: catalog.linkForum ?? catalog?.linkForum,
  notificationFavoriteIcon: null,
  nameOIV: catalog.nameOIV,
  nameOIVEn: catalog.nameOIVEn,
  idOIV: catalog.idOIV,
  isDynamic: catalog.isDynamic,
  firstPublication: catalog.dataset?.firstPublication
    ? DateTime.fromFormat(catalog.dataset.firstPublication.substring(0, 10), 'yyyy-MM-dd').toFormat('dd.MM.yyyy')
    : '',
  numberVotes: catalog?.dataset.stat?.numberVotes,
  validDate: catalog?.validDate ? DateTime.fromFormat(catalog?.validDate.substring(0, 10), 'yyyy-MM-dd').toFormat('dd.MM.yyyy') : '',
  defaultValidDate: catalog?.validDate ? DateTime.fromJSDate(new Date(catalog?.validDate.substring(0, 16))).toFormat('yyyyMMddТHHmm') : ''
});

export const convertCatalogItemFieldsVMToView = (columns: CatalogItemFieldsVM[]): CatalogDynamicColumns[] => {
  const result: CatalogDynamicColumns[] = [];
  const regex = /( |<([^>]+)>)/gi;
  columns.forEach(column => {
    result.push({
      name: column.techName,
      title: column.name,
      show: !!column?.isDefaultSystems?.find(x => x === 'opod'),
      type: column.type,
      catalogColumns: column.specification ? convertCatalogItemFieldsVMToView(column.specification.fields) : undefined,
      order: column.order,
      dictionaryId: column?.dictionaryId,
      imageInfo: null,
      systemField: column.systemField,
      system: column.system,
      isPk: column?.isPk,
      catalogId: column.catalogId,
      calculationWidth: column.name.replace(regex, '').length
    });
  });

  return result.sort((a, b) => (a.order > b.order ? 1 : -1));
};

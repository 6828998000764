export enum ServerLinkName {
  EhdApi,
  Ehdws,
  File,
  OpenData,
  DownloadFile,
  Media,
  Lk,
  DynApi,
  VkData,
  GlobalSearch,
  ApiData,
  OldOpod,
  Login,
  ApiKey,
  VkGroupLink,
  VkForumLink,
  VkBoardLink,
  VkGroupLinkWall,
  ApiLink,
  CreateAppMail,
  UserPushNotify
}

export type ServerLinksVariables = {
  prod: string;
  dev: string;
  stage: string;
};

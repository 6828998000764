import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SnackbarMessageComponent } from '@shared/components/snackbar-message/component/snackbar-message/snackbar-message.component';
import { SvgModule } from '@shared/components/svg/svg.module';
import { MAT_SNACK_BAR_DATA, MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
    declarations: [SnackbarMessageComponent],
    imports: [CommonModule, SvgModule, MatSnackBarModule],
    exports: [SnackbarMessageComponent],
    providers: [{ provide: MAT_SNACK_BAR_DATA, useValue: {} }]
})
export class SnackbarMessageModule {}

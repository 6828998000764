import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CustomIcons } from '@shared/models/custom-icons';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'svg[icon]',
  template: '<svg:use [attr.href]="href"></svg:use>',
  styles: [':host { fill: transparent; stroke: transparent;}'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SvgIconComponent {
  @Input() public icon: CustomIcons | undefined;

  public get href(): string {
    return `#${this.icon}`;
  }
}

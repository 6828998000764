import { Component, ChangeDetectionStrategy, Inject, PLATFORM_ID } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CustomIcons } from '@shared/models/custom-icons';
import { isPlatformBrowser } from '@angular/common';
import { AfterAuthorizationOperation } from '@shared/models/catalog.service';
import { LoginMosRu, RegistrationMosRu } from '@auth/store/auth/auth.action';
import { Store } from '@ngxs/store';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-authorization-dialog',
  templateUrl: './authorization-dialog.component.html',
  styleUrls: ['./authorization-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthorizationDialogComponent {
  public icons = CustomIcons;
  public keyOperations: AfterAuthorizationOperation;
  public operationValue: any;
  constructor(
    private dialogRef: MatDialogRef<AuthorizationDialogComponent>,
    private authService: AuthService,
    @Inject(PLATFORM_ID) private platformId: string,
    private store: Store
  ) {}

  private addSessionStorage(): void {
    if (isPlatformBrowser(this.platformId) && this.keyOperations) {
      localStorage.setItem(this.keyOperations, JSON.stringify(this.operationValue));
    }
  }

  public close(): void {
    this.dialogRef.close(false);
  }

  public loginMosRu(): void {
    this.addSessionStorage();
    this.dialogRef.close();
    this.store.dispatch(new LoginMosRu());
  }

  public registrationMosRu(): void {
    this.addSessionStorage();
    this.dialogRef.close();
    this.store.dispatch(new RegistrationMosRu());
  }
}

import { Subject } from 'rxjs';

export interface IDestroy {
  componentDestroy: () => Subject<any>;
}
// используя этот декоратор , всегда реализуй интерфейс выше
export function TakeUntilDestroy(constructor: any): void {
  const originalDestroy = constructor.prototype.ngOnDestroy;

  constructor.prototype.componentDestroy = function (): Subject<any> {
    this._takeUntilDestroy$ = this._takeUntilDestroy$ || new Subject();
    return this._takeUntilDestroy$;
  };

  constructor.prototype.ngOnDestroy = function (...args: any): void {
    if (this._takeUntilDestroy$) {
      this._takeUntilDestroy$.next();
      this._takeUntilDestroy$.complete();
    }

    if (typeof originalDestroy === 'function') {
      originalDestroy.apply(this, args);
    }
  };
}

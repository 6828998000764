import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilesComponent } from 'app/modules/secondary-pages/files/files.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [FilesComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: ':catalogId/:catalogName/:filename',
        component: FilesComponent,
        pathMatch: 'full'
      }
    ])
  ]
})
export class FilesModule {}

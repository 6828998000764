import { ServerTime } from '@utils/server-time';
import { DateTime } from 'luxon';
import { Locale } from '@shared/models/locale';
import { CatalogFilter, FilterName } from '../../data/modules/catalog-data/models/catalog.service';
import { CatalogStatus, Seasons } from '../../data/modules/catalog-data/store/catalog-table.state';

export const getStringFilter = (filter: CatalogFilter | null, locale: Locale): string => {
  let filtersArray: string[] = [];
  const seasons: Seasons[] = [];
  const catalogStatus: CatalogStatus[] = [];
  if (filter) {
    const flatFilter = { ...filter, ...filter.booleanFilters };

    if (!flatFilter[FilterName.ActiveStatus] && !flatFilter[FilterName.ArchiveStatus]) {
      filtersArray.push(`publicCatalogStatus in (${CatalogStatus.active})`);
    }

    Object.keys(flatFilter).forEach(key => {
      if (flatFilter[key] || key === FilterName.DataSetType) {
        switch (key) {
          case FilterName.Provider:
            if (filter.providerIds && filter.providerIds.length > 0) {
              const providersFilter = `idOiv in (${filter.providerIds.toString()})`;
              filtersArray.push(providersFilter);
            }
            break;
          case FilterName.SearchString:
            if (locale === Locale.EN_US) {
              filtersArray.push(`namePublicCatalogEn ilike '%${filter.searchString}%'`);
            } else {
              filtersArray.push(`namePublicCatalog ilike '%${filter.searchString}%'`);
            }

            break;
          case FilterName.OpenCategory:
            if (filter.openCategoryIds && filter.openCategoryIds.length > 0) {
              const categoriesFilter = `openCategoryId in (${filter.openCategoryIds.toString()})`;
              filtersArray.push(categoriesFilter);
            }
            break;
          case FilterName.DataSetType:
            if (filter.booleanFilters?.datasetType === 'dynamic') {
              filtersArray.push(`isDynamic = true`);
            } else if (filter.booleanFilters?.datasetType !== null) {
              filtersArray.push(`isDict = ${filter.booleanFilters?.datasetType}`);
            }
            break;
          case FilterName.ActiveStatus:
            catalogStatus.push(CatalogStatus.active);
            break;
          case FilterName.ArchiveStatus:
            catalogStatus.push(CatalogStatus.archive);
            break;
          case FilterName.Accessibility:
            filtersArray.push(`accessibility = true`);
            break;
          case FilterName.SummerSeason:
            seasons.push(Seasons.summer);
            break;
          case FilterName.AllSeasons:
            seasons.push(Seasons.all);
            break;
          case FilterName.WinterSeason:
            seasons.push(Seasons.winter);
            break;
          case FilterName.WithMap:
            filtersArray.push(`hasGeo = true`);
            break;
          case FilterName.WithoutMap:
            filtersArray.push(`hasGeo = false`);
            break;
          case FilterName.Iso:
            filtersArray.push(`iso = true`);
            break;
          case FilterName.OrdinaryDict:
            filtersArray.push(`typeDict = 'Обычный'`);
            break;
          case FilterName.RegionalDict:
            filtersArray.push(`typeDict = 'Региональный'`);
            break;
          case FilterName.FederalDict:
            filtersArray.push(`typeDict = 'Федеральный'`);
            break;
          case FilterName.NewRecommendedFavorites:
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (flatFilter[FilterName.NewRecommendedFavorites] === 'new' || flatFilter[FilterName.NewRecommendedFavorites] === true) {
              const serverTime = new ServerTime(DateTime.now().toISOTime()).serverTimeMonthAgoInServerFormat();
              filtersArray.push(`firstPublication >= '${serverTime}'`);
            }

            break;
          default:
            break;
        }
      }
    });
  }
  if (seasons.length > 0) {
    filtersArray.push(`seasonId in(${seasons.map(x => `'${x}'`).join(',')})`);
  }

  if (catalogStatus.length > 0) {
    filtersArray.push(`publicCatalogStatus in(${catalogStatus.join(',')})`);
  }
  const typeDict = filtersArray.filter(x => x.includes('typeDict'));
  if (typeDict.length > 1) {
    filtersArray = filtersArray.filter(x => !x.includes('typeDict'));

    return `${filtersArray.join(' and ')} and ${typeDict.join(' or ')}`;
  }

  return filtersArray.join(' and ');
};

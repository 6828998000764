import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ServerLinks } from '@shared/services/server-links';
import { ServerLinkName } from '@shared/models/server-links-model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  DocumentTableView,
  DocumentType,
  GetDocumentParams,
  GetDocumentResponse,
  GetDocumentTypeOptionsResponse
} from 'app/modules/secondary-pages/documents/models/document';
import { MultiSelectOption } from '@shared/components/multi-select/models';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  private _urlEhdapi = '';
  private _downloadFileUrl = '';

  private readonly _httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'ehd-system': 'opod'
  });

  constructor(private http: HttpClient, serverLinks: ServerLinks) {
    this._urlEhdapi = serverLinks.getLink(ServerLinkName.EhdApi);
    this._downloadFileUrl = serverLinks.getLink(ServerLinkName.Media);
  }

  public getDocuments(params: GetDocumentParams): Observable<DocumentTableView[]> {
    return this.http
      .post<GetDocumentResponse>(`${this._urlEhdapi}catalog/get`, params, {
        headers: this._httpHeaders
      })
      .pipe(
        map(res =>
          res.response.map(d => ({
            documentName: d.Name,
            documentType: d.TypeDoc,
            influenceScope: d.SphereDoc,
            archive: d.is_Archive,
            fileId: d.File
          }))
        )
      );
  }

  public getDocumentTypes(params: { id: number }): Observable<MultiSelectOption[]> {
    return this.http
      .post<GetDocumentTypeOptionsResponse>(`${this._urlEhdapi}dictionary/get`, params, {
        headers: this._httpHeaders
      })
      .pipe(
        map(res =>
          res?.response?.map((val: DocumentType) => ({
            title: val.value,
            value: val.value,
            isSelected: true,
            engValue: val.value_en
          }))
        )
      );
  }
  public downloadDocumentFile(fileId: string): Observable<ArrayBuffer> {
    return this.http.get<ArrayBuffer>(`${this._downloadFileUrl}getFile?id=${fileId}`, {
      responseType: 'arraybuffer' as 'json'
    });
  }
}

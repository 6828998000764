export type Providers = {
  idOIV: number;
  fullNameOIV: string;
  openCatalogCount: number;
};

export enum FilterName {
  SearchString = 'searchString',
  OpenCategory = 'openCategoryIds',
  Provider = 'providerIds',
  BooleanFilters = 'booleanFilters',
  DataSetType = 'datasetType',
  ActiveStatus = 'activeStatus',
  ArchiveStatus = 'archiveStatus',
  Accessibility = 'accessibility',
  SummerSeason = 'summerSeason',
  WinterSeason = 'winterSeason',
  AllSeasons = 'allSeasons',
  WithMap = 'withMap',
  WithoutMap = 'withoutMap',
  Iso = 'iso',
  OrdinaryDict = 'ordinaryDict',
  RegionalDict = 'regionalDict',
  FederalDict = 'federalDict',
  CatalogIds = 'catalogIds',
  NewRecommendedFavorites = 'newRecommendedFavorites',
  New = 'new'
}

export type BooleanCatalogFilters = {
  datasetType?: boolean | null | 'dynamic';
  activeStatus?: boolean | null;
  archiveStatus?: boolean | null;
  accessibility?: boolean | null;
  summerSeason?: boolean | null;
  winterSeason?: boolean | null;
  allSeasons?: boolean | null;
  withMap?: boolean | null;
  withoutMap?: boolean | null;
  iso?: boolean | null;
  ordinaryDict?: boolean | null;
  regionalDict?: boolean | null;
  federalDict?: boolean | null;
};

export type HandleCatalogHeaderToggleModel = 'all' | 'new' | 'recommended' | 'favorites';

export type CatalogFilter = {
  searchString?: string;
  openCategoryIds?: number[];
  providerIds?: number[];
  booleanFilters?: BooleanCatalogFilters;
  catalogIds?: number[];
  newRecommendedFavorites?: HandleCatalogHeaderToggleModel;
};

export type CatalogQueryParams = {
  searchString?: string;
  openCategoryIds?: string;
  providerIds?: string;
  sorting?: string;
  newRecommendedFavorites?: HandleCatalogHeaderToggleModel;
} & BooleanCatalogFilters;

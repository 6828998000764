const insertString = function (startIndex: number, currentIndex: number, str: string, searchString: string): string {
  const startSearch = startIndex !== 0 ? str.substring(0, currentIndex) : str.substring(startIndex, currentIndex);

  return `${startSearch}<span style="background: #CFDFF6">${str.slice(currentIndex, currentIndex + searchString.length)}</span>${str.substring(
    currentIndex + searchString.length,
    str.length
  )}`;
};

export const searchStringAddBackground = (str: string, searchString: string, startIndex = 0): string => {
  const foundIndex = str.toLowerCase().indexOf(searchString.toLowerCase(), startIndex === 0 ? 0 : startIndex + 41); // 41 это количество символов в span

  if (foundIndex === -1) {
    return str;
  }

  if (foundIndex > 300) {
    let newString = insertString(foundIndex, foundIndex, str, searchString);

    if (startIndex === 0) {
      newString = insertString(0, 0, str.substring(foundIndex), searchString);
    }

    return searchStringAddBackground(newString, searchString, foundIndex);
  }
  const newString = insertString(startIndex, foundIndex, str, searchString);

  if (foundIndex === 0) {
    return newString;
  }

  return searchStringAddBackground(newString, searchString, foundIndex);
};

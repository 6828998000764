<div class="container">
  <div class="title">
    <span class="h3">{{ 'AUTHORIZATION.HEADER' | translate }}</span>
 <!--   <button class="btn-empty" (click)="close()">
      <svg [icon]="icons.Close" class="dark-gray-color svg24 close-icon" />
    </button>-->
  </div>

  <div class="body">
    <p>{{ 'AUTHORIZATION.RE_AUTHORIZATION_HEADER' | translate }}!</p>
  </div>

  <div class="footer">
    <button (click)="reAuthorization()" class="entry">
      <span class="link-3 text ">{{ 'AUTHORIZATION.RE_AUTHORIZATION' | translate }}</span>
    </button>
    <button (click)="logout()" class="register">
      <span class="link-3 text">{{ 'OTHER.EXIT' | translate }}</span>
    </button>
  </div>
</div>

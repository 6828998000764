import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { MirSnackbarType } from '@shared/components/snackbar-message/model/snackbar';
import { SnackBarService } from '@shared/components/snackbar-message/services/snackbar.service';
import { DocumentFilters, DocumentTableView } from 'app/modules/secondary-pages/documents/models/document';
import { MultiSelectOption, SelectOption } from '@shared/components/multi-select/models';
import { DocumentService } from '@shared/services/document.service';
import { environment } from '@environments/environment';
import { Stend } from '@shared/models/Stend';
import { cloneDeep } from 'lodash';
import {
  DropDocumentsFilterState,
  FilterDocuments,
  GetDocuments,
  GetDocumentTypes,
  SetDocumentFilters,
  SetSearchPhrase,
  SetSorting
} from './document.actions';
import { searchStringAddBackground } from '../../../search/components/utils';
import { ImpairedSorting, OrderingTypeValue } from '../../../version-for-visually-impaired/modules/data/models/data-table';

type DocumentStateModel = {
  documents: DocumentTableView[];
  filteredDocuments: DocumentTableView[];
  criteria: string;
  documentTypeOptions: MultiSelectOption[];
  searchPhrase: string | null;
  filters: DocumentFilters;
  error: boolean;
  sorting: ImpairedSorting;
};

const initialStateValue: DocumentStateModel = {
  documents: [],
  filteredDocuments: [],
  criteria: `is_deleted=0`,
  documentTypeOptions: [],
  searchPhrase: null,
  filters: {
    documentTypes: [],
    documentsSphere: null
  },
  sorting: {
    type: 'documentName',
    value: 'ASC' as OrderingTypeValue
  },
  error: false
};

@State<DocumentStateModel>({
  name: 'documentTable',
  defaults: { ...initialStateValue }
})
@Injectable()
export class DocumentTableState {
  private _catalogId: number;
  private _dictId: number;

  constructor(private documentService: DocumentService, private snackBar: SnackBarService) {
    switch (environment.stend) {
      case Stend.Dev:
        this._catalogId = 193047;
        this._dictId = 2672;
        break;
      case Stend.Prod:
        this._catalogId = 118003;
        this._dictId = 2912;
        break;
      case Stend.Stage:
        this._catalogId = 6347;
        this._dictId = 2484;
        break;
      default:
        this._catalogId = 192819;
        this._dictId = 2672;
    }
  }

  /* this._catalogId = 193047;
    this._dictId = 2672;
  } */

  private filterDocumentsByPhrase(documents: DocumentTableView[], searchPhrase: string | null): DocumentTableView[] {
    if (searchPhrase) {
      return documents
        ?.filter(document => document.documentName.toLowerCase().includes(searchPhrase.toLowerCase()))
        .map(res => {
          const documentName = searchStringAddBackground(res.documentName, searchPhrase);
          return {
            ...res,
            documentName
          };
        });
    }

    return documents;
  }

  @Selector()
  public static getDocuments(state: DocumentStateModel): DocumentTableView[] | undefined {
    return state.documents;
  }

  @Selector()
  public static isError(state: DocumentStateModel): boolean {
    return state.error;
  }

  @Selector()
  public static sorting(state: DocumentStateModel): ImpairedSorting {
    return state.sorting;
  }

  @Selector()
  public static getFilteredDocuments(state: DocumentStateModel): DocumentTableView[] | undefined {
    return state.filteredDocuments;
  }

  @Selector()
  public static getDocumentTypeOptions(state: DocumentStateModel): MultiSelectOption[] {
    return state.documentTypeOptions;
  }

  @Selector()
  public static getDocumentFilters(state: DocumentStateModel): DocumentFilters | null {
    return state.filters;
  }

  @Selector()
  public static getSearchPhrase(state: DocumentStateModel): string | null {
    return state.searchPhrase;
  }

  @Action(GetDocuments)
  public getDocuments(ctx: StateContext<DocumentStateModel>): Observable<DocumentTableView[]> {
    const state = ctx.getState();
    if (state.documents.length > 0) {
      return of([]);
    }
    return this.documentService
      .getDocuments({
        id: this._catalogId,
        criteria: state.criteria,
        sorting: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          is_Archive: 'ASC',
          Name: 'ASC'
        }
      })
      .pipe(
        tap(res => {
          ctx.patchState({
            documents: res,
            error: false,
            filteredDocuments: this.filterDocumentsByPhrase(res, state.searchPhrase)
          });
        }),
        catchError((error: HttpErrorResponse) => {
          ctx.patchState({
            error: true
          });
          this.snackBar.open(MirSnackbarType.Error, [
            {
              translate: false,
              message: `${error.message}`
            }
          ]);
          return EMPTY;
        })
      );
  }

  @Action(GetDocumentTypes)
  public getDocumentTypeOptions(ctx: StateContext<DocumentStateModel>): Observable<SelectOption[]> {
    const documentTypeOptions = ctx.getState().documentTypeOptions;
    if (documentTypeOptions.length > 0) {
      return of(documentTypeOptions);
    }
    return this.documentService.getDocumentTypes({ id: this._dictId }).pipe(
      tap(res => {
        const documentTypes = res.filter(x => x.isSelected).map(x => x.title);
        ctx.patchState({
          documentTypeOptions: res,
          filters: {
            documentsSphere: null,
            documentTypes: documentTypes
          }
        });
      })
    );
  }

  @Action(SetSearchPhrase)
  public setSearchPhrase(ctx: StateContext<DocumentStateModel>, { searchPhrase }: SetSearchPhrase): void {
    ctx.patchState({ searchPhrase });
    ctx.dispatch(new FilterDocuments());
  }

  @Action(SetSorting)
  public setSorting(ctx: StateContext<DocumentStateModel>, params: SetSorting): void {
    ctx.patchState({
      sorting: params.sorting
    });
    ctx.dispatch(new FilterDocuments());
  }

  @Action(SetDocumentFilters)
  public setDocumentFilters(ctx: StateContext<DocumentStateModel>, { filters }: SetDocumentFilters): void {
    ctx.patchState({ filters: filters });
    ctx.dispatch(new FilterDocuments());
  }

  @Action(FilterDocuments)
  public filterDocuments(ctx: StateContext<DocumentStateModel>): void {
    const currentState = ctx.getState();
    let documents = currentState.documents;
    const filters = currentState.filters;
    const searchPhrase = currentState.searchPhrase;
    const sorting = currentState.sorting;
    if (searchPhrase) {
      documents = this.filterDocumentsByPhrase(documents, searchPhrase);
    }

    if (filters && filters.documentTypes && filters.documentTypes?.length > 0) {
      documents = documents.filter(document => filters.documentTypes?.includes(document.documentType));
    } else {
      documents = [];
    }

    if (filters && filters.documentsSphere) {
      if (filters.documentsSphere === 'Архивные') {
        documents = documents.filter(document => document.archive === 'да');
      } else {
        documents = documents.filter(document => document.influenceScope === filters.documentsSphere);
      }
    }

    if (sorting.value === 'ASC') {
      documents = documents.sort(function (a, b) {
        if (a[sorting.type] < b[sorting.type]) {
          return -1;
        }
        if (a[sorting.type] > b[sorting.type]) {
          return 1;
        }
        return 0;
      });
    } else {
      documents = documents.sort(function (a, b) {
        if (a[sorting.type] > b[sorting.type]) {
          return -1;
        }
        if (a[sorting.type] < b[sorting.type]) {
          return 1;
        }
        return 0;
      });
    }
    const documentTypeOptions = cloneDeep(currentState.documentTypeOptions).map(d => {
      d.isSelected = filters.documentTypes?.some(x => x === d.value) ?? false;

      return d;
    });
    ctx.patchState({
      filteredDocuments: documents,
      documentTypeOptions: documentTypeOptions
    });
  }

  @Action(DropDocumentsFilterState)
  public dropDocumentsState(ctx: StateContext<DocumentStateModel>): void {
    ctx.patchState({
      filters: {
        documentTypes: ctx.getState().documentTypeOptions.map(x => x.title),
        documentsSphere: null
      },
      searchPhrase: initialStateValue.searchPhrase,
      criteria: initialStateValue.criteria
    });

    ctx.patchState({
      filteredDocuments: ctx.getState().documents
    });
  }
}

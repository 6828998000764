import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServerLinks } from '@shared/services/server-links';
import { ServerLinkName } from '@shared/models/server-links-model';
import { ResponseCatalogDataSave } from '@shared/models/catalog.service';
import { ParamsCatalogStateSave } from '../models/catalog-item.service';

@Injectable({
  providedIn: 'root'
})
export class CatalogItemService {
  private _urlEhdApi = '';
  private _urlOpenDataApi = '';

  private readonly _httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'ehd-system': 'opod'
  });
  constructor(private http: HttpClient, serverLinks: ServerLinks) {
    this._urlEhdApi = serverLinks.getLink(ServerLinkName.EhdApi);
    this._urlOpenDataApi = serverLinks.getLink(ServerLinkName.OpenData);
  }

  /*  public getCountCatalogItem(
    catalogId: number,
    criteria: string,
    geoData: GeoData | null = null,
    historyParams: HistoryParams | null = null,
    isDynamic = false
  ): Observable<CatalogItemValueResponse> {
    let body: BodyReq = {
      id: catalogId,
      criteria: criteria
    };

    if (geoData) {
      body = { ...body, geoData: geoData };
    }

    if (historyParams) {
      body = { ...body, ...historyParams };
    }

    return this.http.post<CatalogItemValueResponse>(`${isDynamic ? this._urlOpenDataApi : this._urlEhdApi}catalog/count`, body, {
      headers: this._httpHeaders
    });
  } */

  public saveCatalogStatistic(params: ParamsCatalogStateSave): Observable<ResponseCatalogDataSave> {
    return this.http.post<ResponseCatalogDataSave>(`${this._urlOpenDataApi}publicCatalogStatSave`, params, {
      headers: this._httpHeaders
    });
  }
}

import { GeoDataInfo } from '@shared/components/map/models/map';
import { CustomIcons } from '@shared/models/custom-icons';
import { NextToMeData } from '../../data/modules/catalog-items-data/models/catalog-item.service';

export type CatalogInfo = {
  accountingObject: string;
  accountingObjectEn: string;
  oiv: string;
  oivEn: string;
  responsiblePerson: {
    fio: string;
    email: string;
    phone: string;
  };
  version: {
    validFrom: string;
  };
  id: number;
  period: string;
  periodEn: string;
  validDate: string;
  keywords: string;
  description: string;
  // eslint-disable-next-line no-use-before-define
  fields: CatalogItemFieldsVM[];
  name: string;
  shortName: string;
  hasGeo?: number;
  epoch?: string;
  updatePeriod?: string;
  isDynamic?: boolean;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  is_dynamic?: boolean;
};

export type CatalogItemFieldsVM = {
  name: string;
  enName: string;
  techName: string;
  order: number;
  isDefaultSystems: string[];
  // eslint-disable-next-line no-use-before-define
  type: CatalogType;
  // eslint-disable-next-line no-use-before-define
  fieldType: CatalogType;
  specification?: CatalogInfo;
  dictionaryId?: number;
  system?: boolean;
  isPk?: boolean;
  maxLength?: number;
  systemField?: string;
  catalogId: number;
};

export type DynamicCatalogItemFieldsVM = {
  permittedSystems: string[];
  // eslint-disable-next-line no-use-before-define
  fieldType: CatalogType;
} & CatalogItemFieldsVM;

export type DynamicCatalogInfo = {
  fieldSpecificationList: DynamicCatalogItemFieldsVM[];
} & CatalogInfo;

export enum CatalogType {
  Catalog = 'CATALOG',
  String = 'STRING',
  File = 'FILE',
  Number = 'NUMBER',
  Dictionary = 'DICTIONARY',
  Flag = 'FLAG',
  Integer = 'INTEGER',
  Date = 'DATE',
  Text = 'text',
  Numeric = 'numeric',
  Boolean = 'boolean',
  Timestamp = 'timestamp',
  LINK = 'LINK'
}

export type CatalogViewHistory = {
  idPublicCatalog: number;
  lastVersion: string;
  lastRelease: string;
  lastVersionNum: number;
  lastReleaseNum: number;
};

export type CatalogView = {
  history: CatalogViewHistory[];
  actualVersion: string;
  actualVersionNum: number;
  actualRelease: string;
  actualReleaseNum: number;
  actualVersionPassport: string;
  actualReleasePassport: string;
  id: number;
  datasetId: number;
  notificationFavoriteIcon: CustomIcons | null;
  catalogName: string;
  catalogNameEn: string;
  catalogNameRu: string;
  categoryName: string;
  categoryNameEn: string;
  categoryNameRu: string;
  imageCode: string | null;
  categoryId: number;
  rating: number;
  numberDownloads: number;
  numberView: number;
  relevance: string;
  //  layerId: string;
  hasGeo: boolean;
  archive: boolean;
  idNumber: string;
  cluster: boolean;
  publicCatalogStatus: string;
  linkForum?: string;
  nameOIV: string;
  nameOIVEn: string;
  idOIV: number;
  firstPublication: string;
  isDynamic: boolean;
  validDate: string;
  defaultValidDate: string;
  numberVotes: number;
  countRows: number;
  link?: string;
};

export type CatalogGeoData = {
  catalogId: number;
  isRecommendationData: boolean;
  criteria: string;
  geoData: GeoDataInfo[];
  nextMeData: NextToMeData;
};

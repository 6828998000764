export class GetFavoriteCatalogs {
  public static readonly type = '[GetFavoriteCatalogs] Get FavoriteCatalogs';
}

export class AddFavoriteCatalog {
  public static readonly type = '[AddFavoriteCatalog] Add FavoriteCatalog';
  constructor(public catalogid: number) {}
}

export class DeleteFavoriteCatalog {
  public static readonly type = '[DeleteFavoriteCatalog] Delete FavoriteCatalog';
  constructor(public catalogid: number) {}
}

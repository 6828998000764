import { NgModule } from '@angular/core';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from '@angular/material/tooltip';

export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  hideDelay: 0,
  touchGestures: undefined,
  touchendHideDelay: 0,
  position: 'below',
  showDelay: 500
};

@NgModule({
  providers: [
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: myCustomTooltipDefaults
    }
  ]
})
export class CoreModule {}

import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { MainPageService } from 'app/modules/main/services/main-page.service';
import { SmallCardData } from 'app/modules/main/models/smal-card';
import { GetCatalogCountAndTime, GetPopularDatasets, GetSeasonDatasets, SetChosenCatalogOnMap } from 'app/modules/main/store/main-page.action';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ServerTime } from '@utils/server-time';
import { BreakpointObserver } from '@angular/cdk/layout';
import { DateTime } from 'luxon';
import { isPlatformServer } from '@angular/common';
import { BooleanCatalogFilters, CatalogFilter } from '../../data/modules/catalog-data/models/catalog.service';
import { Seasons } from '../../data/modules/catalog-data/store/catalog-table.state';

type MainPageStateModel = {
  popularDatasets: SmallCardData[] | null;
  seasonDatasets: SmallCardData[] | null;
  catalogsCount: number | null;
  serverTime: ServerTime | null;
  chosenCatalogOnMap: number | null;
  seasonDatasetsCriteria: CatalogFilter | null;
};

@State<MainPageStateModel>({
  name: 'mainPage',
  defaults: {
    popularDatasets: null,
    seasonDatasets: null,
    catalogsCount: null,
    serverTime: null,
    chosenCatalogOnMap: null,
    seasonDatasetsCriteria: null
  }
})
@Injectable()
export class MainPageState {
  constructor(
    private store: Store,
    private mainPageService: MainPageService,
    private breakpointObserver: BreakpointObserver,
    @Inject(PLATFORM_ID) private platformId: string
  ) {}

  @Selector()
  public static getPopularDatasets(state: MainPageStateModel): SmallCardData[] | null {
    return state.popularDatasets;
  }

  @Selector()
  public static getSeasonDatasets(state: MainPageStateModel): SmallCardData[] | null {
    return state.seasonDatasets;
  }

  @Selector()
  public static seasonDatasetsCriteria(state: MainPageStateModel): CatalogFilter | null {
    return state.seasonDatasetsCriteria;
  }

  @Selector()
  public static getCatalogsCount(state: MainPageStateModel): number | null {
    return state.catalogsCount;
  }

  @Selector()
  public static getChosenCatalogOnMap(state: MainPageStateModel): number | null {
    return state.chosenCatalogOnMap;
  }

  @Selector()
  public static getServerTime(state: MainPageStateModel): ServerTime | null {
    return state.serverTime;
  }

  @Action(GetPopularDatasets)
  public fetchPopularDatasets(ctx: StateContext<MainPageStateModel>): Observable<SmallCardData[]> {
    const currentState = ctx.getState();
    if (currentState.popularDatasets) {
      return of([]);
    }
    return this.mainPageService.getNewSetsAndPopularCards().pipe(
      tap(popularDatasets => {
        if (isPlatformServer(this.platformId)) {
          ctx.patchState({ popularDatasets: popularDatasets.slice(0, 4) });
        } else {
          ctx.patchState({ popularDatasets });
        }
      })
    );
  }

  @Action(GetSeasonDatasets)
  public fetchSeasonDatasets(ctx: StateContext<MainPageStateModel>): Observable<SmallCardData[]> {
    const currentState = ctx.getState();

    if (currentState.seasonDatasets) {
      return of([]);
    }

    /* const season = currentState.serverTime?.getCurrentSeason();

    let booleanFilters: BooleanCatalogFilters =
      season === Seasons.winter
        ? {
            winterSeason: true
          }
        : {
            summerSeason: true
          };

    ctx.patchState({
      seasonDatasetsCriteria: {
        booleanFilters
      }
    }); */
    return this.mainPageService.getSeasonDataSetCards().pipe(
      tap(seasonDatasets => {
        if (seasonDatasets[0] && seasonDatasets[0].seasonsId) {
          const season = seasonDatasets[0].seasonsId.toString();

          let booleanFilters: BooleanCatalogFilters = {};

          // eslint-disable-next-line default-case
          switch (season) {
            case Seasons.winter: {
              booleanFilters = {
                winterSeason: true,
                allSeasons: true
              };
              break;
            }
            case Seasons.summer: {
              booleanFilters = {
                summerSeason: true,
                allSeasons: true
              };
              break;
            }
            case Seasons.all: {
              booleanFilters = {
                allSeasons: true
              };
              break;
            }
          }

          ctx.patchState({ seasonDatasetsCriteria: { booleanFilters } });
        }

        ctx.patchState({ seasonDatasets });
      })
    );
  }

  @Action(GetCatalogCountAndTime)
  public fetchCatalogCount(ctx: StateContext<MainPageStateModel>): Observable<number> {
    const currentState = ctx.getState();
    if (currentState.serverTime && currentState.catalogsCount) {
      return of(1);
    }

    return this.mainPageService.getCatalogsCount().pipe(
      catchError(() =>
        of({
          found: 0,
          rest: 0,
          time: DateTime.now().toISOTime(),
          total: 0
        })
      ),
      tap(res => {
        const serverTime = new ServerTime(res.time);
        ctx.patchState({ catalogsCount: res.found, serverTime });
      }),
      map(res => res.found)
    );
  }

  @Action(SetChosenCatalogOnMap)
  public setChosenCatalogOnMap(ctx: StateContext<MainPageStateModel>, { catalogId }: SetChosenCatalogOnMap): void {
    ctx.patchState({ chosenCatalogOnMap: catalogId });
  }
}
